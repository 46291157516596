<template>
  <lang-router-link :to="{ name: 'spaceForm', params: { businessSlug: company.slug } }" class="study-card-list__item study-card -company">
    <div>
      <p class="space-form__card-title">{{ company.name }}</p>
      <p class="space-form__card-place">
        <Icon id="region"/>
        <span>{{ `${$beet.regions[company.region].code} - ${$beet.regions[company.region].name}` }}</span>
      </p>
    </div>
    <div>
      <button class="button -link -margin-top -fullwidth">{{ page.steps.check.btnAccess }}</button>
    </div>
  </lang-router-link>
</template>

<script>
export default {
  name: "CompanyCard",
  props: {
    page: Object,
    company: Object
  }
};
</script>

<style scoped>

</style>