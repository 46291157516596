<template>
  <div :class="{ '-loading': !$beet.isReady || !$page }">
    <template v-if="$beet.isReady && $page && ready">
      <header class="header" :style="backgroundImage">
        <div v-scroll:reveal="{ delay: 450 }">
          <Icon id="instant" class="header__badge"/>
        </div>
        <h1 v-scroll:reveal="{ delay: 550 }" class="header__title -max-width">{{ $t('maturity.title') }}</h1>
      </header>

      <section id="ref" class="maturity-form__section" style="padding-top: 2.5rem">
        <LangRouterLink :to="{name: 'maturity'}" class="link maturity-form__back ">
          <Icon id="arrow"/>
          {{ $t('app.back') }}
        </LangRouterLink>

        <div class="maturity-form">
          <div class="maturity-form__head">
            <div id="steps" class="maturity-form__head-row">
              <div v-for="(item, key) in tabs" :id="`step-${key}`" :key="key" class="maturity-form__step">
                <div
                    class="maturity-form__step-tag"
                    :style="{backgroundColor: currentTab >= key ? item.color : '', color: currentTab >= key ? 'white' : ''}"
                >
                  <icon :id="item.icon"/>
                </div>
                <div
                    class="maturity-form__step-label"
                    :style="{color: currentTab >= key ? item.color : ''}"
                >
                  {{ item.title }}
                </div>
              </div>
            </div>

            <p v-if="activeQuestion.type !== 'info'" class="maturity-form__head-text">
              {{ tabs[currentTab].text }}
            </p>
            <div v-if="activeQuestion.type !== 'info'" :style="{color: tabs[currentTab].color}">
              <Icon id="carret"/>
            </div>
          </div>

          <div class="maturity-form__content">
            <div class="maturity-form__content-title">
              {{ activeQuestion.title }}
            </div>
            <div class="maturity-form__content-wrapper">
              <div v-if="activeQuestion.type === 'radio'">
                <div v-for="(option, key) in activeQuestion.options"
                     :key="key"
                     class="maturity-form__radio" :class="{'-active' : activeAnswer.value === key}"
                     @click="selectAnswer(key)">
                  <div class="maturity-form__radio-circle" :class="{'-active' : activeAnswer.value === key}">
                    <Icon v-if="activeAnswer.value === key" id="checkmark"/>
                  </div>
                  <div class="maturity-form__radio-label">
                    {{ option }}
                  </div>
                </div>
              </div>

              <div v-if="activeQuestion.type === 'selectModel'">
                <InputSelect
                    name="select"
                    input-class="maturity-select"
                    :options="modelData[activeQuestion.dataType]"
                    @input="selectAnswer"
                    :value="activeAnswer.value"
                />
              </div>

              <div v-if="activeQuestion.type === 'select'">
                <InputSelect
                    name="select"
                    input-class="maturity-select"
                    :options="activeQuestion.options"
                    @input="selectAnswer"
                    :value="activeAnswer.value"
                />
              </div>

              <div v-else-if="activeQuestion.type === 'text'" style="width: 100%">
                <InputText @input="selectAnswer" input-class="maturity-text" :value="activeAnswer.value"/>
              </div>

              <div v-else-if="activeQuestion.type === 'info'" class="maturity-form__info">
                {{ activeQuestion.text }}
              </div>
            </div>

            <div class="maturity-form__content-buttons">
              <button v-if="currentTab > 0 || currentQuestion !== 0" class="button maturity-form__content-button -back"
                      @click="back">
                <Icon id="arrow"/>
                {{ $t('app.previous') }}
              </button>
              <button class="button -dark maturity-form__content-button" :class="{'-disabled': !canNext()}"
                      :disabled="!canNext()" @click="next">
                {{ isEnd ? $t('app.submit') : $t('app.next') }}
                <Icon v-if="!isEnd" id="arrow"/>
              </button>
            </div>
          </div>
        </div>
      </section>
    </template>
  </div>
</template>

<script>
import InputSelect from "@/components/inputs/InputSelect.vue";
import InputText from "@/components/inputs/InputText.vue";
import {PageMetaMixin} from "@/mixins";
import axiosInstance from "@/extensions/Http";

export default {
  name: "MaturityForm",
  mixins: [PageMetaMixin],
  components: {InputText, InputSelect},
  beetPage: 'maturity-form',
  data() {
    return {
      ready: false,
      currentTab: 0,
      currentQuestion: 0,
      answers: {0: {}, 1: {}, 2: {}, 3: {}, 4: {}, 5: {}},
      tabs: {
        0: {
          title: this.$t('maturity.dimension.general.formTitle'),
          icon: 'dim_general',
          color: '#545CFF',
          questions: []
        },
        1: {
          title: this.$t('maturity.dimension.strategy.formTitle'),
          icon: 'dim_strategie',
          color: '#FE9F41',
          questions: []
        },
        2: {
          title: this.$t('maturity.dimension.data.formTitle'),
          icon: 'dim_donnees',
          color: '#5F96FF',
          questions: []
        },
        3: {
          title: this.$t('maturity.dimension.technology.formTitle'),
          icon: 'dim_tech',
          color: '#009ECE',
          questions: []
        },
        4: {
          title: this.$t('maturity.dimension.rh.formTitle'),
          icon: 'dim_rh',
          color: '#BA026F',
          questions: []
        },
        5: {
          title: this.$t('maturity.dimension.govern.formTitle'),
          icon: 'dim_gouvernance',
          color: '#EE2D64',
          questions: []
        }
      }
    }
  },
  computed: {
    backgroundImage() {
      return this.$device.tablet
          ? `background-image: url('/images/banner_maturity.png')`
          : `background-image: url('/images/banner_maturity_m.png')`
    },
    activeQuestion() {
      return this.tabs[this.currentTab].questions[this.currentQuestion]
    },
    activeAnswer() {
      if (this.answers[this.currentTab][this.currentQuestion]) {
        const question = this.answers[this.currentTab]
        const answer = question[this.currentQuestion]
        return answer
      }
      return null
    },
    modelData() {
      const regionOptions = {}
      Object.values(this.$beet.regions).forEach(region => {
        regionOptions[Number(region.code)] = region.name
      })
      regionOptions[0] = this.$t('maturity.form.selectRegion')

      const sectorOptions = {}
      Object.values(this.$beet.sectors).forEach(sector => {
        sectorOptions[Number(sector.id)] = sector.name
      })
      sectorOptions[0] = this.$t('maturity.form.selectSector')

      return {
        region: regionOptions,
        sector: sectorOptions
      }
    },
    isEnd() {
      return this.currentTab === Object.keys(this.tabs).length - 1 && this.currentQuestion === this.tabs[this.currentTab].questions.length - 1
    }
  },
  watch: {
    '$page': {
      handler(to) {
        if (to) {
          this.parseData().then(() => {
            Object.entries(this.tabs).forEach(entry => {
              this.answers[entry[0]] = {}
              entry[1].questions.forEach((question, index) => {
                if (question.type !== 'info') this.answers[entry[0]][index] = {value: ''}
              })
            })
            this.getSavedData().then(() => {
              this.ready = true
              if (localStorage.maturityLastTab) this.currentTab = Number(localStorage.maturityLastTab)
              setTimeout(() => {
                this.$forceUpdate()
              }, 500)
            }).catch(() => {
              localStorage.maturityLastTab = 0
              this.currentTab = 0
              this.ready = true
            })
          })
        }
      },
      immediate: true
    }
  },
  methods: {
    getSavedData() {
      return new Promise((resolve, reject) => {
        axiosInstance.$get('/api/maturity/results').then((response) => {
          if (!response.data.results) {
            reject(new Error)
          } else if (response.data.results.length) {
            response.data.results.forEach((data, index) => {
              Object.values(data).forEach((val, key) => {
                if (val !== 0 && val !== null)
                  if (this.answers[index][Number(key)]) this.answers[index][Number(key)].value = val
              })
            })
            Object.assign(this.answers[0][0], {value: response.data.results[0][1]})
            resolve()
          }
        })
        reject(new Error)
      })
    },
    parseData() {
      return new Promise(resolve => {
        const {dimensions} = this.$page
        dimensions[0] = this.$page.general
        Object.entries(dimensions).forEach(entry => {
          const index = entry[0]
          const dim = entry[1]
          this.tabs[index].text = dim.description
          Object.values(dim.questions).forEach(q => {
            const question = this.parseQuestionData(q)
            this.tabs[index].questions.push(question)
          })
          resolve()
        })
      })

    },
    parseQuestionData(q) {
      const question = {}
      question.title = q.question || 'undefined'
      if (q.flexibleLayout) {
        switch (q.flexibleLayout) {
          case 'TextQuestion':
            question.type = 'text'
            break;
          case 'ModelQuestion':
            question.type = 'selectModel'
            question.dataType = q.model
            break;
          case 'SelectQuestion':
            question.type = 'select'
            question.options = q.answers.reduce((a, b, i) => {
              const obj = {}
              obj[i + 1] = b.answer
              return {...a, ...obj}
            }, {})
            question.options[0] = this.$t('maturity.form.selectAnswer')
            break;
          case 'YesNoQuestion':
            question.type = 'radio'
            question.options = {1: this.$t('app.yes'), 0: this.$t('app.no')}
            break;
          case 'InformativeQuestion':
            question.type = 'info'
            question.text = q.text
            question.title = q.title
            break;
          default:
            break;
        }
      } else {
        question.type = 'radio'
        question.options = {}
        Object.values(q.answers).forEach((option, key) => {
          question.options[key + 1] = option
        })
      }
      return question
    },
    canNext() {
      if (this.activeQuestion.type === 'info') return true
      return (this.activeAnswer && this.activeAnswer.value !== '')
    },
    selectAnswer(answer) {
      Object.assign(this.answers[this.currentTab][this.currentQuestion], {value: answer})
      this.$forceUpdate()
    },
    next() {
      this.scrollTop()
      this.parseAnswers()

      if (this.isEnd) {
        localStorage.maturityLastTab = 0
        this.saveData()
        return this.$router.push(this.$i18n.locale === 'fr' ? '/maturite/rapport' : '/en/maturity/report')
      }
      if (this.currentQuestion < this.tabs[this.currentTab].questions.length - 1) {
        if (this.currentTab === 0 && this.currentQuestion === 1 && Number(this.activeAnswer.value) === 0) {
          this.currentQuestion += 1;
        }
        this.currentQuestion += 1;
      } else if (this.currentQuestion === this.tabs[this.currentTab].questions.length - 1) {
        this.saveData()
        this.mobileScroll(1)
        this.currentQuestion = 0;
        this.currentTab += 1;
        localStorage.maturityLastTab = Number(this.currentTab)
      }
    },
    back() {
      this.scrollTop()
      if (this.currentQuestion === 0) {
        this.mobileScroll(-1)
        this.currentTab -= 1;
        this.currentQuestion = this.tabs[this.currentTab].questions.length - 1
      } else {
        this.currentQuestion -= 1;
      }
    },
    mobileScroll(dir) {
      if (this.$device.phone) {
        const nextStep = document.getElementById(`step-${this.currentTab + dir}`)
        const steps = document.getElementById('steps')
        steps.scrollLeft = nextStep.offsetLeft
      }
    },
    scrollTop() {
      if (window.scrollY > 270) {
        const ref = document.getElementById('ref')
        window.scrollTo({
          top: (ref.offsetTop - 100),
          behavior: 'smooth'
        });
      }
    },
    parseAnswers() {
      const final = {}
      Object.entries(this.answers).forEach(entry => {
        const key = entry[0]
        const data = entry[1]
        const newData = Object.values(data).reduce((a, b, i) => {
          const obj = {}
          if (b.value !== '') {
            obj[i + 1] = Number.isNaN(Number(b.value)) ? b.value : Number(b.value)
          }
          return {...a, ...obj}
        }, {})
        final[key] = newData
      })
      return final
    },
    saveData() {
      return axiosInstance.$post('/api/maturity/save', {
        dimension: this.currentTab,
        results: this.parseAnswers()[this.currentTab]
      })
    }
  }
}
</script>
