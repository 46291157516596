<template>
  <aside class="filter-bar-modal">
    <header>
      <div v-if="!$device.laptop" class="filter-bar-modal__header">
        <p class="filter-bar-modal__header-title">{{ $t('app.filters.title') }}</p>
        <button class="filter-bar-modal__close -mobile" :aria-label="$t('app.ariaLabel.closeFilter')" @click="close">
          <Icon id="close"/>
        </button>
      </div>
      <DropDown :is-open="activeFilters.length > 0" :class="['filter-bar-modal__top', {'--active': activeFilters.length > 0}]">
        <transition-group tag="ul" name="list" class="filter-bar-modal__tag-group">
          <li v-for="(filter) in activeFilters" :key="filter.index">
            <LangRouterLink :to="getRouterLink(filter.queryKey, filter.value, filter.isMultiple)"
                            class="filter-bar-modal__tag">
              <span>{{ filter.option[1] }}</span>
              <Icon id="more" class="filter-bar-modal__tag-icon"/>
            </LangRouterLink>
          </li>
        </transition-group>
      </DropDown>
    </header>
    <ul class="filter-bar-modal__content">
      <li
          v-for="(filter, i) in filters"
          :key="filter.queryKey"
          :class="['filter-bar-modal__section', { '-expand': isCurrent === i }]"
      >
        <div class="filter-bar-modal__title" @click="isOpen(i)">
          <h2 class="filter-bar-modal__label">
            {{ filter.name }}
          </h2>
          <button class="more">
            <Icon id="arrow-drop" :class="['filter-bar-modal__arrow', { '-active-group': i === isCurrent }]"/>
          </button>
        </div>
        <DropDown :is-open="isCurrent === i">
          <div v-if="filter.options" :class="['filter-bar-modal__options']">
            <LangRouterLink
                v-for="(option) in filter.options"
                :key="option[0]"
                :to="getRouterLink(filter.queryKey, option[0], filter.isMultiple)"
                :class="['filter-bar-modal__link', { '-active-filter': isActiveFilter(filter.queryKey, option[0]) }]"
            >
              <Icon v-if="filter.hasIcon" :id="`${filter.queryKey}_${option[0]}`"/>
              <span>{{ option[1] }}</span>
            </LangRouterLink>
          </div>
        </DropDown>
      </li>
    </ul>
    <nav class="filter-bar-modal__footer">
      <LangRouterLink :to="clearLink" :class="['button', '-link', { '-disabled': !isIndexFiltered }]">{{
          $t('app.filters.reset')
        }}
      </LangRouterLink>
      <button class="button -dark -apply" @click="close">{{ getButtonLabel() }}</button>
    </nav>
    <button class="filter-bar-modal__close" :aria-label="$t('app.ariaLabel.closeFilter')" @click="close">
      &times;
    </button>
  </aside>
</template>

<script>
import {FilterQueryBuilderMixin} from '../../mixins'
import {DropDown, Icon} from '@/components/global'

export default {
  name: 'FilterBarModal',
  components: {DropDown, Icon},
  mixins: [FilterQueryBuilderMixin],
  props: {
    filters: {type: Array, default: Array},
    scope: {type: String, required: true},
    label: {type: String, default: null},
    isIndexFiltered: {type: Boolean, default: false},
    count: {type: Number, required: true}
  },
  data() {
    return {
      isCurrent: 0,
      isPressed: false
    }
  },
  computed: {
    activeFilters() {
      const activeFilters = []
      Object.entries(this.$route.query).forEach(filter => {
        if(filter[0] === 'q' || filter[0] === 'page') return
        const safeArrayValue = Array.isArray(filter[1]) ? filter[1] : [filter[1]]
        const currentSector = this.filters.find(sector => sector.queryKey === filter[0])
        safeArrayValue.forEach(value => {
          activeFilters.push({
            index: `${filter[0]}${value}`,
            isMultiple: true,
            option: Object.values(currentSector.options).find(option => option[0].toString() === value.toString()),
            queryKey: filter[0],
            value
          })
        })
      })
      return activeFilters
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    isOpen(index) {
      if (this.isCurrent === null || this.isCurrent !== index) {
        this.isCurrent = index
        this.isPressed = true
      } else {
        this.isCurrent = null
        this.isPressed = false
      }
    },
    getButtonLabel() {
      return this.count > 0
          ? `${this.$t('app.filters.display')} ${this.count} ${this.$t('app.filters.results')}`
          : this.$t('app.filters.noResult')
    }
  }
}
</script>
