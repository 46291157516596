<template>
  <form class="input-text -search" @submit.prevent="search">
    <input v-model="str" type="text" />
    <button>
      <icon id="search" />
    </button>
  </form>
</template>

<script>
export default {
  name: 'InputSearch',
  data() {
    return {
      str: ''
    }
  },
  methods: {
    search() {
      if (this.str.length && this.str !== this.$route.query.q) {
        this.$router.push({ name: 'search', query: { q: this.str } })
      }
    }
  }
}
</script>
