export default {
  income: {
    250000: 'Less than C$0.5 million',
    1250000: 'C$0.5 million to C$2 million',
    6000000: 'C$2 million to C$10 million',
    55000000: 'C$10 million to C$100 million',
    425000000: 'Over C$100 million',
    unknown: 'Prefers not to answer the question'
  },
  size: {
    10: 'Fewer than 10 employees',
    30: '10 to 49 employees',
    75: '50 to 99 employees',
    175: '100 to 249 employees',
    375: '250 to 499 employees',
    750: '500 to 999 employees',
    1000: 'More than 1000 employees',
    employee: 'employee',
    employees: 'employees'
  },
  client: {
    business: 'Companies',
    school: 'Educational institutions',
    government: 'Governments',
    worker: 'Workers',
    citizen: 'Citizens',
    consumer: 'Consumers'
  },
  motivation: {
    rd: 'R&D',
    commerce: 'Marketing',
    dev: 'Market development',
    export: 'Exports',
    growth: 'Growth',
    tech: 'Technical expertise',
    complementary: 'Additional expertise',
    talent: 'Talent',
    support: 'Financing',
    none: 'We are not looking for any partner at the moment'
  },
  partnership: {
    actual: 'Current',
    wanted: 'Wanted'
  }
}
