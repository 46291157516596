<template>
  <div :class="{ '-loading': !isDataReady || !$page }">
    <template v-if="isDataReady && $page">
      <HeaderShow v-if="!$device.customtablet" object="business" :title="business.name" :date="business.updatedAt"/>

      <div class="single">
        <div class="single__left">
          <HeaderShow
            v-if="$device.customtablet"
            object="business"
            :title="business.name"
            :date="business.updatedAt"
            :logo="business.logo"
            :destination="'businessIndex'"
            @scrollToProfile="redirectTo('askAccess')"
          />

          <template v-if="!$device.customlaptop">
            <BusinessSideContact :business="business" />
          </template>

          <div class="block-list">
            <div class="block-list__row">
              <div class="block-list__item -half block">
                <div class="block__header">
                  <h2 class="block__title">{{ $page.subtitle.who }}</h2>
                  <LangRouterLink
                    v-if="whoWeAreFilled"
                    :to="{ name: 'businessShow', hash: '#who', params: { businessSlug: business.slug } }"
                    class="block__anchor"
                  >
                    <span class="block__anchor-icon"><Icon id="more" /></span>
                    <p @click="isWhoOpen = true">{{ $t('app.links.details') }}</p>
                  </LangRouterLink>
                </div>
                <div class="block__body">
                  <p>{{ business.about }}</p>
                </div>
              </div>

              <div class="block-list__item -half block">
                <div class="block__header">
                  <h2 class="block__title">{{ $page.subtitle.psSummary }}</h2>
                  <LangRouterLink
                    v-if="psFilled"
                    :to="{ name: 'businessShow', hash: '#ps', params: { businessSlug: business.slug } }"
                    class="block__anchor"
                  >
                    <span class="block__anchor-icon"><Icon id="more" /></span>
                    <p @click="isPsOpen = true">{{ $t('app.links.details') }}</p>
                  </LangRouterLink>
                </div>
                <div class="block__body">
                  <p>{{ business.psSummary }}</p>
                </div>
              </div>
            </div>

            <div class="block-list__row">
              <div class="block-list__item -half block">
                <div class="block__header">
                  <h2 class="block__title">{{ $page.subtitle.expertises }}</h2>
                </div>
                <div class="block__body">
                  <ul class="block__list">
                    <button v-for="tag in categories" :key="tag.id" class="tag" @click="searchCategorie(tag.id)">
                      {{ tag.name }}
                    </button>
                  </ul>
                </div>
              </div>

              <div class="block-list__item -half block">
                <div class="block__header">
                  <h2 class="block__title">{{ $page.labels.capacities }}</h2>
                  <LangRouterLink
                      v-if="hasCapacities"
                    :to="{ name: 'businessShow', hash: '#ai', params: { businessSlug: business.slug } }"
                    class="block__anchor"
                  >
                    <span class="block__anchor-icon"><Icon id="more" /></span>
                    <p @click="isPsOpen = true">{{ $t('app.links.details') }}</p>
                  </LangRouterLink>
                </div>
                <div class="block__body">
                  <ul class="block__list">
                    <button v-for="tag in selectedCapacities('specialityCapacities')" :key="tag.id" class="tag" @click="search(tag.name)">
                      {{ tag.name }}
                    </button>
                  </ul>
                </div>
              </div>
            </div>

            <div v-if="studies.length" class="block-list__item block">
              <div class="block__header -studies">
                <h2 class="block__title">{{ $page.subtitle.studies }}</h2>
              </div>
              <div class="block__list -studies">
                <div v-for="study in studies" :key="study.id" class="block__study">
                  <div class="block__study-title">
                    <span v-if="$device.customtablet" class="block__badge"
                      ><img src="/badges/study.svg" alt="" class="block__badge-img"
                    /></span>
                    <h3 class="block__subtitle">{{ study.title }}</h3>
                  </div>

                  <LangRouterLink
                    :to="{ name: 'studyShow', params: { studySlug: study.slug } }"
                    class="block__link -study"
                    ><span v-if="!$device.customtablet" class="block__badge"
                      ><img src="/badges/study.svg" alt="" class="block__badge-img" /></span
                    >{{ $t('app.links.studySingle') }}<Icon id="arrow"
                  /></LangRouterLink>
                </div>
              </div>
            </div>
          </div>

          <div class="toggle-list">
            <div v-if="whoWeAreFilled" class="toggle-list__item">
              <div
                id="who"
                class="toggle-list__header"
                tabindex="0"
                role="button"
                :aria-pressed="isWhoOpen"
                @click="isWhoOpen = !isWhoOpen"
              >
                <h2 class="toggle-list__title">
                  {{ $page.subtitle.who }}
                </h2>
                <button class="toggle-list__button" :aria-label="getToggleAria(isWhoOpen)">
                  <Icon :id="getToggleId(isWhoOpen)" />
                </button>
              </div>

              <DropDown :is-open="isWhoOpen">
                <div class="toggle-list__dropdown">
                  <template v-if="business.mission">
                    <h3 class="toggle-list__subtitle">{{ $page.subLabels.mission }}</h3>
                  <p>{{ business.mission }}</p>
                  </template>
                  <template v-if="business.vision">
                    <h3 class="toggle-list__subtitle">{{ $page.subLabels.vision }}</h3>
                  <p>{{ business.vision }}</p>
                  </template>
                  <template v-if="business.pitch">
                    <h3 class="toggle-list__subtitle">{{ $page.subLabels.pitch }}</h3>
                    <p>{{ business.pitch }}</p>
                  </template>
                </div>
              </DropDown>
            </div>

            <div v-if="psFilled" class="toggle-list__item">
              <div
                id="ps"
                class="toggle-list__header"
                tabindex="0"
                role="button"
                :aria-pressed="isPsOpen"
                @click="isPsOpen = !isPsOpen"
              >
                <h2 class="toggle-list__title">
                  {{ $page.subtitle.psSummary }}
                </h2>
                <button class="toggle-list__button" :aria-label="getToggleAria(isPsOpen)">
                  <Icon :id="getToggleId(isPsOpen)" />
                </button>
              </div>

              <DropDown :is-open="isPsOpen">
                <div class="toggle-list__dropdown">
                  <template v-if="business.psDescription">
                    <h3 class="toggle-list__subtitle">{{ $page.subLabels.psDescription }}</h3>
                  <p>{{ business.psDescription }}</p>
                  </template>
                  <template v-if="business.why">
                    <h3 class="toggle-list__subtitle">{{ $page.subLabels.why }}</h3>
                    <p>{{ business.why }}</p>
                  </template>
                </div>
              </DropDown>
            </div>

            <div v-if="business.stories.length" class="toggle-list__item">
              <div
                id="stories"
                class="toggle-list__header"
                tabindex="0"
                role="button"
                :aria-pressed="isStoriesOpen"
                @click="isStoriesOpen = !isStoriesOpen"
              >
                <h2 class="toggle-list__title">
                 {{ $page.subtitle.success }}
                </h2>
                <button class="toggle-list__button" :aria-label="getToggleAria(isStoriesOpen)">
                  <Icon :id="getToggleId(isStoriesOpen)" />
                </button>
              </div>

              <DropDown :is-open="isStoriesOpen">
                <div class="block__list -studies -margin">
                  <div v-for="story in business.stories" :key="story.key" class="block__study -stories">
                    <div class="block__study-flex">
                      <div class="block__study-title">
                        <span v-if="$device.customtablet" class="block__badge"
                          ><img src="/badges/study.svg" alt="" class="block__badge-img"
                        /></span>
                        <h3 class="block__subtitle">{{ story.attributes.title }}</h3>
                      </div>

                      <a
                        :href="story.attributes.link"
                        target="_blank"
                        class="block__link -study"
                        ><span v-if="!$device.customtablet" class="block__badge"
                          ><img src="/badges/study.svg" alt="" class="block__badge-img" /></span
                        >{{ $t('app.links.storySingle') }}<Icon id="arrow"
                      /></a>
                    </div>
                    <div v-if="story.attributes.description" class="block__study-description">
                      <p>{{ story.attributes.description }}</p>
                    </div>
                  </div>
                </div>
              </DropDown>
            </div>

            <div class="toggle-list__item" v-if="hasCapacities">
              <div
                id="ai"
                class="toggle-list__header"
                tabindex="0"
                role="button"
                :aria-pressed="isStoriesOpen"
                @click="isIaOpen = !isIaOpen"
              >
                <h2 class="toggle-list__title">
                  {{ $page.subtitle.capacities }}
                </h2>
                <button class="toggle-list__button" :aria-label="getToggleAria(isIaOpen)">
                  <Icon :id="getToggleId(isIaOpen)" />
                </button>
              </div>

              <DropDown :is-open="isIaOpen">
                <div class="toggle-list__dropdown">
                  <div class="toggle-list__columns">
                    <div v-if="business.size" class="toggle-list__column">
                      <h3 class="toggle-list__subtitle">{{ $page.subLabels.size }}</h3>
                      <p>{{ $t(`options.size.${business.size}`) }}</p>
                    </div>
                    <div v-if="business.aiEmployee" class="toggle-list__column">
                      <h3 class="toggle-list__subtitle">{{ $page.subLabels.aiEmployee }}</h3>
                      <p v-if="business.aiEmployee">{{ business.aiEmployee }} {{ employeeLabel }}</p>
                    </div>
                  </div>
                  <div v-for="(type, index) in capacityTypes" :key="index" class="toggle-list__row">
                    <template v-if="selectedCapacities(`${type}Capacities`).length">
                      <h3 class="toggle-list__subtitle -border">{{ $page.subLabels[type] }}</h3>
                      <ul class="block__list">
                        <button v-for="tag in selectedCapacities(`${type}Capacities`)" :key="tag.id" class="tag" @click="search(tag.name)">
                          {{ tag.name }}
                        </button>
                      </ul>
                    </template>
                  </div>
                </div>
              </DropDown>
            </div>



            <template v-if="isAuth">
              <div class="toggle-list__item">
                <div
                  id="inter"
                  class="toggle-list__header"
                  tabindex="0"
                  role="button"
                  :aria-pressed="isInterOpen"
                  @click="isInterOpen = !isInterOpen"
                >
                  <h2 class="toggle-list__title">
                    <img src="/badges/auth.svg" alt="contenu privé" class="toggle-list__badge-auth" />
                    {{ getLabel('interPresence') }}
                  </h2>
                  <button class="toggle-list__button" :aria-label="getToggleAria(isInterOpen)">
                    <Icon :id="getToggleId(isInterOpen)" />
                  </button>
                </div>

                <DropDown :is-open="isInterOpen">
                  <div class="toggle-list__dropdown">
                    <h3 class="toggle-list__subtitle">{{ $page.subLabels.actualInterExperience }}</h3>
                    <p>{{ business.actualInterExperience }}</p>
                    <h3 class="toggle-list__subtitle">{{ $page.subLabels.actualInterExportation }}</h3>
                    <p>{{ business.actualInterExportation }}</p>
                    <h3 class="toggle-list__subtitle">{{ $page.subLabels.actualInterClients }}</h3>
                    <p>{{ business.actualInterClients }}</p>
                    <h3 class="toggle-list__subtitle">{{ $page.subLabels.actualInterOthers }}</h3>
                    <p>{{ business.actualInterOthers }}</p>
                  </div>
                </DropDown>
              </div>

              <div class="toggle-list__item">
                <div
                  id="export"
                  class="toggle-list__header"
                  tabindex="0"
                  role="button"
                  :aria-pressed="isExportOpen"
                  @click="isExportOpen = !isExportOpen"
                >
                  <h2 class="toggle-list__title">
                    <img src="/badges/auth.svg" alt="contenu privé" class="toggle-list__badge-auth" />
                    {{ getLabel('export') }}
                  </h2>
                  <button class="toggle-list__button" :aria-label="getToggleAria(isExportOpen)">
                    <Icon :id="getToggleId(isExportOpen)" />
                  </button>
                </div>

                <DropDown :is-open="isExportOpen">
                  <div class="toggle-list__dropdown">
                    <h3 class="toggle-list__subtitle">{{ $page.subLabels.wantedInterExportation }}</h3>
                    <p>{{ business.wantedInterExportation }}</p>
                    <h3 class="toggle-list__subtitle">{{ $page.subLabels.wantedInterClients }}</h3>
                    <p>{{ business.wantedInterClients }}</p>
                    <h3 class="toggle-list__subtitle">{{ $page.subLabels.wantedInterOthers }}</h3>
                    <p>{{ business.wantedInterOthers }}</p>
                  </div>
                </DropDown>
              </div>

              <div class="toggle-list__item">
                <div
                  id="rd"
                  class="toggle-list__header"
                  tabindex="0"
                  role="button"
                  :aria-pressed="isRdOpen"
                  @click="isRdOpen = !isRdOpen"
                >
                  <h2 class="toggle-list__title">
                    <img src="/badges/auth.svg" alt="contenu privé" class="toggle-list__badge-auth" />
                    {{ getLabel('rd') }}
                  </h2>
                  <button class="toggle-list__button" :aria-label="getToggleAria(isRdOpen)">
                    <Icon :id="getToggleId(isRdOpen)" />
                  </button>
                </div>

                <DropDown :is-open="isRdOpen">
                  <div class="toggle-list__dropdown">
                    <h3 class="toggle-list__subtitle">{{ $page.subLabels.actualInterRd }}</h3>
                    <p>{{ business.actualInterRd }}</p>
                    <h3 class="toggle-list__subtitle">{{ $page.subLabels.wantedInterRd }}</h3>
                    <p>{{ business.vision }}</p>
                    <h3 class="toggle-list__subtitle">{{ $page.subLabels.wantedInterRdPartnerships }}</h3>
                    <p>{{ business.wantedInterRdPartnerships }}</p>
                    <h3 class="toggle-list__subtitle">{{ $page.subLabels.wantedInterRdOthers }}</h3>
                    <p>{{ business.wantedInterRdOthers }}</p>
                  </div>
                </DropDown>
              </div>
            </template>
          </div>
        </div>

        <div v-if="$device.customlaptop" class="single__right">
          <BusinessSideContact :business="business" />
        </div>

        <h2 class="header__title -show -print">{{ business.name }}</h2>
      </div>
    </template>
  </div>
</template>

<script>
import { getMeta } from '@/helpers/metaHelpers'
import { DropDown, Icon } from '@/components/global'
import { BusinessSideContact, HeaderShow } from '@/components/partials'

export default {
  name: 'BusinessShow',
  beetPage: 'businessshow',
  components: { BusinessSideContact, DropDown, HeaderShow, Icon },
  props: {
    businessSlug: { type: String, require: true }
  },
  metaInfo() {
    if (this.business.seo) {
      const { seo } = this.business
      if (!seo.title) seo.title = `${this.business.name} | Vitrine - IA`
      if (!seo.description) seo.description = this.business.about
      if (!seo.image) seo.image = `${window.location.origin}/images/seo.jpg`
      return getMeta(seo)
    }
  },
  data() {
    return {
      isDataReady: false,
      isWhoOpen: true,
      isPsOpen: true,
      isInterOpen: false,
      isExportOpen: false,
      isRdOpen: false,
      isStoriesOpen: false,
      isIaOpen: false,
      capacityTypes: [
        'data',
        'domain',
        'learning',
        'method',
        'code',
        'interface',
        'responsible'
      ],
      categoriesRemovingIDs: [7, 8],
      capacitiesRemovingIDs: [1, 14, 15, 34, 35, 43, 44, 61, 62, 73, 74, 86, 87, 97]
    }
  },
  computed: {
    isAuth() {
      return this.$store.state.user.isAuth
    },
    business() {
      const id = this.$store.getters.businessSlugMap[this.businessSlug]
      return this.$beet.businesses[id]
    },
    studies() {
      const { studies = [] } = this.$beet || {}
      return this.business.studies.map(id => studies[id])
    },
    categories() {
      const finalResult = [];
      this.business.categories.forEach(id => {
        if (!this.categoriesRemovingIDs.includes(id)) {
          finalResult.push(this.$beet.categories[id])
        }
      });
      return finalResult;
    },
    whoWeAreFilled() {
      return !!this.business.mission || !!this.business.vision || !!this.business.pitch;
    },
    psFilled() {
      return !!this.business.psDescription || !!this.business.why;
    },
    employeeLabel() {
      return this.business.aiEmployee > 1 ? this.$t('options.size.employees') : this.$t('options.size.employee')
    },
    hasCapacities() {
      if(this.isDataReady) {
        if(this.business.aiEmployee || this.business.size) return true
        this.capacityTypes.forEach(type => {
          if(this.selectedCapacities(`${type}Capacities`).length) return true
        })
      }
      return false
    }
  },
  watch: {
    '$route.hash'() {
      switch (this.$route.hash) {
        case '#who':
          this.isWhoOpen = true
          break
        case '#ps':
          this.isPsOpen = true
          break
        case '#ai':
          this.isIaOpen = true
          break
        default:
      }
    }
  },

  beforeMount() {
    if (!this.business) this.$router.replace({ name: 'not-found' })
  },
  created() {
    const id = this.$store.getters.businessSlugMap[this.businessSlug]
    const axiosConfig = { url: `/businesses/${id}?lang=${this.$lang.current}`, headers: {} }

    const parseBusiness = ({ data }) => data

    this.$store.dispatch('fetchSingle', ['businesses', id, axiosConfig, parseBusiness]).then(() => {
      this.isDataReady = true
    })
  },
  mounted() {
    switch (this.$route.hash) {
      case '#who':
        this.isWhoOpen = true
        break
      case '#ps':
        this.isPsOpen = true
        break
      case '#ai':
        this.isIaOpen = true
        break
      default:
    }
  },
  methods: {
    getLabel(key) {
      return this.$device.laptop ? this.$page.labels[key] : this.$page.labels[`${key}Short`]
    },
    getToggleId(isOpen) {
      return isOpen ? 'less' : 'more'
    },
    getToggleAria(isOpen) {
      return isOpen ? this.$t('app.ariaLabel.less') : this.$t('app.ariaLabel.more')
    },
    getPartnerIcon(id) {
      return `partner_${id}`
    },
    search(value) {
      this.$router.push({ name: 'businessIndex', query: { q: value } })
    },
    searchCategorie(id) {
      this.$router.push({ name: 'businessIndex', query: { type: id } })
    },
    redirectTo(id) {
      this.$router.push({
        name: 'businessShow',
        hash: `#${id}`,
        params: { businessSlug: this.business.slug }
      })
    },
    selectedCapacities(key) {
      const finalResult = [];
      this.business[key].forEach(id => {
        if (!this.capacitiesRemovingIDs.includes(id)) {
          finalResult.push(this.$beet.capacities[id]);
        }
      })
      return finalResult;
    }
  }
}
</script>
