export default {
  computed: {
    clearLink() {
      const { q } = this.$route.query
      const query = q ? { q } : {}
      return { ...this.$route, query }
    }
  },
  data() {
    return {
      filtering: null
    }
  },
  methods: {
    createFiltering(params) {
      this.filtering = params
    },
    isActiveFilter(queryKey, value) {
      const currentValue = this.$route.query[queryKey]
      return Array.isArray(currentValue) ? currentValue.includes(value) : currentValue === value
    },
    getRouterLink(queryKey, value, isMultiple) {
      const { page, ...currentQuery } = this.$route.query

      return isMultiple
        ? this.handleFilterMultiple(currentQuery, queryKey, value)
        : this.handleFilterUnique(currentQuery, queryKey, value)
    },
    handleFilterMultiple(query, queryKey, value) {
      const currentQueryValue = query[queryKey] || []
      const safeCurrentQueryValue = Array.isArray(currentQueryValue) ? currentQueryValue : [currentQueryValue]

      const newQueryValue = safeCurrentQueryValue.includes(value)
        ? safeCurrentQueryValue.filter(val => val !== value)
        : [...safeCurrentQueryValue, value]

      const newQuery = newQueryValue.length
        ? { ...query, [queryKey]: newQueryValue }
        : this.unsetQueryKey(query, queryKey)
      return { ...this.$route, query: newQuery }
    },
    handleFilterUnique(currentQuery, queryKey, value) {
      const query =
        currentQuery[queryKey] !== value
          ? this.setQueryKey(currentQuery, queryKey, value)
          : this.unsetQueryKey(currentQuery, queryKey)
      return { ...this.$route, query }
    },
    setQueryKey(query, key, value) {
      return { ...query, [key]: value }
    },
    unsetQueryKey(query, key) {
      return Object.keys(query)
        .filter(qKey => qKey !== key)
        .reduce((queryMap, qKey) => ({ ...queryMap, [qKey]: query[qKey] }), {})
    }
  }
}
