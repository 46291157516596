<template>
  <section class="newsletter">
    <div class="newsletter__intro">
      <h2 class="section__subtitle">{{ content.title }}</h2>
      <p>{{ content.subtitle }}</p>
    </div>

    <transition name="fade" mode="out-in">
      <form v-if="isFormVisible" :key="isFormVisible" class="form-newsletter" @submit.prevent="validate()">
        <InputText
          v-model="validation.name.value"
          name="name"
          :label="$t('forms.name.label')"
          :is-required="validation.name.isRequired"
          :show-validation="validation.name.showValidation"
          :error="validation.name.isInvalid"
          :error-message="validation.name.currentMessage"
          @single-validate="validate"
        />

        <div class="row">
          <InputText
            v-model="validation.organisation.value"
            name="organisation"
            :show-validation="validation.organisation.showValidation"
            :label="$t('forms.organisation.label')"
            :error="validation.organisation.isInvalid"
            :error-message="validation.organisation.currentMessage"
            @single-validate="validate"
          />

          <InputSelect
            v-model="validation.orgType.value"
            :options="selectOptions"
            name="orgType"
            :is-required="validation.orgType.isRequired"
            :label="$t('forms.orgType.label')"
            :show-validation="validation.orgType.showValidation"
            :error="validation.orgType.isInvalid"
            :error-message="validation.orgType.currentMessage"
            @single-validate="validate"
          />
        </div>

        <InputText
          v-model="validation.email.value"
          name="email"
          :label="$t('forms.email.label')"
          :show-validation="validation.email.showValidation"
          :is-required="validation.email.isRequired"
          :error="validation.email.isInvalid"
          :error-message="validation.email.currentMessage"
          @single-validate="validate"
        />

        <div>
          <input id="marketing" v-model="marketing" type="checkbox" class="input__field" /><label
            label-for="marketing"
            >{{ $t('forms.marketing.label') }}</label
          >
        </div>

        <div class="connexion__buttons row -center">
          <button class="button -link" @click="close">{{ $t('forms.buttons.cancel') }}</button>
          <button type="submit" class="button -link">{{ $t('forms.buttons.subscription') }}</button>
        </div>
      </form>

      <div v-else-if="isConfirmCardVisible" class="form__confirmation">
        <h3 class="section__title">{{ content.confirmation.title }}</h3>
        <p class="section__subtitle">{{ content.confirmation.subtitle }}</p>
      </div>
    </transition>

    <button class="newsletter__close" :aria-label="$t('app.ariaLabel.closeModal')" @click="close">
      <Icon id="close" />
    </button>
  </section>
</template>

<script>
import axios from 'axios'
import { InputText, InputSelect } from '@/components/inputs'
import { ValidationMixin } from '@/mixins'

export default {
  name: 'NewsLetter',
  components: { InputText, InputSelect },
  mixins: [ValidationMixin],
  data() {
    return {
      isFormVisible: true,
      isConfirmCardVisible: false,
      marketing: false
    }
  },
  computed: {
    content() {
      return this.$beet.options.newsletterform
    },
    selectOptions() {
      // return this.content.object.objects.reduce(
      //   (optionsMap, option, currentIndex) => {
      //     return { ...optionsMap, [currentIndex + 1]: option.item }
      //   },
      //   { 0: 'Je veux...' }
      // )
      return { 0: this.$t('forms.orgType.default'), 1: "Obtenir de l'information" }
    }
  },
  created() {
    this.createValidation({
      name: {
        isRequired: true,
        regex: /^[a-zA-ZÀ-ÿ\-' ]{1,50} [a-zA-ZÀ-ÿ\-' ]{1,50}$/,
        messages: {
          empty: this.$t('forms.name.empty'),
          pattern: this.$t('forms.name.pattern')
        }
      },
      organisation: {
        isRequired: true,
        regex: /^[0-9a-zA-ZÀ-ÿ\-,' ]{0,100}$/,
        messages: {
          empty: '',
          pattern: this.$t('forms.organisation.pattern')
        }
      },
      orgType: {
        isRequired: true,
        regex: /^[0-9]{1,2}$/,
        messages: {
          empty: this.$t('forms.orgType.empty'),
          pattern: this.$t('forms.orgType.pattern')
        },
        compute: val => this.selectOptions[val]
      },
      email: {
        isRequired: true,
        regex:
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        messages: {
          empty: this.$t('forms.email.empty'),
          pattern: this.$t('forms.email.pattern')
        }
      }
    })
  },
  methods: {
    close() {
      this.$emit('close')
    },
    selectObject(value) {
      this.validation.object.value = value
      this.validateSingleField('orgType')
    },
    submitForm() {
      const data = { ...this.getValidationFields(), marketing: this.marketing }
      this.toggleConfirmationCard()

      axios({
        method: 'post',
        baseURL: process.env.VUE_APP_API_URL,
        url: '/newsletter',
        data
      })
    },
    toggleConfirmationCard() {
      this.isFormVisible = false
      this.isConfirmCardVisible = true
    }
  }
}
</script>
