<template>
  <div :class="{ '-loading': !$page }">
    <template v-if="$page">
      <header class="header" :style="backgroundImage">
        <h1 v-if="$page.header.subtitle" v-scroll:reveal class="header__title">{{ $page.header.subtitle }}</h1>
        <h2 v-if="$page.header.title" v-scroll:reveal class="header__subtitle">{{ $page.header.title }}</h2>
      </header>

      <section class="section -margin-bottom">
        <RawHtml :html="$page.content" />
      </section>
    </template>
  </div>
</template>

<script>
export default {
  name: 'Glossary',
  beetPage: 'glossary',
  computed: {
    backgroundImage() {
      return this.$device.tablet
        ? `background-image: url('/images/banner_terms.png')`
        : `background-image: url('/images/banner_terms_m.png')`
    }
  }
}
</script>
