<template>
  <div class="eco-cta -organisation">
    <div class="eco-cta__content">
      <h2 class="eco-cta__title">{{ title }}</h2>
      <p class="eco-cta__subtitle">{{ subtitle }}</p>
      <lang-router-link :to="{ name: 'vitrineSpace' }" class="button -link -fullwidth-mob -center" rel="noopener">{{ linkLabel }}</lang-router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OrganisationCta',
  props: {
    title: { type: String, require: true },
    subtitle: { type: String, require: true },
    linkLabel: { type: String, require: true }
  },
}
</script>
