<template>
  <div :class="{ '-loading': !$page }">
    <template v-if="$page">
      <section :class="['header', '-home', { '-auth': isAuth }]">
        <video autoplay playsinline loop muted class="header__video" width="100%" height="100%">
          <source src="https://assets.boitebeet.com/vitrine-ia/39/home_loop.mp4" type="video/mp4"/>
        </video>
        <div class="header__content">
          <div>
            <h2 v-scroll:reveal="{ delay: 650 }" class="header__title -home">{{ $page.header.title }}</h2>
            <p v-scroll:reveal="{ delay: 700 }" class="header__text">{{ $page.header.subtitle }}</p>
            <SearchBarMobile
                v-if="!$device.customtablet"
                v-scroll:reveal="{ delay: 750 }"
                :title="$page.header.searchLabel"
            />
            <SearchBar v-else v-scroll:reveal="{ delay: 750 }" :title="$page.header.searchLabel"/>
          </div>

          <LangRouterLink
              v-scroll:reveal="{ delay: 800, offset: 0 }"
              :to="{ name: 'businessIndex' }"
              class="header__link -home"
          >{{ $page.header.linkLabel }}
            <Icon id="arrow"
            />
          </LangRouterLink>
        </div>
        <LangRouterLink v-scroll:reveal="{ delay: 650, offset: 0 }" class="header__eco" :to="openVideoModal"
        >
          <Icon id="meet-eco"/>
          {{ $page.header.videoLabel }}
        </LangRouterLink
        >
      </section>

      <MiriaHeader/>

      <section class="section">
        <div v-scroll:reveal="{ delay: 100 }" class="section__header">
          <span v-if="$device.customtablet" class="section__header-badge"
          ><img src="/badges/business.svg" alt="" class="section__header-badgeimg"
          /></span>

          <div>
            <h2 class="section__title -bigger">{{ $page.sectors.title }}</h2>
            <p>{{ $page.sectors.subtitle }}</p>
          </div>

          <LangRouterLink :to="{ name: 'businessIndex' }" class="section__header-link link"
          ><span v-if="!$device.customtablet" class="section__header-badge"
          ><img src="/badges/business.svg" alt="" class="section__header-badgeimg"/></span
          >{{ $page.sectors.linkLabel }}
            <Icon id="arrow"
            />
          </LangRouterLink>
        </div>

        <div class="sector-card-list">
          <SectorCard
              v-for="(sector, i) in $page.sectors.items"
              :id="sector"
              :key="sector"
              v-scroll:reveal="{ delay: i * 150 }"
              class="sector-card-list__item -home"
          />
        </div>
      </section>

      <EcoCta
          v-scroll:reveal="{ delay: 100 }"
          :title="$page.ecosystem.title"
          :subtitle="$page.ecosystem.subtitle"
          :link-label="$page.ecosystem.linkLabel"
          class="-home"
      />

      <section class="section">
        <div v-scroll:reveal="{ delay: 100 }" class="section__header">
          <span v-if="$device.customtablet" class="section__header-badge"
          ><img src="/badges/study.svg" alt="" class="section__header-badgeimg"
          /></span>

          <div>
            <h2 class="section__title -bigger">{{ $page.studies.title }}</h2>
            <p>{{ $page.studies.subtitle }}</p>
          </div>

          <LangRouterLink :to="{ name: 'studyIndex' }" class="section__header-link link"
          ><span v-if="!$device.customtablet" class="section__header-badge"
          ><img src="/badges/study.svg" alt="" class="section__header-badgeimg"/></span
          >{{ $page.studies.linkLabel }}
            <Icon id="arrow"
            />
          </LangRouterLink>
        </div>

        <div class="study-card-list">
          <StudyHomeCard
              v-for="(study, i) in $page.studies.items"
              :id="study"
              :key="study"
              v-scroll:reveal="{ delay: i * 150 }"
              class="study-card-list__item"
          />
        </div>
      </section>

      <section v-scroll:reveal="{ delay: 100 }" class="block-tools">
        <div class="block-tools__content">
          <h2 class="block-tools__title">{{ $page.tools.title }}</h2>
          <p class="block-tools__subtitle">{{ $page.tools.subtitle }}</p>
          <p class="block-tools__link">{{ $page.tools.linkLabel }}</p>
        </div>
      </section>

      <section v-scroll:reveal="{ delay: 100 }" class="section -double-pad block-sponsors">
        <div class="block-sponsors__content">
          <div class="block-sponsors__column">
            <h2 class="block-sponsors__title">{{ $page.sponsors.title }}</h2>
            <p class="block-sponsors__text">{{ $page.sponsors.text }}</p>
            <img src="/images/logo_gouv.png" alt="Gouvernement du Québec" class="block-sponsors__logo"/>
          </div>
          <div v-for="(sponsor, i) in $page.sponsors.items" :key="i" class="block-sponsors__column">
            <h3 class="block-sponsors__name"><span class="block-sponsors__name-before"></span>{{ sponsor.name }}</h3>
            <p>{{ sponsor.description }}</p>
            <img :src="sponsor.logo" alt="" class="block-sponsors__logo"/>
          </div>
        </div>
      </section>
    </template>
  </div>
</template>

<script>
import {EcoCta, MiriaHeader, SearchBar, SearchBarMobile} from '@/components/partials'
import {SectorCard, StudyHomeCard} from '@/components/cards'
import {PageMetaMixin} from '@/mixins'

export default {
  name: 'Home',
  beetPage: 'home',
  components: {EcoCta, MiriaHeader, SearchBar, SearchBarMobile, SectorCard, StudyHomeCard},
  mixins: [PageMetaMixin],
  computed: {
    isAuth() {
      return this.$store.state.user.isAuth
    },
    openVideoModal() {
      return {
        name: this.$route.name,
        query: {...this.$route.query, video: true}
      }
    }
  }
}
</script>
