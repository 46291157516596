import Vue from 'vue'
import VueI18n from 'vue-i18n'

import appEn from './en/app'
import documentEn from './en/documents'
import formsEn from './en/forms'
import maturityEn from './en/maturity'
import optionsEn from './en/options'

import appFr from './fr/app'
import documentFr from './fr/documents'
import formsFr from './fr/forms'
import maturityFr from './fr/maturity'
import optionsFr from './fr/options'

Vue.use(VueI18n)

const en = {
  app: appEn,
  documents: documentEn,
  forms: formsEn,
  maturity: maturityEn,
  options: optionsEn
}

const fr = {
  app: appFr,
  documents: documentFr,
  forms: formsFr,
  maturity: maturityFr,
  options: optionsFr
}

export default new VueI18n({
  locale: navigator.language.substr(0, 2) || 'en',
  fallbackLocale: 'fr',
  messages: { en, fr }
})
