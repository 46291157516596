<template>
  <div>
    <Component :is="ConnexionFormComponent" @toggle="isConnexionVisible = !isConnexionVisible" @close="closePanel" />

    <RouterLink :to="closePanelLink" class="form__close button -close" :aria-label="$t('app.ariaLabel.closeConnexion')">
      <Icon id="close" />
    </RouterLink>
  </div>
</template>

<script>
import ConnexionForm from './ConnexionForm.vue'
import LinkResetForm from './LinkResetForm.vue'

export default {
  name: 'Connexion',
  components: { ConnexionForm, LinkResetForm },
  data() {
    return {
      isConnexionVisible: true
    }
  },
  computed: {
    ConnexionFormComponent() {
      return this.isConnexionVisible ? ConnexionForm : LinkResetForm
    },
    closePanelLink() {
      const { connexion, ...query } = this.$route.query
      return { name: this.$route.name, query }
    }
  },
  methods: {
    closePanel() {
      const { connexion, ...query } = this.$route.query
      this.$router.push({ query })
    }
  }
}
</script>
