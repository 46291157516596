export default {
  methods: {
    /**
     * This method assume that all params needs a translation. Could be adapt with slug naming like
     * elementTranslatableSlug (if translatable), elementSlug (if not)
     */
    getParams(lang, storeObjectKey) {
      const paramsArray = Object.entries(this.$route.params)
      return paramsArray.length
        ? paramsArray.reduce((newParamObj, [paramKey, paramValue]) => {
            if (paramKey === 'pathMatch') {
              return { [paramKey]: paramValue }
            }
            const translatedParam = this.paramTranslator(paramKey, paramValue, lang, storeObjectKey)
            return { ...newParamObj, ...translatedParam }
          }, {})
        : null
    },
    /**
     * This logic assume that a slug map for a given elementType exists in $store. Make sure it does
     */
    paramTranslator(paramKey, paramValue, lang, storeObjectKey) {
      const elementType = paramKey.replace(/(Slug)/g, '')
      const elementId = this.$store.getters[`${elementType}SlugMap`][paramValue]
      const { slugs = {} } = this.$beet[`${storeObjectKey}`][elementId] || {}
      return elementId ? { [paramKey]: slugs[lang] } : { [paramKey]: paramValue }
    }
  }
}
