<template>
  <div class="filter-search">
    <form class="filter-search__form" @submit.prevent="search">
      <input v-model="str" type="text" class="filter-search__input" :placeholder="placeholder" />

      <button class="button -dark -search filter-search__button" :aria-label="$t('app.ariaLabel.search')">
        <icon id="search" />
      </button>
      <transition name="filter-search__clear">
        <button v-if="$route.query.q" class="filter-search__clear" @click="clearSearch">&times;</button>
      </transition>
    </form>
  </div>
</template>

<script>
export default {
  name: 'FilterBarSearch',
  props: {
    scope: { type: String, required: true },
    placeholder: { type: String, default: null }
  },
  data() {
    return {
      str: this.$route.query.q || ''
    }
  },
  methods: {
    search() {
      if (!this.str.length || this.str === this.$route.query.q) return
      this.$router.push({ query: { ...this.$route.query, q: this.str } })
    },
    clearSearch() {
      this.str = ''
      const { page, q, ...query } = this.$route.query
      this.$router.push({ query })
    }
  }
}
</script>
