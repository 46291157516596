<template>
  <div>
    <div v-if="isFormVisible">
      <form id="reset" class="form form-reset" @submit.prevent="validate()">
        <div class="form__intro">
          <h2 class="form__title">{{ $t('forms.reset.title') }}</h2>
        </div>
        <InputText
          v-model="validation.password.value"
          name="password"
          :is-password="true"
          :label="$t('forms.reset.newPassword.label')"
          :show-validation="validation.password.showValidation"
          :error="validation.password.isInvalid"
          :error-message="validation.password.currentMessage"
          @single-validate="validate"
        />

        <InputText
          v-model="validation.password_confirmation.value"
          name="password_confirmation"
          :is-password="true"
          :label="$t('forms.reset.confirmPassword.label')"
          :show-validation="validation.password_confirmation.showValidation"
          :error="validation.password_confirmation.isInvalid"
          :error-message="validation.password_confirmation.currentMessage"
          @single-validate="validate"
        />

        <p v-if="failMessage" class="input__error"><Icon id="warning" class="input__warning" />{{ failMessage }}</p>
      </form>
      <div class="form__buttons">
        <button form="reset" type="submit" name="reset" class="button -dark -fullwidth-mob">
          {{ $t('forms.buttons.confirmReset') }}
        </button>
      </div>
    </div>

    <div v-else-if="isConfirmationVisible" class="form__confirmation">
      <div class="form__intro">
        <h3 class="form__title">{{ $t('forms.reset.confirmation') }}</h3>
      </div>
      <div class="form__buttons">
        <LangRouterLink :to="openLoginPanelLink" class="button -link -dark">{{
          $t('forms.buttons.back')
        }}</LangRouterLink>
      </div>
    </div>

    <LangRouterLink :to="closePanelLink" class="form__close button -close" :aria-label="$t('app.ariaLabel.closeReset')">
      <Icon id="close" />
    </LangRouterLink>
  </div>
</template>

<script>
import http from '@/extensions/Http'
import { InputText } from '@/components/inputs'
import { ValidationMixin } from '@/mixins'

export default {
  name: 'Reset',
  components: { InputText },
  mixins: [ValidationMixin],
  data() {
    return {
      isFormVisible: true,
      isConfirmationVisible: false,
      failMessage: null
    }
  },
  computed: {
    closePanelLink() {
      return { name: this.$route.name }
    },
    queryData() {
      return { email: this.$route.query.email, token: this.$route.query.token }
    },
    openLoginPanelLink() {
      return {
        name: this.$route.name,
        query: { connexion: true }
      }
    }
  },
  created() {
    this.createValidation({
      password: {
        isRequired: true,
        regex: /^(?=.{10,}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).*$/,
        messages: {
          empty: this.$t('forms.reset.newPassword.empty'),
          pattern: this.$t('forms.reset.newPassword.empty')
        }
      },
      password_confirmation: {
        isRequired: true,
        isPasswordConfirmation: true,
        messages: {
          pattern: this.$t('forms.reset.confirmPassword.pattern')
        }
      }
    })
  },
  methods: {
    submitForm() {
      const data = { ...this.getValidationFields(), ...this.queryData }

      http
        .$post('/api/reset-password', data)
        .then(() => {
          this.showConfirmation()
        })
        .catch(({ catchResponse }) => {
          this.failMessage = catchResponse.data.message
        })
    },
    showConfirmation() {
      this.isFormVisible = false
      this.isConfirmationVisible = true
    }
  }
}
</script>
