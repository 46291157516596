<template>
  <Portal v-if="isOpen" to="modal">
    <transition appear :duration="325" mode="out-in" name="modal">
      <div :key="queryKey" :class="['modal', { '-delayed': isDelayed }]" @click="onClickOutside">
        <div :class="contentClassName" @click.stop>
          <slot />
        </div>
      </div>
    </transition>
  </Portal>
</template>

<script>
import _omit from 'lodash.omit'

export default {
  name: 'Modal',
  props: {
    queryKey: { type: String, default: null },
    isVisible: { type: Boolean, default: false },
    contentClass: { type: [String, Array, Object], default: Array },
    isDelayed: { type: Boolean, default: false }
  },
  computed: {
    isOpen() {
      return this.isVisible || this.$route.query[this.queryKey]
    },
    contentClassName() {
      return ['modal__content', this.contentClass]
    }
  },
  watch: {
    isVisible() {
      this.isVisible ? this.$scroll.prevent() : this.$scroll.restore()
    },
    isOpen() {
      this.isOpen ? this.$scroll.prevent() : this.$scroll.restore()
    }
  },
  methods: {
    onClickOutside() {
      if (this.isVisible) return this.$emit('close')
      const query = _omit(this.$route.query, [this.queryKey])
      this.$router.push({ ...this.$route, query })
    }
  }
}
</script>
