<template>
  <div :class="['business-card', { '-hover': hover }]">
    <div>
      <div @mouseenter="hover = true" @mouseleave="hover = false">
        <LangRouterLink
          :to="{ name: 'businessShow', params: { businessSlug: business.slug } }"
          @focus.native="hover = true"
          @blur.native="hover = false"
        >
          <p class="business-card__title">{{ getName(business.name) }}</p>
        </LangRouterLink>
      </div>
      <p class="business-card__region"><Icon id="region" />{{ region.name }} {{ otherRegions ? $tc('app.other', otherRegions, {nb: otherRegions}) : '' }}</p>
    </div>

    <div>
      <div class="business-card__sectors">
        <LangRouterLink
          v-for="(sector, i) in sectors"
          :key="i"
          :to="{ name: 'businessIndex', query: { sect: String(sector.id) }, hash: '#top' }"
          class="tag -icon"
          :title="sector.name"
          ><Icon :id="getSectorIcon(sector.id)" /><span class="tag__tip">{{ sector.name }}</span></LangRouterLink
        >
        <span v-if="extraSector > 0" class="tag -icon">+{{ extraSector }}</span>
      </div>

      <div class="business-card__categories">
        <LangRouterLink
          v-for="(category, i) in categories"
          :key="i"
          class="tag -smaller"
          :to="{ name: 'businessIndex', query: { type: String(category.id) }, hash: '#top' }"
          >{{ category.name }}</LangRouterLink
        >
        <span v-if="extraCategory > 0" class="tag">+{{ extraCategory }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import _shuffle from 'lodash.shuffle'

export default {
  name: 'BusinessCard',
  props: { businessId: { type: Number, required: true } },
  data() {
    return {
      hover: false
    }
  },
  computed: {
    business() {
      return this.$beet.businesses[this.businessId]
    },
    region() {
      return this.$beet.regions[this.business.region]
    },
    maxSector() {
      return this.$device.tablet ? 5 : 4
    },
    sectors() {
      const { sectors = [] } = this.$beet || {}
      const randomized = _shuffle(this.business.applicationSectors)

      if (this.business.applicationSectors.length <= 5)
        return randomized.sort(this.sortByQuerySect).map(id => sectors[id])

      return randomized
        .sort(this.sortByQuerySect)
        .map(id => sectors[id])
        .slice(0, this.maxSector)
    },
    extraSector() {
      return this.business.applicationSectors.length - this.maxSector
    },
    safeQuerySect() {
      const currentQueryValue = this.$route.query.sect || []
      return Array.isArray(currentQueryValue) ? currentQueryValue : [currentQueryValue]
    },
    categories() {
      const { categories = [] } = this.$beet || {}
      const randomized = _shuffle(this.business.categories)
      if (this.business.categories.length <= 4) return randomized.sort(this.sortByQueryType).map(id => categories[id])

      return randomized
        .sort(this.sortByQueryType)
        .map(id => categories[id])
        .slice(0, 4)
    },
    extraCategory() {
      return this.business.categories.length - 4
    },
    safeQueryType() {
      const currentQueryValue = this.$route.query.type || []
      return Array.isArray(currentQueryValue) ? currentQueryValue : [currentQueryValue]
    },
    otherRegions() {
      return this.business.officesRegions.filter(id => this.business.region !== id).length;
    }
  },
  methods: {
    sortByQuerySect(a, b) {
      const { sect } = this.$route.query
      if (!sect) return
      if (this.safeQuerySect.indexOf(String(a)) !== -1 && this.safeQuerySect.indexOf(String(b)) === -1) {
        return -1
      } else if (this.safeQuerySect.indexOf(String(b)) !== -1 && this.safeQuerySect.indexOf(String(a)) === -1) {
        return 1
      }
      return 0
    },
    sortByQueryType(a, b) {
      const { type } = this.$route.query
      if (!type) return
      if (this.safeQueryType.indexOf(String(a)) !== -1 && this.safeQueryType.indexOf(String(b)) === -1) {
        return -1
      } else if (this.safeQueryType.indexOf(String(b)) !== -1 && this.safeQueryType.indexOf(String(a)) === -1) {
        return 1
      }
      return 0
    },
    getSectorIcon(id) {
      return `sect_${id}`
    },
    getName(name) {
      return name.length < 80 ? name : `${name.substring(0, 70)} ...`
    }
  }
}
</script>
