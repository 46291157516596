<template>
  <div :class="{ '-loading': !$page }">
    <template v-if="$page">
      <MiriaHeader class="--header"/>

      <header class="header --miria" :style="backgroundImage">
        <div v-scroll:reveal="{ delay: 450 }">
          <Icon id="business" class="header__badge" />
        </div>

        <h1 v-scroll:reveal="{ delay: 550 }" class="header__title">{{ $page.header.title }}</h1>
        <h2 v-scroll:reveal="{ delay: 650 }" class="header__subtitle">{{ $page.header.subtitle }}</h2>
        <button
          v-scroll:reveal="{ delay: 450 }"
          class="button -info header__info"
          aria-label="Info additionnelle"
          @click="isInfoOpen = true"
        >
          <Icon id="info" />
          <Modal :is-visible="isInfoOpen || !isBetaSet" content-class="-info" @close="isInfoOpen = false">
            <BusinessInfo :title="$page.modal.title" :content="$page.modal.richText" @close="closeInfo" />
          </Modal>
        </button>
      </header>

      <FilterBar v-bind="filterConfig" :count="count" :is-index-filtered="isIndexFiltered" :mention="$page.mention" />

      <section class="section -first -double-pad">
        <template v-if="isIndexReady">
          <p class="section__legend">
            <span v-if="count > 0">{{ count }} </span><span>{{ getResultLabel(count) }}</span>
          </p>
          <div class="business-card-list">
            <BusinessCard
              v-for="business in currentIndexPage"
              :key="business.id"
              :business-id="business.id"
              class="business-card-list__item"
            />
          </div>
        </template>
        <div v-else class="pending">
          <img src="/badges/pending.png" alt="" class="pending__img" />
          <p>{{ $t('app.pending') }}</p>
        </div>
      </section>

      <Pagination
        v-if="hasPagination"
        :current-page="currentPage"
        :item-per-page="itemPerPage"
        :total-item="index.length"
      />
      <section>
        <InstantLink
            icon="/badges/sect_c_2.svg"
            :title="$t('app.instant.link.title')"
            :text="$t('app.instant.link.text')"
            :link-label="$t('app.instant.link.linkLabel')"
            :link-route="{name: 'instant'}"
        />
      </section>


      <SectorPromo
        :title="$page.businesses.title"
        :subtitle="$page.businesses.subtitle"
        :sectors="$page.sectors.items"
      />
    </template>
  </div>
</template>

<script>
import { IndexPageMixin, PageMetaMixin } from '../mixins'
import { filterOptionFactory } from '@/helpers'
import { BusinessCard } from '@/components/cards'
import { SectorPromo, InstantLink, MiriaHeader} from '@/components/partials'
import { FilterBar } from '@/components/filters'
import { Pagination } from '@/components/nav'
import { BusinessInfo, Modal } from '@/components/modal'
import http from '@/extensions/Http'

const createOptions = filterOptionFactory('id', 'name')

export default {
  name: 'BusinessIndex',
  beetPage: 'businessesindex',
  components: {MiriaHeader, BusinessCard, FilterBar, Pagination, Modal, BusinessInfo, SectorPromo, InstantLink },
  mixins: [IndexPageMixin, PageMetaMixin],
  data() {
    return {
      isBetaSet: true,
      searchResults: null,
      isIndexReady: true,
      isFilterOpen: false,
      isInfoOpen: false,
      itemPerPage: 9,
      sectorsRemovingIDs: ["16"],
      categoriesRemovingIDs: ["7", "8"]
    }
  },
  computed: {
    backgroundImage() {
      return this.$device.tablet
        ? `background-image: url('/images/banner_business.jpg')`
        : `background-image: url('/images/banner_business_m.jpg')`
    },
    isIndexFiltered() {
      const { sect, region, type, act } = this.$route.query
      return !!(sect || region || type || act)
    },
    filterConfig() {
      return {
        scope: 'business',
        label: this.$t('app.object.business'),
        placeholder: this.$t('app.filters.placeholder'),
        filters: [
          {
            name: this.$t('app.object.actorType'),
            queryKey: 'act',
            options: this.formatFilterOptions(this.getActorsOptions()),
            isMultiple: true,
            hasIcon: true
          },
          {
            name: this.$t('app.object.category'),
            queryKey: 'type',
            options: this.formatFilterOptions(createOptions(this.$beet.categories), true, this.categoriesRemovingIDs),
            isMultiple: true,
            hasIcon: false
          },
          {
            name: this.$t('app.object.appSector'),
            queryKey: 'sect',
            options: this.formatFilterOptions(createOptions(this.$beet.sectors), true, this.sectorsRemovingIDs),
            isMultiple: true,
            hasIcon: true
          },
          {
            name: this.$t('app.filters.region'),
            queryKey: 'region',
            options: this.formatFilterOptions(createOptions(this.$beet.regions), false),
            isMultiple: true,
            hasIcon: false
          }
        ]
      }
    },
    index() {
      const {
        sect: sectorQueryValue,
        region: regionQueryValue,
        type: categoryQueryValue,
        act: actorQueryValue,
        tech: techQueryValue,
        startup: startupQueryValue
      } = this.$route.query
      const filterBySector = this.createFilterBySector(sectorQueryValue)
      const filterByRegion = this.createFilterByRegion(regionQueryValue)
      const filterByCategory = this.createFilterByCategory(categoryQueryValue)
      const filterByActor = this.createFilterByActor(actorQueryValue)
      const filterByTech = this.createFilterByTech(techQueryValue)
      const filterByStartup = this.createFilterByStartup(startupQueryValue)
      return !this.searchResults
        ? this.sortedIndex(
            this.$store.state.randomBusinessIndex
              .filter(filterBySector)
              .filter(filterByRegion)
              .filter(filterByCategory)
              .filter(filterByActor)
              .filter(filterByTech)
              .filter(filterByStartup)
          )
        : this.sortedIndex(
            this.searchResults
              .filter(filterBySector)
              .filter(filterByRegion)
              .filter(filterByCategory)
              .filter(filterByActor)
              .filter(filterByTech)
              .filter(filterByStartup)
          )
    }
  },
  watch: {
    '$route.query.q': {
      immediate: true,
      handler(to, from) {
        if (!from && to) {
          this.isIndexReady = false
          this.getSearchResults(to)
        } else if (from !== to && to) {
          this.isIndexReady = false
          this.getSearchResults(to)
        } else {
          this.searchResults = null
        }
      }
    }
  },
  methods: {
    sortedIndex(index) {
      if (!this.isIndexFiltered && !this.searchResults) {
        return index.sort(this.sortByIsCompleted)
      } else if (this.searchResults) {
        return index
      }
      return index.sort(this.sortByName)
    },
    getActorsOptions() {
      return { ...createOptions(this.$beet.actorTypes), 99999: this.$t('app.object.startups') }
    },
    getSearchResults(query) {
      http
        .get(`api/search/businesses?s=${query}`)
        .then(this.mapSearchResults)
        .catch(() => (this.searchResults = null))
    },
    mapSearchResults({ data }) {
      if (!data.length) {
        this.searchResults = []
      } else {
        this.searchResults = data.map(id => {
          return this.$beet.businesses[id]
        })
      }
      this.isIndexReady = true
    },
    createFilterBySector(sectorValue) {
      if (!sectorValue) return () => true
      const safeQueryValue = Array.isArray(sectorValue) ? sectorValue : [sectorValue]
      return ({ applicationSectors }) =>
        safeQueryValue.some(selectedId => applicationSectors.indexOf(Number(selectedId)) > -1)
    },
    createFilterByRegion(regionValue) {
      if (!regionValue) return () => true
      const safeQueryValue = Array.isArray(regionValue) ? regionValue : [regionValue]
      return ({ region }) => safeQueryValue.some(selectedId => region === Number(selectedId))
    },
    createFilterByCategory(categoryValue) {
      if (!categoryValue) return () => true
      const safeQueryValue = Array.isArray(categoryValue) ? categoryValue : [categoryValue]
      return ({ categories }) => safeQueryValue.some(selectedId => categories.indexOf(Number(selectedId)) > -1)
    },
    createFilterByActor(actorValue) {
      if (!actorValue) return () => true
      const safeQueryValue = Array.isArray(actorValue) ? actorValue : [actorValue]
      if (safeQueryValue.includes('99999') && safeQueryValue.length > 1) {
        return ({ actorType, isStartup }) =>
          safeQueryValue.some(selectedId => actorType === Number(selectedId) && isStartup === true)
      }
      if (safeQueryValue.includes('99999')) {
        return ({ isStartup }) => isStartup
      }
      return ({ actorType }) => safeQueryValue.some(selectedId => actorType === Number(selectedId))
    },
    createFilterByTech(techValue) {
      if (!techValue) return () => true
      const safeQueryValue = Array.isArray(techValue) ? techValue : [techValue]
      return ({ businessTags }) => safeQueryValue.some(selectedId => businessTags.indexOf(Number(selectedId)) > -1)
    },
    createFilterByStartup(startupValue) {
      if (!startupValue) return () => true
      return ({ isStartup }) => isStartup === Boolean(Number(startupValue))
    },
    getResultLabel(count) {
      return count > 0 ? this.$t('app.filters.results') : this.$t('app.filters.noResult')
    },
    closeInfo() {
      this.isInfoOpen = false
      this.isBetaSet = true
    },
    formatFilterOptions(filter, alphabeticalSort = true, indexes = []) {
      const alphabetical = Object.entries(filter).sort((a, b) => {
        if (a[1] < b[1]) {
          return -1
        } else if (a[1] > b[1]) {
          return 1
        }
        return 0
      })

      let result = [];

      if (indexes.length) {
        Object.values(alphabetical).forEach(value => {
          if (!indexes.includes(value[0])) {
            result.push(value);
          }
        })
      } else {
        result = alphabetical;
      }
      return alphabeticalSort ? result : Object.entries(filter)
    }
  }
}
</script>
