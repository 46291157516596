<template>
  <div v-if="isFormVisible">
    <form id="linkReset" ref="linkReset" class="form -linkreset" @submit.prevent="validate()">
      <div class="form__intro">
        <h3 class="form__title -linkreset">{{ $t('forms.linkReset.title') }}</h3>
      </div>
      <InputText
        v-model="validation.email.value"
        name="email"
        :label="$t('forms.email.label')"
        :show-validation="validation.email.showValidation"
        :error="validation.email.isInvalid"
        :error-message="validation.email.currentMessage"
        @single-validate="validate"
      />
      <p v-if="failMessage" class="input__error"><Icon id="warning" class="input__warning" />{{ failMessage }}</p>
    </form>
    <div class="form__buttons -linkreset">
      <input type="button" class="button" :value="$t('forms.buttons.back')" @click="toggle" />
      <button form="linkReset" type="submit" name="linkReset" class="button -dark">
        {{ $t('forms.buttons.linkReset') }}
      </button>
    </div>
  </div>

  <div v-else-if="isConfirmationVisible" class="form__confirmation">
    <div class="form__intro">
      <h3 class="form__title">{{ $t('forms.linkReset.confirmation') }}</h3>
    </div>

    <div class="form__buttons">
      <button class="button -dark" @click="toggle">{{ $t('forms.buttons.back') }}</button>
    </div>
  </div>
</template>

<script>
import http from '@/extensions/Http'
import { InputText } from '@/components/inputs'
import { ValidationMixin } from '@/mixins'

export default {
  name: 'LinkResetForm',
  components: { InputText },
  mixins: [ValidationMixin],
  data() {
    return {
      isFormVisible: true,
      isConfirmationVisible: false,
      failMessage: null
    }
  },
  created() {
    this.createValidation({
      email: {
        isRequired: true,
        regex:
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        messages: {
          empty: this.$t('forms.email.empty'),
          pattern: this.$t('forms.email.pattern')
        }
      }
    })
  },
  methods: {
    toggle() {
      this.$emit('toggle')
    },
    submitForm() {
      const data = this.getValidationFields()

      http
        .$post(`/api/forgot-password?lang=${this.$lang.current}`, data)
        .then(() => {
          this.showConfirmation()
        })
        .catch(({ response }) => {
          const { email } = response.data.errors
          this.failMessage = email.toString()
        })
    },
    showConfirmation() {
      this.isFormVisible = false
      this.isConfirmationVisible = true
    }
  }
}
</script>
