<template>
  <LangRouterLink class="sector-card" :to="{ name: 'businessIndex', query: { sect: String(sector.id) }, hash: '#top' }">
    <div>
      <h3 class="sector-card__title">{{ sector.name }}</h3>
      <p v-if="showDescription && sector.description">{{ sector.description }}</p>
    </div>

    <p class="sector-card__link">{{ $t('app.links.sectorCard') }}<Icon id="arrow" /></p>
    <img :src="srcBadge" alt="" class="sector-card__badge" />
  </LangRouterLink>
</template>

<script>
export default {
  name: 'SectorCard',
  props: { id: { type: Number, required: true }, showDescription: { type: Boolean, default: true } },
  computed: {
    sector() {
      return this.$beet.sectors[this.id]
    },
    srcBadge() {
      return `/badges/sect_c_${this.id}.svg`
    }
  }
}
</script>
