<template>
  <div class="input">
    <label v-if="label" :for="fieldMeta.id" :class="labelClassName">{{ label }}<span v-if="isRequired">*</span></label>

    <select
      :id="fieldMeta.id"
      :name="name"
      :class="fieldClassName"
      @input="updateValue"
      @blur="$emit('single-validate', name)"
    >
      <template v-for="(option, key, i) in options">
        <option v-if="!i" :key="key" value="">{{ option }}</option>
        <option v-else :key="key" :value="key" :selected="value === key">{{ option }}</option>
      </template>
    </select>

    <p v-if="error && showValidation" :class="errorClassName">
      <Icon id="warning" class="input__warning" />{{ errorMessage }}
    </p>
  </div>
</template>

<script>
import randomId from 'random-entities/id'

export default {
  name: 'InputSelect',
  props: {
    options: { type: Object, required: true },
    name: { type: String, required: true },
    label: { type: String, default: null },
    value: { type: String, default: null },
    errorClass: { type: [String, Array, Object], default: Array },
    inputClass: { type: [String, Array, Object], default: Array },
    labelClass: { type: [String, Array, Object], default: Array },
    isRequired: { type: Boolean, default: false },
    showValidation: { type: Boolean, default: false },
    error: { type: Boolean, default: false },
    errorMessage: { type: String, default: null }
  },
  data() {
    const generatedId = randomId()
    const id = this.id || generatedId

    return {
      fieldMeta: { id, name: this.name || id }
    }
  },
  computed: {
    fieldClassName() {
      return [
        'input__field',
        this.inputClass,
        { '-error': this.error && this.showValidation, '-textarea': this.textarea }
      ]
    },
    labelClassName() {
      return ['input__label', this.labelClass]
    },
    errorClassName() {
      return ['input__error', this.errorClass]
    }
  },
  methods: {
    updateValue({ target }) {
      this.$emit('input', target.value)
    }
  }
}
</script>
