<template>
  <div :class="{ '-loading': !$beet.isReady || !$page }">
    <template v-if="$beet.isReady && $page">
      <header class="header" :style="backgroundImage">
        <div v-scroll:reveal="{ delay: 450 }">
          <Icon id="instant" class="header__badge"/>
        </div>
        <h1 v-scroll:reveal="{ delay: 550 }" class="header__title -max-width">{{ $t('maturity.title') }}</h1>
      </header>

      <section class="maturity-form__section" style="padding-top: 2.5rem">
        <LangRouterLink :to="{name: 'maturity'}" class="link maturity-form__back">
          <Icon id="arrow"/>
          {{ $t('app.back') }}
        </LangRouterLink>

        <div class="maturity-report">
          <div class="maturity-report__head">
            <h3 class="maturity-form__content-title">{{ $page.email.title }}</h3>
            <p>{{ $page.email.description }}</p>
            <br/>
            <transition name="fade" mode="out-in">
              <div v-if="sent" key="sent" class="maturity-report__head-transition-wrap">
                <img src="/badges/confirm.svg" width="34" height="36" class="maturity-report__head-sent-icon"/>
                <div class="maturity-report__head-sent-label">{{ $t('maturity.report.sent') }}</div>
                <button class="button -dark" @click="sent = false">{{ $t('maturity.report.send_again') }}</button>
              </div>

              <div v-else class="maturity-report__head-transition-wrap">
                <InputText v-model="email" :label="$t('maturity.report.email')" input-class="maturity-report__head-input"/>
                <button class="button -dark" @click="sendEmail">{{ $t('app.send') }}</button>
              </div>
            </transition>
          </div>

          <div class="maturity-report__content">
            <h3 class="maturity-form__content-title">{{ $page.level.title }}</h3>

            <div class="maturity__tag -big"
                 :style="{backgroundColor: levels[general].backgroundColor, color: '#fff'}">
              <Icon :id="levels[general].icon" class="maturity__tag-icon"/>
              {{ `${general}. ${levels[general].title}` }}
            </div>

            <div class="maturity-report__objectif">
              <span class="maturity-report__objectif-blue">
                {{ $page.level.objective }} <Icon id="carret_right"/>
              </span>
              {{ levels[general].objectif.title }}
            </div>

            <p>
              {{ levels[general].objectif.text }}
            </p>

            <section class="maturity-report__section">
              <h3 class="maturity-report__section-title">{{ $page.level.profile.title }}</h3>
              <p> {{ $page.level.profile.description }} </p>

              <MaturityGraph v-if="ready" :dataset="chartData"/>
            </section>

            <section class="maturity-report__section">
              <template v-if="$device.tablet">
                <h3 class="maturity-report__section-title">{{ $page.level.recommendations.title }}</h3>
                <p> {{ $page.level.recommendations.description }} </p>
              </template>

              <div class="maturity-report__tabs">
                <div v-for="(item, key) in dimensions" :key="key"
                     class="maturity-report__tabs-tab"
                     :style="{backgroundColor: tab === key ? item.color : '', color: tab === key ? '#fff' : item.color}"
                     @click="tab = key"
                >
                  <Icon :id="item.icon"/>
                  {{ item.title }}
                </div>
              </div>
              <div class="maturity-report__tab">
                <div class="maturity-report__tab-head" :style="`backgroundColor: ${dimensions[tab].backgroundColor}`">
                  <span class="maturity-report__tab-icon" :style="`color: ${dimensions[tab].color}`" >
                    <Icon id="carret_right"/>
                  </span>
                  {{ $t('maturity.report.action') }}
                </div>

                <div class="maturity-report__tab-content">
                  <div class="maturity-report__tab-title">
                    {{ $page.level.recommendations.result }}
                  </div>

                  <div class="maturity__tag" :style="{backgroundColor: levels[dimensions[tab].level].backgroundColor, color: '#fff'}">
                    <Icon :id="levels[dimensions[tab].level].icon" class="maturity__tag-icon"/>
                    {{ $beet.maturityDimensions[dimensions[tab].id].levels[Number(dimensions[tab].level) - 1].title }}
                  </div>
                  <br/>
                  <div class="raw-html"
                       :style="`--bullet-color: ${dimensions[tab].color}`"
                       v-html="dimensions[tab].levels[dimensions[tab].level]"/>

                </div>
              </div>
            </section>

            <section class="maturity-report__download">
              <div class="maturity-report__download-img" :style="{backgroundImage: `url(/images/maturite_rapport_${$i18n.locale}.png)`}"/>

              <div class="maturity-report__download-content">
                <p> {{ $page.report.title }} </p>
                <div class="link maturity-report__download-link" @click="download">
                  {{ $page.report.download }} <Icon id="download"/>
                </div>
              </div>
            </section>

          </div>
        </div>
      </section>

      <section class="maturity-form__section">
        <div class=" maturity-report__links">
          <div class="maturity-report__links-top">
            {{ $page.action.title }}
          </div>

          <img src="/icones/confirmation.svg" width="34" height="34" alt="confirmation" class="maturity-report__links-confirmation">
          <div class="maturity-report__links-title">{{ $page.action.partner.title }}</div>
          <p>{{ $page.action.partner.description }}</p>

          <div class="maturity-report__links-links">
            <div class="row -wrap -center">
              <div v-for="(link, key) in actorLinks" :key="key" class="row__item -span-3 -skip-1 row -mobile maturity-report__links-link">
                <Icon :id="link.icon" class="maturity-report__links-link-icon"/>
                <LangRouterLink :to="link.route" class="link maturity-report__links-link-label">
                  <div v-if="$device.laptop">
                    {{link.label}}
                    <div class="maturity-report__links-link-arrow" >
                      <Icon id="arrow"/>
                    </div>
                  </div>
                  <template v-else>
                  <span style="max-width: 210px">
                    {{link.label}}
                    <span class="maturity-report__links-link-arrow" >
                      <Icon id="arrow"/>
                    </span>
                  </span>
                  </template>

                </LangRouterLink>
              </div>
            </div>
          </div>
        </div>

      </section>
    </template>
  </div>
</template>

<script>
import InputText from "@/components/inputs/InputText.vue";
import MaturityGraph from "@/components/partials/MaturityGraph.vue";
import {PageMetaMixin} from "@/mixins";
import axiosInstance from "@/extensions/Http";

export default {
  name: "MaturityReport",
  components: {MaturityGraph, InputText},
  mixins: [PageMetaMixin],
  beetPage: 'maturity-results',
  data() {
    return {
      ready: false,
      tab: 'strategy',
      sent: false,
      email: '',
      general: 2,
      levels: {
        1: {
          title: this.$t('maturity.levels.1.title'),
          backgroundColor: '#C6C9FD',
          icon: 'niv_exploration',
          objectif: {}
        },
        2: {
          title: this.$t('maturity.levels.2.title'),
          backgroundColor: '#B3B6FD',
          icon: 'niv_experimentation',
          objectif: {}
        },
        3: {
          title: this.$t('maturity.levels.3.title'),
          backgroundColor: '#9A9EFC',
          icon: 'niv_formalisation',
          objectif: {}
        },
        4: {
          title: this.$t('maturity.levels.4.title'),
          backgroundColor: '#7F85FB',
          icon: 'niv_optimisation',
          objectif: {}
        },
        5: {
          title: this.$t('maturity.levels.5.title'),
          backgroundColor: '#646BFB',
          icon: 'niv_transformation',
          objectif: {}
        }
      },
      dimensions: {
        strategy: {
          id:1,
          title: this.$t('maturity.dimension.strategy.title'),
          color: '#FE9F41',
          backgroundColor: '#FFF5EB',
          icon: 'dim_strategie',
          level: 1,
          levels: this.$beet.maturityDimensions[1].levels.reduce(this.levelsReducer, {}),
        },
        data: {
          id: 2,
          title: this.$t('maturity.dimension.data.title'),
          color: '#5F96FF',
          backgroundColor: '#EEF4FF',
          icon: 'dim_donnees',
          level: 1,
          levels: this.$beet.maturityDimensions[2].levels.reduce(this.levelsReducer, {}),
        },
        technology: {
          id: 3,
          title: this.$t('maturity.dimension.technology.title'),
          color: '#009ECE',
          backgroundColor: '#EBF7FB',
          icon: 'dim_tech',
          level: 3,
          levels: this.$beet.maturityDimensions[3].levels.reduce(this.levelsReducer, {}),
        },
        rh: {
          id: 4,
          title: this.$t('maturity.dimension.rh.title'),
          color: '#BA026F',
          backgroundColor: '#F9F0F5',
          icon: 'dim_rh',
          level: 4,
          levels: this.$beet.maturityDimensions[4].levels.reduce(this.levelsReducer, {}),
        },
        govern: {
          id: 5,
          title: this.$t('maturity.dimension.govern.title'),
          color: '#EE2D64',
          backgroundColor: '#FEEDF2',
          icon: 'dim_gouvernance',
          level: 4,
          levels: this.$beet.maturityDimensions[5].levels.reduce(this.levelsReducer, {}),
        }
      },
    }
  },
  computed: {
    actorLinks() {
      const ids = [1,2,3,4,5,7]
      const links = []
      ids.forEach(id => {
        links.push({
          icon: `act_${id}`,
          label: this.$beet.actorTypes[id].name,
          route: {name: 'businessIndex', query: {act: id}}
        })
      })
      return links
    },
    backgroundImage() {
      return this.$device.tablet
          ? `background-image: url('/images/banner_maturity.png')`
          : `background-image: url('/images/banner_maturity_m.png')`
    },
    chartData() {
      return {
        labels: [
          this.$t('maturity.dimension.strategy.formTitle'),
          this.$t('maturity.dimension.data.formTitle'),
          this.$t('maturity.dimension.technology.formTitle'),
          this.$t('maturity.dimension.rh.formTitle'),
          this.$t('maturity.dimension.govern.formTitle')
        ],
        datasets: [{
          data: Object.values(this.dimensions).map(({level}) => level),
          backgroundColor: 'rgba(84,92,255, 0.2)',
          borderColor: '#545cff',
          borderWidth: 1
        }]
      }
    }
  },
  watch: {
    '$page': {
      handler(to) {
        if(to) {
         Object.values(this.levels).forEach((level, key) => {
           level.objectif = {
             title: this.$page.level[`l${key + 1}`].objective,
             text: this.$page.level[`l${key + 1}`].description
           }
         })
        }
      },
      immediate: true
    }
  },
  beforeMount() {
    axiosInstance.$get('/api/maturity/levels ').then(response => {
      this.general = response.data.global
      Object.keys(this.dimensions).forEach((key, index) => {
        this.dimensions[key].level = response.data.dimensions[index + 1]
      })
      this.ready = true
    }).catch(() => {
      this.$router.replace(this.$i18n.locale === 'fr' ? '/maturite' : '/en/maturity')
    })
  },
  methods: {
    download() {
      axiosInstance.$post('/api/maturity/guide')
      window.open(this.$page.report.file[this.$i18n.locale], '_blank')
    },
    levelsReducer(acc, level, index) {
      const obj = {}
      obj[index + 1] = level.text
      return {...acc, ...obj}
    },
    sendEmail() {
      axiosInstance.$post('/api/maturity/email', { email: this.email}).then(() => {
        this.sent = true
        this.email = ''
      }).catch(() => {
        this.email = ''
      })
    }
  }
}
</script>
