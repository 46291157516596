<template>
  <div class="maturity-report__graph">
    <canvas ref="chart">

    </canvas>
  </div>

</template>

<script>
import {Chart, registerables} from 'chart.js'

export default {
  name: "MaturityGraph",
  props: {
    dataset: {
      type: Object
    }
  },
  data() {
    return {
      chart: null,
      chartOptions: {
        pointBackgroundColor: '#545cff',
        pointBorderColor: '#545cff',

        plugins: {
          legend: {
            display: false
          },
        },
        scales: {
          r: {
            pointLabels: {
              color: ['#FE9F41', '#5F96FF', '#009ECE', '#BA026F', '#EE2D64'],
              font: {
                size: 13,
                family: 'GalanoGrotesque-Medium',
              },
            },
            beginAtZero: true,
            min: 0,
            max: 5,
            angleLines: {
              color: '#D0D2FD'
            },
            grid: {
              color: '#D0D2FD'
            },
            ticks: {
              stepSize: 1,
              color: '#D0D2FD',
              font: {
                size: this.$device.laptop ? 18 : 13,
                family: 'GalanoGrotesque-regular',
              },
            }
          },
        }
      }
    }

  },
  mounted() {
    Chart.register(...registerables)
    this.chart = new Chart(this.$refs.chart, {
      type: 'radar',
      data: this.dataset,
      options: this.chartOptions
    })
  }
}
</script>
