<template>
  <aside class="study-side-bar">
    <figure v-if="logo" class="study-side-bar__logo" :style="backgroundImage" :title="name" />

    <RawHtml v-if="description" :html="description" />

    <button v-if="$device.customlaptop" class="business-side-contact__scroll-up link -scroll-up" @click="scrollTop">
      {{ $t('app.nav.scrollUp') }}
      <Icon id="arrow" />
    </button>
  </aside>
</template>

<script>
import { Icon } from '@/components/global'

export default {
  name: 'StudySideBar',
  components: {
    Icon
  },
  props: {
    logo: { type: String, default: null },
    description: { type: String, default: null }
  },
  computed: {
    backgroundImage() {
      return `background-image: url('${this.logo}')`
    }
  },
  methods: {
    scrollTop() {
      this.$scroll.toTop(0, 300)
      this.$router.replace({ hash: '' })
    }
  }
}
</script>
