<template>
  <div class="tuto-modal">
    <div class="tuto-modal__content">
      <h2 class="tuto-modal__title">{{ content.title }}</h2>
      <div class="tuto-modal__badge">
        <span class="tuto-modal__circle"></span>
        <img src="/badges/auth_c.svg" alt="" class="tuto-modal__img" />
      </div>

      <p class="tuto-modal__subtitle">{{ content.subtitle }}</p>
      <p>{{ content.text }}</p>
      <div class="tuto-modal__buttons">
        <Button class="button -dark -fullwidth-mob" @click="closeModal()">{{ content.buttonLabel }}</Button>
      </div>
    </div>

    <Button
      class="tuto-modal__close button -close"
      :aria-label="$t('app.ariaLabel.closeConnexion')"
      @click="closeModal()"
    >
      <Icon id="close" />
    </Button>
  </div>
</template>

<script>
export default {
  name: 'Tutorial',
  computed: {
    content() {
      return this.$beet.options.portalmodal
    }
  },
  methods: {
    closeModal() {
      const { tuto, ...query } = this.$route.query
      this.$router.push({ query })
      this.setTutoTimestamp()
    },
    setTutoTimestamp() {
      localStorage.setItem('tutorial-timestamp', Date.now())
    }
  }
}
</script>
