import { defineValidation } from '@/helpers'

export default {
  data() {
    return {
      validation: null
    }
  },
  methods: {
    createValidation(params) {
      this.validation = defineValidation(params)
    },
    validateSingleField(name) {
      if (!this.validation[name].value && this.validation[name].isRequired) {
        this.validation[name].isInvalid = true
        this.validation[name].showValidation = true
        this.validation[name].currentMessage = this.validation[name].messages.empty
      } else if (!this.validation[name].regex.test(this.validation[name].value)) {
        this.validation[name].isInvalid = true
        this.validation[name].showValidation = true
        this.validation[name].currentMessage = this.validation[name].messages.pattern
      } else {
        this.validation[name].isInvalid = false
        this.validation[name].showValidation = false
      }
    },
    validatePasswordConfirmation(name) {
      if (this.validation[name].value !== this.validation.password.value) {
        this.validation[name].isInvalid = true
        this.validation[name].showValidation = true
        this.validation[name].currentMessage = this.validation[name].messages.pattern
      } else {
        this.validation[name].isInvalid = false
        this.validation[name].showValidation = false
      }
    },
    validate(name) {
      if (name) {
        this.validation[name].isPasswordConfirmation
          ? this.validatePasswordConfirmation(name)
          : this.validateSingleField(name)
      } else {
        Object.keys(this.validation).forEach(field => {
          this.validation[field].isPasswordConfirmation
            ? this.validatePasswordConfirmation(field)
            : this.validateSingleField(field)
        })
        if (this.isFormValid()) this.submitForm()
      }
    },
    isFormValid() {
      return Object.values(this.validation).every(({ isInvalid }) => !isInvalid)
    },
    getValidationFields() {
      return Object.entries(this.validation).reduce((dataMap, [name, { value, compute }]) => {
        return { ...dataMap, [name]: compute(value) }
      }, {})
    }
  }
}
