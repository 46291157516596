<template>
  <form @submit.prevent="checkForm" class="maturity-form__content -larger" v-if="isReady">
    <p v-if="showErrorMessage" class="space-form__error">{{ $t('app.globalError') }}</p>
    <p class="space-form__title">{{ page.steps.access.title }}</p>
    <p>{{ page.steps.access.subtitle }}</p>
    <div class="space-form__flex">
      <InputText
        id="first_name"
        name="first_name"
        :label="page.user.firstname"
        :value="formData.firstname"
        :show-validation="true"
        class="space-form__input"
        :error="!!errors.firstname"
        :error-message="errors.firstname || ''"
        @input="value => {formData.firstname = value; $emit('remove-error', 'firstname')}"
      />
      <InputText
        id="last_name"
        name="last_name"
        :label="page.user.lastname"
        :value="formData.lastname"
        :show-validation="true"
        class="space-form__input"
        :error="!!errors.lastname"
        :error-message="errors.lastname || ''"
        @input="value => {formData.lastname = value; $emit('remove-error', 'lastname')}"
      />
      <InputText
        id="email"
        name="email"
        :label="page.user.email"
        :value="formData.email"
        :show-validation="true"
        class="space-form__input"
        :error="!!errors.email"
        :error-message="errors.email || ''"
        @input="(value) => { formData.email = value; delete errors.email; $emit('remove-error', 'email') }"
      />
      <InputText
        id="email_check"
        name="email_check"
        :label="page.user.emailConf"
        :value="email_check"
        :show-validation="true"
        class="space-form__input"
        :error="!!errors.email"
        :error-message="errors.email || ''"
        @input="(value) => { email_check = value; delete errors.email; $emit('remove-error', 'email') }"
      />
      <InputText
        id="password"
        name="password"
        :label="page.user.password"
        :value="formData.password"
        :show-validation="true"
        class="space-form__input"
        :is-password="true"
        :show-eye="true"
        :error="!!errors.password"
        :error-message="errors.password || ''"
        @input="value => {formData.password = value; delete errors.password; $emit('remove-error', 'password')}"
      />
      <InputText
        id="password_check"
        name="password_check"
        :label="page.user.passwordConf"
        :value="formData.password_confirmation"
        :show-validation="true"
        class="space-form__input"
        :is-password="true"
        :show-eye="true"
        :error="!!errors.password"
        :error-message="errors.password || ''"
        @input="value => {formData.password_confirmation = value; delete errors.passwor; $emit('remove-error', 'password')}"
      />
    </div>
    <button type="button" :class="['filter-bar-modal__link -left', { '-active-filter': formData.newsletter }]"
            @click="formData.newsletter = !formData.newsletter"><span>{{ page.user.newsletter }}</span></button>
    <div class="space-form__flex -buttons">
      <button type="button" class="button -icon" @click="$emit('previous', formData)">
        <icon id="arrow" class="space-form__rotate" />
        <span>{{ $t("app.previous") }}</span></button>
      <button v-if="!isLastStep" class="button -dark -icon" :class="{ '-disabled': isDisabled }" type="submit"><span>{{ $t("app.next")
        }}</span>
        <icon id="arrow" />
      </button>
      <button v-else class="button -dark -icon" :class="{ '-disabled': isDisabled }" type="submit">{{ $t("app.submit")
        }}
      </button>
    </div>
    <template v-if="isLastStep">
      <p class="space-form__conditions">{{ page.conditions.preLink }}
        <lang-router-link :to="{ name: 'terms' }" class="space-form__link" target="_blank">{{ page.conditions.link }}</lang-router-link>
        {{ page.conditions.postLink }}
      </p>
    </template>
  </form>
</template>

<script>
import InputText from "@/components/inputs/InputText.vue";

const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export default {
  name: "FormStep2",
  components: { InputText },
  props: {
    commonErrors: Object,
    page: Object,
    commonData: Object,
    otherData: Object,
    isLastStep: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      email_check: "",
      formData: {
        firstname: "",
        lastname: "",
        email: "",
        password: "",
        newsletter: false,
        password_confirmation: "",
      },
      errors: {},
      isReady: true,
      showErrorMessage: false
    };
  },
  computed: {
    isDisabled() {
      return !this.formData.firstname ||
        !this.formData.lastname ||
        !this.formData.email ||
        !this.email_check ||
        !this.formData.password ||
        !this.formData.password_confirmation;
    }
  },
  watch: {
    commonErrors: {
      deep: true,
      handler(to) {
        this.isReady = false;
        this.showErrorMessage = false;
        if (to.global) {
          this.showErrorMessage = true;
        }
        if (to.firstname) {
          this.errors.firstname = to.firstname[0];
        }
        if (to.lastname) {
          this.errors.lastname = to.lastname[0];
        }
        if (to.email) {
          this.errors.email = to.email[0];
        }
        if (to.password) {
          this.errors.password = to.password[0];
        }
        this.isReady = true;
      }
    }
  },
  mounted() {
    if (this.commonData.firstname) {
      this.formData.firstname = this.commonData.firstname;
    }
    if (this.commonData.lastname) {
      this.formData.lastname = this.commonData.lastname;
    }
    if (this.commonData.email) {
      this.formData.email = this.commonData.email;
    }
    if (this.otherData.email_check) {
      this.email_check = this.otherData.email_check;
    }
    if (this.commonData.password) {
      this.formData.password = this.commonData.password;
    }
    if (this.commonData.password_confirmation) {
      this.formData.password_confirmation = this.commonData.password_confirmation;
    }
    if (this.commonData.newsletter) {
      this.formData.newsletter = this.commonData.newsletter;
    }
    if (Object.values(this.commonErrors).length > 0) {
      this.isReady = false;
      this.showErrorMessage = false;
      if (this.commonErrors.global) {
        this.showErrorMessage = true;
      }
      if (this.commonErrors.firstname) {
        this.errors.firstname = this.commonErrors.firstname[0];
      }
      if (this.commonErrors.lastname) {
        this.errors.lastname = this.commonErrors.lastname[0];
      }
      if (this.commonErrors.email) {
        this.errors.email = this.commonErrors.email[0];
      }
      if (this.commonErrors.password) {
        this.errors.password = this.commonErrors.password[0];
      }
      this.isReady = true;
    }
  },
  methods: {
    checkForm() {
      if (!this.isDisabled) {
        this.errors = {};

        if (this.formData.email.trim().toLowerCase() !== this.email_check.trim().toLowerCase()) {
          this.errors.email = this.page.error.emailIdentical;
        } else if (!EMAIL_REGEX.test(this.formData.email)) {
          this.errors.email = this.page.error.emailValid;
        }

        if (this.formData.password.trim() !== this.formData.password_confirmation.trim()) {
          this.errors.password = this.page.error.passwordIdentical;
        }

        if (Object.values(this.errors).length === 0) {
          this.$emit("next-step", {commonData: this.formData, otherData: { email_check: this.email_check }});
        }
      }
    }
  }

};
</script>

<style scoped>

</style>
