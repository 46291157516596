export default {
  income: {
    250000: '0 $ à 0,5 million $ (CAD)',
    1250000: 'De 0,5 million à 2 millions $ (CAD)',
    6000000: 'De 2 millions à 10 millions $ (CAD)',
    55000000: 'De 10 millions à 100 millions $ (CAD)',
    425000000: 'Plus de 100 millions $ (CAD)',
    unknown: 'Préfère ne pas répondre à la question'
  },
  size: {
    10: 'Moins de 10 employés',
    30: 'De 10 à 49 employés',
    75: 'De 50 à 99 employés',
    175: 'De 100 à 249 employés',
    375: 'De 250 à 499 employés',
    750: 'De 500 à 999 employés',
    1000: 'Plus de 1000 employés',
    employee: 'employé',
    employees: 'employés'
  },
  client: {
    business: 'Entreprises',
    school: "Établissements d'enseignement",
    government: 'Gouvernements',
    worker: 'Travailleurs',
    citizen: 'Citoyens',
    consumer: 'Consommateurs'
  },
  motivation: {
    rd: 'R&D',
    commerce: 'Commercialisation',
    dev: 'Développement de marché',
    export: 'Exportation',
    growth: 'Croissance',
    tech: 'Expertise technique',
    complementary: 'Expertise complémentaire',
    talent: 'Talent',
    support: 'Financement',
    none: 'Nous ne cherchons aucun partenaire pour le moment'
  },
  partnership: {
    actual: 'Actuels',
    wanted: 'Recherchés'
  }
}
