<template>
  <div>
    <form id="connexion" class="form -connexion" @submit.prevent="validate()">
      <div class="form__intro">
        <h2 class="form__title">{{ content.title }}</h2>
        <p>{{ content.subtitle }}</p>
      </div>
      <div>
        <InputText
          v-model="validation.email.value"
          name="email"
          :label="$t('forms.email.label')"
          :show-validation="validation.email.showValidation"
          :error="validation.email.isInvalid"
          :error-message="validation.email.currentMessage"
          @single-validate="validate"
        />

        <InputText
          v-model="validation.password.value"
          name="password"
          :is-password="true"
          :label="$t('forms.password.label')"
          :show-validation="validation.password.showValidation"
          :error="validation.password.isInvalid"
          :error-message="validation.password.currentMessage"
          @single-validate="validate"
        />
        <p v-if="failMessage" class="input__error"><Icon id="warning" class="input__warning" />{{ failMessage }}</p>

        <input type="button" class="form__forgot" :value="$t('forms.links.reset')" @click="toggle" />
      </div>
    </form>
    <div class="form__buttons">
      <input type="button" class="button" :value="$t('forms.buttons.cancel')" @click="close" />
      <button form="connexion" name="connexion" class="button -dark">{{ $t('forms.buttons.connection') }}</button>
    </div>
  </div>
</template>

<script>
import http from '@/extensions/Http'

import { InputText } from '@/components/inputs'
import { ValidationMixin } from '@/mixins'

export default {
  name: 'ConnexionForm',
  components: { InputText },
  mixins: [ValidationMixin],
  data() {
    return {
      failMessage: null,
      isTutorialSet: !localStorage.getItem('tutorial-timestamp')
    }
  },
  computed: {
    content() {
      return this.$beet.options.connectionform
    }
  },
  created() {
    this.createValidation({
      email: {
        isRequired: true,
        regex:
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        messages: {
          empty: this.$t('forms.email.empty'),
          pattern: this.$t('forms.email.pattern')
        }
      },
      password: {
        isRequired: true,
        regex: /^[a-zA-ZÀ-ÿ0-9?$@#()'!,+\-=_:.&€£*%\s]{0,50}$/,
        messages: {
          empty: this.$t('forms.password.empty')
        }
      }
    })
  },
  methods: {
    toggle() {
      this.$emit('toggle')
    },
    close() {
      this.$emit('close')
    },
    submitForm() {
      const data = this.getValidationFields()

      http
        .$post(`/api/login?lang=${this.$lang.current}`, data)
        .then(() => {
          http.get('/api/me/role').then((response) => {
            if(response.data.role === 'BusinessOwner') {
              this.$store.dispatch('updateIsAuth', {bool: false, role: response.data.role})
            }
            else {
              this.$store.dispatch('updateIsAuth', {bool: true, role: response.data.role}).then(() => {
                if (!this.isTutorialSet) {
                  this.close()
                } else {
                  this.close()
                  const { connexion, ...query } = this.$route.query
                  this.$router.push({ query: { tuto: true, ...query } })
                }
              })
            }
          })
        })
        .catch(({ response }) => {
          const { email } = response.data.errors
          this.failMessage = email.toString()
        })
    }
  }
}
</script>
