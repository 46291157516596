<template>
  <div class="socials">
    <a target="_blank" rel="noopener" :href="socials.facebook" aria-label="Facebook"
      ><icon id="facebook" class="socials__icon -facebook"
    /></a>
    <a target="_blank" rel="noopener" :href="socials.linkedin" aria-label="LinkedIn"
      ><icon id="linkedin" class="socials__icon"
    /></a>
    <a target="_blank" rel="noopener" :href="socials.twitter" aria-label="Twitter"
      ><icon id="x" class="socials__icon"
    /></a>
  </div>
</template>

<script>
export default {
  name: 'Socials',
  computed: {
    socials() {
      return this.$beet.options.socials
    }
  }
}
</script>
