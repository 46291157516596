const axios = require('axios')

const axiosInstance = axios.create({ baseURL: process.env.VUE_APP_API_URL })

axiosInstance.defaults.withCredentials = true

axiosInstance.$post = (url, data) =>
  new Promise((resolve, reject) => {
    if(!axiosInstance.csrf) axiosInstance.get('/sanctum/csrf-cookie')

    axiosInstance
      .post(url, data)
      .then(response => resolve(response))
      .catch(error => reject(error))
      .catch(error => reject(error))
  })

axiosInstance.$get = (url) =>
    new Promise((resolve, reject) => {
        if(!axiosInstance.csrf) axiosInstance.get('/sanctum/csrf-cookie')

        axiosInstance
        .get(url)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })

axiosInstance.$init = () => {
    axiosInstance.csrf = true
    return axiosInstance.get('/sanctum/csrf-cookie')
}

export default axiosInstance
