<template>
  <div :class="['study-card -home', { '-hover': hover }]">
    <div>
      <div @mouseenter="hover = true" @mouseleave="hover = false">
        <LangRouterLink :to="{ name: 'studyShow', params: { studySlug: study.slug } }" class="study-card__title"
          @focus.native="hover = true" @blur.native="hover = false">{{ study.title }}</LangRouterLink>
      </div>
      <p class="study-card__subtitle">{{ $t('app.by') }} {{ study.companyName }}</p>
    </div>
    <div>
      <div @mouseenter="hover = true" @mouseleave="hover = false">
        <LangRouterLink :to="{ name: 'studyShow', params: { studySlug: study.slug } }"
          :class="['study-card__link', { '-hover': hover }]" @focus.native="hover = true" @blur.native="hover = false">
          {{ $t('app.links.studyCard') }}
          <Icon id="arrow" />
        </LangRouterLink>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StudyHomeCard',
  props: {
    id: { type: Number, required: true }
  },
  data() {
    return {
      hover: false
    }
  },
  computed: {
    study() {
      return this.$beet.studies[this.id]
    }
  }
}
</script>
