<template>
  <header class="miria">
    <div class="miria__relative">
      <div class="miria__content">
        <img v-scroll:reveal="{ delay: 100 }" src="/images/miria_logo.svg" alt="" class="miria__logo">
        <RawHtml v-scroll:reveal="{ delay: 200 }" tag="h1" :html="$t('app.miria.title')" class="miria__title" />
        <div v-scroll:reveal="{ delay: 300 }" class="miria__details">
          <p>{{ $t('app.miria.description') }}</p>
          <RawHtml v-scroll:reveal="{ delay: 200 }" tag="span" :html="$t('app.miria.highlight')" />
          <p class="miria__note">
            <Icon id="miria_star" />
            <RawHtml v-scroll:reveal="{ delay: 200 }" tag="span" :html="$t('app.miria.note')" />
          </p>
        </div>
      </div>
      <video v-scroll:reveal="{ delay: 400 }" autoplay playsinline loop muted class="miria__video">
        <source src="https://assets.boitebeet.com/vitrine-ia/32/miria-promo-septembre-2024.mp4">
      </video>
    </div>
    <img src="/images/miria_pattern.png" alt="" class="miria__grid" />
    <img src="/images/miria_pattern.png" alt="" class="miria__grid --right" />
  </header>
</template>

<script>
import RawHtml from '@/components/global/RawHtml.vue'

export default {
  name: 'MiriaHeader',
  components: {RawHtml}
}
</script>
