<template>
  <div class="share">
    <div :class="['share__content', { 'is-open': providersVisible }]">
      <button v-if="hasShare" class="share__button" :aria-label="$t('app.ariaLabel.share')" @click="shareNavigator">
        <icon id="share" />
      </button>
      <button v-else class="share__button" :aria-label="$t('app.ariaLabel.shareMore')" @click="toggle()">
        <icon id="share" />
      </button>

      <transition appear mode="out-in" name="share__list">
        <div v-if="$device.laptop && providersVisible" class="share__list">
          <button
            v-for="provider in providers"
            :key="provider"
            :aria-label="provider"
            class="share__provider -desktop"
            @click="share(provider)"
          >
            <icon :id="provider" />
          </button>
        </div>
      </transition>
    </div>
    <button class="share__button" :aria-label="$t('app.ariaLabel.print')" @click="openPrint()">
      <Icon id="print" />
    </button>
  </div>
</template>

<script>
export default {
  name: 'Share',
  props: {
    title: { type: String, required: true }
  },
  data() {
    return {
      providersVisible: false,
      providers: ['facebook', 'linkedin', 'twitter']
    }
  },
  computed: {
    hasShare() {
      return navigator && navigator.share && !this.$device.laptop
    }
  },
  methods: {
    toggle() {
      this.providersVisible = !this.providersVisible
    },
    share(provider) {
      const platforms = {
        facebook: [
          `https://facebook.com/sharer/sharer.php?u=${window.location.href}`,
          '_blank',
          'fullscreen=no, width=500, height=700, toolbar=no, status=no, titlebar=no,left=500px'
        ],
        twitter: [
          `https://twitter.com/intent/tweet?text=Vitrine IA - ${this.title}&url=${window.location.href}`,
          '_blank',
          'fullscreen=no, width=500, height=700, toolbar=no, status=no, titlebar=no,left=500px'
        ],
        linkedin: [
          `https://www.linkedin.com/sharing/share-offsite/?summary=Vitrine IA - ${this.title}&url=${window.location.href}`,
          '_blank',
          'fullscreen=no, width=500, height=700, toolbar=no, status=no, titlebar=no,left=500px'
        ]
      }
      window.open(...platforms[provider])
    },
    shareNavigator() {
      if (navigator.share) {
        navigator.share({
          title: this.title,
          url: window.location.href
        })
      }
    },
    openPrint() {
      window.print()
    }
  }
}
</script>
