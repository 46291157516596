<template>
  <header id="up" class="header -show" :style="backgroundImage">
    <div class="header__flex">
      <LangRouterLink :to="{ name: destination }" class="header__link link -back" :class="{'-static': isBusinessHeader}">
        <Icon id="arrow" />{{ backLabel }}
      </LangRouterLink>
      <div v-if="isBusinessHeader">
        <Share :title="title" class="header__share"/>
      </div>
    </div>

    <h1 v-scroll:reveal="{ delay: 550 }" class="header__title -show">{{ title }}</h1>
    <p v-scroll:reveal="{ delay: 650 }" class="header__subtitle -show">{{ subtitleContent }}</p>

    <Share v-if="!isBusinessHeader" v-scroll:reveal="{ delay: 750 }" :title="title" />

    <!-- Image for the printing -->
    <img :src="logo" alt="" class="single__image" />
  </header>
</template>

<script>
import Share from './Share.vue'
import { dateFormat } from '@/helpers'
import { Icon } from '@/components/global'

export default {
  name: 'HeaderShow',
  components: { Icon, Share },
  props: {
    destination: { type: String, required: true, validator: name => ['businessIndex', 'studyIndex'].includes(name) },
    object: { type: String, required: true },
    title: { type: String, required: true },
    businessName: { type: String, default: null },
    date: { type: String, required: true },
    logo: { type: String, required: false }
  },
  computed: {
    isBusinessHeader() {
      return this.object === 'business';
    },
    publishedAt() {
      return dateFormat(this.date, this.$lang.current)
    },
    backgroundImage() {
      return this.$device.customtablet
        ? `background-image: url('/images/show_${this.object}.png')`
        : `background-image: url('/images/show_${this.object}_m.png')`
    },
    backLabel() {
      return this.object === 'study' ? this.$t('app.nav.backStudies') : this.$t('app.nav.backBusinesses')
    },
    subtitleContent() {
      return this.object === 'study'
        ? `${this.$t('app.by')} ${this.businessName} | ${this.$t('app.publishedStudy')} ${this.publishedAt}`
        : `${this.$t('app.updated')} ${this.publishedAt}`
    }
  }
}
</script>
