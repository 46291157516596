import CookieBar from './CookieBar.vue'

// const localStorage = (() => {
//   const noop = () => {}

//   try {
//     localStorage.setItem('test', 0)
//     localStorage.getItem('test')
//     return localStorage
//   } catch (e) {
//     return {
//       setItem: noop,
//       getItem: noop,
//       removeItem: noop
//     }
//   }
// })()

export default Vue => {
  Vue.component('CookieBar', CookieBar)

  const CookieVM = new Vue({
    name: 'CookieVM',
    data() {
      return {
        isCookieBarVisible: !localStorage.getItem('cookie-timestamp'),
        isCookieSidePanelVisible: false
      }
    },
    watch: {
      isCookieSidePanelVisible() {
        this.isCookieSidePanelVisible ? this.$scroll.prevent() : this.$scroll.restore()
      }
    },
    methods: {
      acceptCookies() {
        this.setCookieTimestamp()
        this.setAnalyticsOption(true)
        this.isCookieBarVisible = false
      },
      acceptEssentials() {
        this.setCookieTimestamp()
        this.setAnalyticsOption(false)
        this.isCookieBarVisible = false
      },
      openSidePanel() {
        this.isCookieBarVisible = false
        this.isCookieSidePanelVisible = true
      },
      saveSidePanel(isAnalyticsGranted) {
        this.isCookieSidePanelVisible = false
        this.setCookieTimestamp()
        this.setAnalyticsOption(isAnalyticsGranted)
      },
      cancelSidePanel() {
        this.isCookieSidePanelVisible = false
        this.isCookieBarVisible = true
      },
      setAnalyticsOption(isAccepted) {
        localStorage.setItem('cookie-analytics-granted', isAccepted)
      },
      setCookieTimestamp() {
        localStorage.setItem('cookie-timestamp', Date.now())
      }
    }
  })

  Vue.prototype.$cookie = CookieVM
}
