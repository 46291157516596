<template>
  <footer class="footer">
    <div class="footer__content">
      <div class="footer__column">
        <img :src="footer_logo_src" :alt="$t('app.ariaLabel.footerLogo')" class="footer__logo" />
        <RawHtml class="footer__richtext" :html="content.text"></RawHtml>

        <Socials />
      </div>
      <template v-if="$device.tablet">
        <nav class="footer__column -padding">
          <h3 class="footer__subtitle">{{ $t('app.footer.actorTypes') }}</h3>
          <LangRouterLink
            v-for="actor in actors"
            :key="actor.id"
            class="footer__link"
            :to="{ name: 'businessIndex', query: { act: String(actor.id) }, hash: '#top' }"
            >{{ actor.name }}</LangRouterLink
          >
        </nav>

        <nav class="footer__column -padding">
          <h3 class="footer__subtitle">{{ $t('app.footer.categories') }}</h3>
          <LangRouterLink
            v-for="category in categories"
            :key="category.id"
            class="footer__link"
            :to="{ name: 'businessIndex', query: { type: String(category.id) }, hash: '#top' }"
            >{{ category.name }}</LangRouterLink
          >
        </nav>

        <nav class="footer__column -padding">
          <h3 class="footer__subtitle">{{ $t('app.footer.resources') }}</h3>
          <LangRouterLink class="footer__link" :to="{ name: 'studyIndex' }">{{ $t('app.nav.studies') }}</LangRouterLink>
          <LangRouterLink :to="{name: 'maturity'}" class="footer__link">{{ $t('app.footer.maturity') }}</LangRouterLink>
          <LangRouterLink :to="{name: 'documentIndex'}" class="footer__link">{{ $t('documents.page') }}</LangRouterLink>
          <LangRouterLink class="footer__link" :to="{ name: 'glossary' }">{{
              $t('app.footer.glossary')
            }}</LangRouterLink>
        </nav>

        <div class="footer__column">
          <LangRouterLink class="footer__eco" :to="openVideoModal"
            ><Icon id="meet-eco" />{{ $t('app.links.ecosystem') }}</LangRouterLink
          >
        </div>
      </template>
    </div>

    <div class="terms">
      <div>
        <p>
          © {{ currentYear }} {{ $t('app.companyName') }}<span> - </span>
          <LangRouterLink class="terms__link" :to="{ name: 'terms' }">{{
            $t('app.footer.terms')
          }}</LangRouterLink>
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
import { Socials } from '@/components/partials'

export default {
  name: 'SiteFooter',
  components: { Socials },
  data() {
    return {
      currentYear: new Date().getFullYear(),
      isNewsLetterOpen: false
    }
  },
  computed: {
    content() {
      return this.$beet.options.footer
    },
    socials() {
      return this.$beet.options.socials
    },
    actors() {
      const { items = [] } = this.content.businessType || {}
      return Object.values(items).map(id => this.$beet.actorTypes[id])
    },
    categories() {
      const { items = [] } = this.content.businessCategory || {}
      return Object.values(items).map(id => this.$beet.categories[id])
    },
    footer_logo_src() {
      return `/images/footer_logo_${this.$lang.current}.png`
    },
    openVideoModal() {
      return {
        name: this.$route.name,
        query: { ...this.$route.query, video: true }
      }
    }
  }
}
</script>
