<template>
  <div :class="['business-side-contact', { '-auth': isAuth }]">
    <figure v-if="business.logo" class="business-side-contact__logo" :style="backgroundImage" />

    <div v-if="actor" class="business-side-info__actor">
      <Icon :id="getActorIcon(business.actorType)" />
      <p>{{ actor.name }}</p>
    </div>

    <div v-if="business.isStartup" class="business-side-info__startup">
        <Icon id="startup" />
        <p>{{ $t('app.object.startupStart', { year: business.creationYear }) }}</p>
    </div>

    <div v-if="applicationSectors.length" class="business-side-info__list -sector">
      <LangRouterLink
        v-for="sector in applicationSectors"
        :key="sector.id"
        :to="{ name: 'businessIndex', query: { sect: String(sector.id) } }"
        class="tag -icon"
        :title="sector.name"
        ><Icon :id="getSectorIcon(sector.id)" /><span class="tag__tip">{{ sector.name }}</span></LangRouterLink
      >
    </div>

    <div class="business-side-contact__perk">
      <Icon id="region" />
      <p>{{ region.name }}{{ otherRegions.length ? ', ' + otherRegions.join(', ') : '' }}</p>
    </div>
    <a v-if="business.email" :href="`mailto:${business.email}`" class="business-side-contact__perk -link">
      <Icon id="mail"/>
      <p>{{ business.email }}</p>
    </a>
    <div v-if="business.phone" class="business-side-contact__perk">
      <Icon id="phone" />
      <p>{{ business.phone }}</p>
    </div>

    <div class="business-side-contact__socials">
      <a v-if="business.linkedin" target="_blank" rel="noopener" :href="business.linkedin" aria-label="LinkedIn">
        <icon id="linkedin" class="nav__icon" />
      </a>
      <a v-if="business.facebook" target="_blank" rel="noopener" :href="business.facebook" aria-label="Facebook">
        <icon id="facebook" class="nav__icon -facebook" />
      </a>
      <a v-if="business.twitter" target="_blank" rel="noopener" :href="business.twitter" aria-label="Twitter">
        <icon id="x" class="nav__icon" />
      </a>
    </div>

    <a
      v-if="business.webSite"
      target="_blank"
      rel="noopener"
      :href="business.webSite"
      class="button -link -dark business-side-contact__link"
    >
      {{ $t('app.links.businessWebsite') }}
    </a>

    <button v-if="$device.customlaptop" class="business-side-contact__scroll-up link -scroll-up" @click="scrollTop">
      {{ $t('app.nav.scrollUp') }}
      <Icon id="arrow" />
    </button>
  </div>
</template>

<script>
import { Icon } from '@/components/global'

export default {
  name: 'BusinessSideContact',
  components: {
    Icon
  },
  props: {
    business: { type: Object, require: true }
  },
  data() {
    return {
      sectorsRemovingID: 16
    }
  },
  computed: {
    isAuth() {
      return this.$store.state.user.isAuth
    },
    backgroundImage() {
      return `background-image: url('${this.business.logo}')`
    },
    region() {
      return this.$beet.regions[this.business.region]
    },
    actor() {
      return this.business.actorType ? this.$beet.actorTypes[this.business.actorType] : null
    },
    applicationSectors() {
      const { sectors = [] } = this.$beet || {}
      const selected = this.business.applicationSectors.filter(id => id !== this.sectorsRemovingID);
      return selected.map(id => sectors[id])
    },
    otherRegions() {
      const filtered = this.business.officesRegions.filter(id => this.business.region !== id);
      return filtered.map(id => this.$beet.regions[id].name);
    }
  },
  methods: {
    getWebSite(website) {
      return website.length < 40 ? website : `${website.substring(0, 37)}...`
    },
    scrollTop() {
      this.$scroll.toTop(0, 300)
      this.$router.replace({ hash: '' })
    },
    getActorIcon(id) {
      return `act_${id}`
    },
    getSectorIcon(id) {
      return `sect_${id}`
    }
  }
}
</script>
