export default {
  'update/user': (state, user) => {
    state.user = { ...state.user, ...user }
  },
  'update/businesses': (state, newBusinesses) => {
    state.randomBusinessIndex = newBusinesses
  },
  'update/studies': (state, newStudies) => {
    state.randomStudyIndex = newStudies
  }
}
