<template>

  <div class="instant-modal">
    <div class="instant-modal__close" @click="close"> <Icon id="close"/></div>
    <div class="instant-card -overflow -carte -region instant-flex row__item -laptop-half -modal">
      <template v-if="currentRegion">
        <div class="row -mobile -spread -align-center">
          <div class="row row__item -mobile -align-center">
            <div class="instant-card__number  " style="margin-right: 1rem;">{{ currentRegion.total || 0 }}</div>
            <div class="instant-card__title -nomax row__item -span-10">{{ $tc('app.instant.orgRegion', (currentRegion.total || 0)) }} <br/>
              {{ currentRegion.name }}
            </div>
          </div>
        </div>

        <div class="row -mobile instant-row">
          <div class="row__item -span-5 instant-region-card -col">
            <img class="instant-card__badge-top -small" src="/badges/pousse.svg"/>
            <div class="instant-card__number--small">{{ currentRegion.startups }}</div>
            <div class="instant-card__mobile-pousse">{{ $tc('app.instant.startupsRegion', currentRegion.startups) }}</div>
          </div>

          <div class="row__item -half" style="margin-left: 1rem">
            <div class=" instant-region-card">
              <div class="instant-card__number--small">{{ currentRegion.sizes.sm }}</div>
              <div>{{ $tc('app.instant.small', currentRegion.sizes.sm) }}</div>
            </div>

            <div class=" instant-region-card">
              <div class="instant-card__number--small">{{ currentRegion.sizes.md }}</div>
              <div>{{ $tc('app.instant.medium', currentRegion.sizes.md) }}</div>
            </div>

            <div class=" instant-region-card">
              <div class="instant-card__number--small">{{ currentRegion.sizes.lg }}</div>
              <div>{{ $tc('app.instant.big', currentRegion.sizes.lg) }}</div>
            </div>
            <small class="instant-card__graph-label">*{{ $t('app.instant.available') }}</small>
          </div>
        </div>

        <div>
          <h4 class="instant-card__graph-title">{{ $t('app.instant.actorType') }}</h4>
          <div class="instant-card__graph -region row__item -span-6">
            <div class="instant-card__graph-item -region" v-for="(item, index) in currentRegion.actors" :key="index"  @mouseenter="mapPop = index" @mouseleave="mapPop = null">
              <div class="instant-card__graph-line"
                   :style="{backgroundColor: '#545CFF', height: `${item.ratio}%`}">
                <transition name="fade-fast" appear mode="out-in">
                  <div class="instant-card__graph-pop-wrap" v-show="mapPop === index">
                    <div class="instant-card__graph-pop">{{ `${item.name} - ${item.total}` }}</div>
                  </div>
                </transition>
              </div>
              <div class="instant-card__graph-icon -small">
                <Icon :id="`act_${item.id}`"/>
              </div>
              <div class="instant-card__graph-label">{{ item.total }}</div>
            </div>
          </div>
        </div>

        <div style="margin-bottom: 1rem">
          <h4 class="instant-card__graph-title -sm">{{ $t('app.instant.sectors') }}</h4>
          <div class="instant-card__graph -region row__item -span-6" style="margin-top: -0.5rem;">
            <div v-for="(item, index) in currentSectors" :key="index"
                 class="instant-card__graph-item -region" @mouseenter="mapSectorPop = index"
                 @mouseleave="mapSectorPop = null">
              <div class="instant-card__graph-line"
                   :style="{backgroundColor: '#545CFF', height: `${item.ratio}%`}">
                <transition name="fade-fast" appear mode="out-in">
                  <div v-show="mapSectorPop === index" class="instant-card__graph-pop-wrap">
                    <div class="instant-card__graph-pop">{{ `${item.sector.name} - ${item.total}` }}</div>
                  </div>
                </transition>
              </div>
              <div class="instant-card__graph-icon -small">
                <Icon :id="`sect_${item.id}`"/>
              </div>
              <div class="instant-card__graph-label">{{ item.total }}</div>
            </div>
          </div>
        </div>

        <div class="instant-next">
          <div>{{ $t('app.instant.next') }} :</div>
          <div class="link instant-next__link" @click="toggleNextRegion">{{ nextRegion.name }}
            <icon id="arrow"/>
          </div>
        </div>

        <div class="instant-card__bottom -modal">
          <p>{{ $t('app.instant.missing') }}</p>
          <button class="button -dark" @click="openContactPanelLink">{{ $t('app.instant.suggest') }}</button>
        </div>
      </template>

      <template v-else>
        <div class="instant-target">
          <Icon id="cible"/>
          <h3>
            {{ $t('app.instant.select') }}
          </h3>
        </div>
      </template>
    </div>
  </div>
  </template>

<script>
export default {
  name: "Region",
  data() {
    return {
      mapPop: null,
      mapSectorPop: null
    }
  },
  methods: {
    close() {
      this.$emit('close')
      this.$router.go(-1)
    },
    toggleNextRegion() {
      this.$router.replace({
        query: {
          ...this.$route.query,
          regiondata: Number(this.nextRegion.code)
        }
      })
    },
    openContactPanelLink() {
      this.$router.push({
        name: this.$route.name,
        query: { contact: true }
      })
    },
  },
  computed: {
    selectedRegion() {
      return Number(this.$route.query.regiondata)
    },
    overview() {
      return this.$beet.overview
    },
    nextRegion() {
      if(!this.currentRegion) return null
      if(this.$route.query.quebec) {
        return this.selectedRegion === 3 ? this.$beet.regions[12] : this.$beet.regions[3]
      }
      const region = this.$beet.regions[this.selectedRegion === 17 ? 1 : this.selectedRegion + 1]
      return region
    },
    currentRegion() {
      if (this.selectedRegion) {
        if (!this.overview.regions[this.selectedRegion]) {
          return {
            ...this.$beet.regions[this.selectedRegion],
            total: 0,
            startups: 0,
            sizes: {sm: 0, md: 0, lg: 0},
            actorTypes: {}
          }
        }
        const actors = []
        Object.entries(this.overview.regions[this.selectedRegion].actorTypes).forEach(entry => {
          actors.push({
            id: entry[0],
            total: entry[1].total,
            name: this.$beet.actorTypes[entry[0]].name
          })
        })
        actors.sort((a, b) => {
          if (a.total < b.total) return 1
          else if (a.total > b.total) return -1
          else return 0
        })
        actors.forEach(actor => {
          actor.ratio = ((actor.total / actors[0].total) * 100) / 2
        })
        actors.reverse()
        return {...this.overview.regions[this.selectedRegion], ...this.$beet.regions[this.selectedRegion], actors}
      }
      return null
    },
    currentSectors() {
      const sectors = {}
      Object.entries(this.currentRegion.sectors).forEach(([id, sector]) => {
        sectors[id] = {
          startups: sector.startups,
          total: sector.total,
          sector: this.$beet.sectors[id]
        }
      })

      const sorted = Object.entries(sectors).sort((a, b) => {
        if (a[1].total < b[1].total) return 1
        else if (a[1].total > b[1].total) return -1
        return 0
      })
      const final = []
      const max = sorted[0][1].total
      const amount = 8
      for (let i = 0; i < amount; i++) {
        if(sorted[i]) {
          const temp = {
            id: Number(sorted[i][0]),
            sector: this.$beet.sectors[Number(sorted[i][0])],
            ratio: ((sorted[i][1].total / max) * 100).toFixed(0) / 2,
            ...sorted[i][1]
          }
          final.push(temp)
        }

      }
      return final.reverse()
    },
  }
}
</script>
