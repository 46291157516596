<template>
  <transition appear name="cookie-transition" appear-active-class="cookie-transition-appear" mode="out-in">
    <section v-if="$cookie.isCookieBarVisible" class="cookie-bar">
      <div class="cookie-bar__content">
        <p>
          {{ $t('app.cookies.content')
          }}<LangRouterLink :to="{ name: 'terms' }" class="cookie-bar__link">{{
            $t('app.cookies.linkLabel')
          }}</LangRouterLink>.
        </p>
        <div class="cookie-bar__buttons">
          <button class="button -dark" @click="$cookie.acceptCookies">
            <span>{{ $t('app.cookies.mainButton') }}</span>
          </button>
        </div>
      </div>
    </section>
  </transition>
</template>

<script>
export default {
  name: 'CookieBar'
}
</script>
