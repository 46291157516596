export const getMeta = seo => {
  return {
    title: seo.title || 'Vitrine - IA',
    meta: [
      { name: 'description', content: seo.description || '' },
      { name: 'twitter:card', content: 'summary'},
      { property: 'og:title', content: seo.title || 'Vitrine IA' },
      { property: 'og:description', content: seo.description || '' },
      { property: 'og:image', content: seo.image || `${window.location.href}/images/seo.jpg` },
      { property: 'og:locale', content: 'fr_CA' },
      { property: 'og:type', content: 'website' },
      { property: 'og:site_name', content: 'Vitrine - IA' }
    ]
  }
}
