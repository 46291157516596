import _get from 'lodash.get'

export const filterOptionFactory =
  (value, label = '') =>
  (source, filter = () => true) => {
    const filterHandler = typeof filter === 'string' ? obj => !!obj[filter] : filter

    return Object.values(source)
      .filter(filterHandler)
      .reduce((optionMap, option) => {
        const key = _get(option, value)
        return { ...optionMap, [key]: _get(option, label, key) }
      }, {})
  }
