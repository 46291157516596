<template>
  <div class="info-modal">
    <h2 class="info-modal__title">{{ title }}</h2>
    <RawHtml :html="content" class="info-modal__content" />
    <button class="info-modal__close button -close" :aria-label="$t('app.ariaLabel.closeFilter')" @click="close">
      <Icon id="close" />
    </button>
  </div>
</template>

<script>
export default {
  name: 'BusinessInfo',
  props: {
    title: { type: String, default: null },
    content: { type: String, default: null }
  },
  methods: {
    close() {
      this.$emit('close')
      this.setBetaTimestamp()
    },
    setBetaTimestamp() {
      localStorage.setItem('beta-timestamp', Date.now())
    }
  }
}
</script>
