<template>
  <LangRouterLink v-if="isActive" :to="to" :class="linkClassName" aria-label><slot></slot></LangRouterLink>
  <span v-else :class="linkClassName"><slot></slot></span>
</template>

<script>
export default {
  name: 'ConditionalLink',
  props: {
    to: { type: Object, default: Object },
    linkClass: { type: [String, Array, Object], default: Array },
    isActive: { type: Boolean, default: true },
    ariaLabel: { type: String, default: null }
  },
  computed: {
    linkClassName() {
      return [this.linkClass, { '-disabled': !this.isActive }]
    }
  }
}
</script>
