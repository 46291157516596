import _shuffle from 'lodash.shuffle'

export default {
  init({ commit }) {
    const initialUser = {
      isAuth: false
    }
    commit('update/user', initialUser)
  },
  updateIsAuth({ commit }, data) {
    const user = {
      isAuth: data.bool,
      role: data.role
    }
    commit('update/user', user)
  },
  updateRandomBusinesses({ commit }, data) {
    commit('update/businesses', _shuffle(data))
  },
  updateRandomStudies({ commit }, data) {
    commit('update/studies', _shuffle(data))
  }
}
