<template>
  <div class="video-modal">
    <div class="video-modal__content">
      <iframe width="100%" height="100%" :src="`https://www.youtube.com/embed/${videoId}?autoplay=1`"> </iframe>
    </div>
    <LangRouterLink :to="closeModalLink" class="video-modal__close" :aria-label="$t('app.ariaLabel.closeModal')"
      >&times;</LangRouterLink
    >
  </div>
</template>

<script>
export default {
  name: 'Video',
  computed: {
    closeModalLink() {
      const { video, object, ...query } = this.$route.query
      return { name: this.$route.name, query }
    },
    videoId() {
      const url = this.$beet.options.general.video
      return url.split('v=')[1]
    }
  }
}
</script>
