import Vue from 'vue'
import Vuex from 'vuex'
import LangRouter from '@boite-beet/lang-router'
import actions from '@/store/actions'
import mutations from '@/store/mutations'

Vue.use(Vuex)

const createSlugMap =
  typeKey =>
  ({ beet }) => {
    const data = beet.data[typeKey] || {}
    return Object.values(data).reduce((slugMap, { id, slug }) => ({ ...slugMap, [slug]: id }), {})
  }

export default new Vuex.Store({
  state: {
    user: {},
    randomBusinessIndex: [],
    randomStudyIndex: []
  },
  getters: {
    ...LangRouter.vuexGetters,
    studySlugMap: createSlugMap('studies'),
    businessSlugMap: createSlugMap('businesses')
  },
  actions,
  mutations
})
