const defaultValidation = {
  value: '',
  isRequired: false,
  regex: null,
  isInvalid: true,
  showValidation: false,
  isPasswordConfirmation: false,
  messages: {
    empty: '',
    pattern: ''
  },
  currentMessage: '',
  compute: val => val
}

export const defineValidation = fields => {
  return Object.keys(fields).reduce(
    (fieldsMap, key) => ({
      ...fieldsMap,
      [key]: { ...defaultValidation, ...fields[key] }
    }),
    {}
  )
}
