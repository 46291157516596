<template>
  <div class="metanav">
    <LangSwitchLink
      v-for="lang in languageLinks"
      :key="lang"
      :lang="lang"
      :params="getParams(lang, storeObjectKey)"
      class="metanav__lang"
      :aria-label="$t('app.ariaLabel.switch')"
      >{{ lang.toUpperCase() }}</LangSwitchLink
    >

    <LangRouterLink :to="openContactPanelLink" class="metanav__contact" :aria-label="$t('app.ariaLabel.contact')">
      <Icon id="contact" class="mail" />
    </LangRouterLink>

    <div v-if="toTop.includes($route.name)" class="metanav__up" @click="scrollTop">
      Haut <Icon id="arrow" />
    </div>
  </div>
</template>

<script>
import { LangSwitchMixin } from '@/mixins'

export default {
  name: 'MetaNav',
  mixins: [LangSwitchMixin],
  data() {
    return {
      toTop: ['instant', 'maturityReport']
    }
  },
  computed: {
    languageLinks() {
      return this.$lang.list.filter(langCode => langCode !== this.$lang.current)
    },
    openContactPanelLink() {
      return {
        name: this.$route.name,
        query: { ...this.$route.query, contact: true }
      }
    },
    storeObjectKey() {
      if (this.$route.name.indexOf('studyShow') !== -1) return 'studies'
      if (this.$route.name.indexOf('businessShow') !== -1) {
        return 'businesses'
      }
      if (this.$route.name.indexOf('spaceForm') !== -1) {
        return 'businesses'
      }
      return null
    }
  },
  methods: {
    scrollTop() {
      window.scrollTo({top: 0, behavior: 'smooth'});
    }
  }
}
</script>
