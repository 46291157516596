import ConditionalLink from './ConditionalLink.vue'
import DropDown from './DropDown.vue'
import Element from './Element'
import Icon from './Icon.vue'
import RawHtml from './RawHtml.vue'

const globals = [Element, Icon, DropDown, RawHtml, ConditionalLink]

export { Element, Icon, DropDown, RawHtml, ConditionalLink }

export default Vue => globals.forEach(component => Vue.component(component.name, component))
