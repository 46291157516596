<template>
  <div class="maturity-form__content -larger">
    <p class="space-form__title">{{ page.steps.check.title }}</p>
    <p>{{ page.steps.check.subtitle }}</p>
    <div class="search-bar -center">
      <form class="search-bar__form -grey" @submit.prevent="search">
        <input v-model="str" type="text" class="search-bar__input -larger" :placeholder="$t('app.search')"/>
        <button class="search-bar__button -dark" :aria-label="$t('app.ariaLabel.search')">
          <icon id="search" />
        </button>
      </form>
    </div>

    <template v-if="index && index.length > 0 && isIndexReady">
      <div class="study-card-list -margin-top">
        <CompanyCard v-for="(company, key) in currentIndexPage" :key="'company-' + key" :page="page" :company="company"/>
      </div>
      <Pagination v-if="hasPagination" :total-item="index.length" :item-per-page="itemPerPage" :current-page="currentPage"/>
    </template>

    <template v-if="searchDone && isIndexReady">
      <h2 class="space-form__subtitle">{{ page.steps.check.notFound }}</h2>
      <InstantLink
        icon="/badges/space_3.svg"
        :title="$beet.options.ctos.createOrg.title"
        :text="$beet.options.ctos.createOrg.subtitle"
        :button-label="$beet.options.ctos.createOrg.linkLabel"
        :button-action="() => $emit('next-step')"
        class="-less-mt"
      />
    </template>

    <template v-if="!isIndexReady">
      <div class="pending -more-h">
        <img src="/badges/pending.png" alt="" class="pending__img" />
        <p>{{ $t('app.pending') }}</p>
      </div>
    </template>
  </div>
</template>

<script>
import CompanyCard from "@/components/spaceForm/CompanyCard.vue";
import indexPageMixin from "@/mixins/IndexPageMixin";
import Pagination from "@/components/nav/Pagination.vue";
import InstantLink from "@/components/partials/InstantLink.vue";
import http from "@/extensions/Http";

export default {
  name: "FormStep1",
  components: { InstantLink, Pagination, CompanyCard },
  mixins: [indexPageMixin],
  props: {
    page: Object
  },
  data () {
    return {
      str: null,
      itemPerPage: 6,
      searchLabel: null,
      searchResults: [],
      searchDone: false,
      isIndexReady: true
    }
  },
  computed: {
    index() {
      return this.searchResults;
    },
  },
  methods: {
    search() {
      if (!this.str.length) {
        this.searchLabel = null;
      } else {
        this.searchDone = true;
        this.searchLabel = this.str;
        this.isIndexReady = false
        this.getSearchResults(this.searchLabel);
      }
    },
    getSearchResults(query) {
      http
        .get(`api/search/businesses?s=${query}`)
        .then(this.mapSearchResults)
        .catch(() => (this.searchResults = []))
    },
    mapSearchResults({ data }) {
      if (!data.length) {
        this.searchResults = []
      } else {
        this.searchResults = data.map(id => {
          return this.$beet.businesses[id]
        })
      }
      this.isIndexReady = true
    }
  },

};
</script>

<style scoped>

</style>
