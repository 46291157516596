<template>
  <div class="eco-cta">
    <div class="eco-cta__content">
      <h2 class="eco-cta__title">{{ title }}</h2>
      <p class="eco-cta__subtitle">{{ subtitle }}</p>
      <a class="button -link -fullwidth-mob" :href="moduleUrl" target="_blank" rel="noopener">{{ linkLabel }}</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EcoCta',
  props: {
    title: { type: String, require: true },
    subtitle: { type: String, require: true },
    linkLabel: { type: String, require: true }
  },
  computed: {
    moduleUrl() {
      return this.$lang.current === 'fr' ? process.env.VUE_APP_MODULE_URL : `${process.env.VUE_APP_MODULE_URL}/en`
    }
  }
}
</script>
