<template>
  <div class="filter-bar__control">
    <button class="button -link -filter -relative" aria-label="Ouvrir les filtres" @click="isFilterOpen = true">
      <Icon id="filter" />
      <span>{{ $t('app.filters.filterButton') }}</span>
      <span v-if="filterCount" class="filter-bar__count">{{ filterCount }}</span>
      <Modal :is-visible="isFilterOpen" content-class="-filter-desktop" @close="isFilterOpen = false">
        <FilterBarModal
          :filters="filters"
          :scope="scope"
          :is-index-filtered="isIndexFiltered"
          :count="count"
          @close="isFilterOpen = false"
        />
      </Modal>
    </button>
    <button
      v-if="mention"
      class="filter-bar__mention"
      aria-label="Toggle mention"
      @click="isTipVisible = !isTipVisible"
    >
      <img src="/icones/bell.svg" alt="" class="filter-bar__img" /><span
        v-if="isTipVisible || $device.customlaptop"
        class="filter-bar__tip"
        >{{ mention }}</span
      >
    </button>
  </div>
</template>

<script>
import FilterBarModal from './FilterBarModal.vue'
import { Modal } from '@/components/modal'
import { Icon } from '@/components/global'

export default {
  name: 'FilterBarDesktop',
  components: { Icon, FilterBarModal, Modal },
  props: {
    scope: { type: String, require: true },
    filters: { type: Array, default: Array },
    isIndexFiltered: { type: Boolean, default: false },
    count: { type: Number, required: true },
    mention: { type: String, default: null }
  },
  data() {
    return {
      isFilterOpen: false,
      isTipVisible: false
    }
  },
  computed: {
    filterCount() {
      return Object.entries(this.$route.query)
          .filter(filter => filter[0] !== 'q' && filter[0] !== 'page')
          .map(filter => {
            return Array.isArray(filter[1]) ? filter[1].length : 1
          })
          .reduce((total, filterTypeCount) => {
            return total + filterTypeCount
          }, 0)
    }
  }
}
</script>
