export default {
    title: "Outil d'évaluation de la maturité en matière d’intelligence artificielle",
    businessReady: 'Votre entreprise est-elle prête pour l’intelligence artificielle (IA)?',
    businessReadySub: 'Évaluez votre capacité opérationnelle à adopter et intégrer l’IA et obtenez des recommandations personnalisées pour faire avancer votre entreprise.',
    duration: 'Ne prend que 5 minutes',
    why: {
        title: 'Pourquoi évaluer votre niveau de maturité?',
        description: `
            <p>L’IA est un vecteur de productivité et d’innovation puissant pour les entreprises qui détiennent des données et investissent pour les valoriser.</p>
            <p>En répondant à ce questionnaire, vous obtiendrez</p>
            <ul>
                <li>votre niveau global de maturité en matière d’IA ainsi que votre profil personnalisé selon 5 dimensions organisationnelles distinctes;</li>
                <li>des conseils personnalisés sur la manière de faire progresser votre maturité;</li>
                <li>ainsi que des recommandations de partenaires vers lesquels vous tournez pour être accompagné dans vos projets d’IA.</li>
            </ul>
        `,
        button: 'Commencer le questionnaire',
        note: 'Les données partagées sont traitées de manière confidentielles.'
    },
    how: {
        title: 'Comment fonctionne notre évaluation de la maturité en matière d’IA?',
        content: `
            <ol>
                <li><span>Répondez à 8 questions regroupées selon les 6 dimensions clés de la maturité en matière d’IA</span></li>
                <li><span>Obtenez un score global de maturité ainsi que des résultats personnalisés pour chacune des dimensions et comparez-vous aux autres entreprises </span></li>
                <li><span>En fonction de votre profil, recevez des recommandations personnalisées pour chacune des dimensions</span></li>
                <li><span>Astuce : Votre progression est ensuite archivée dans votre tableau de bord. Vous pouvez reprendre le questionnaire et suivre votre évolution dans le temps ou encore faire une pause, si vous n’avez pas toutes les réponses et revenir à l’évaluation pour reprendre là où vous en étiez</span></li>
            </ol>
        `
    },
    dimension: {
        title: 'Quelles sont les dimensions de la maturité organisationnelle évaluées?',
        subtitle: 'Ce questionnaire évalue 5 dimensions organisationnelles distinctes pour établir votre niveau de maturité en matière d’IA :',
        general: {
          formTitle: 'Organisation'
        },
        strategy: {
            formTitle: 'Stratégie',
            title: 'Stratégie',
            text: 'Quelle est la vision et le niveau d’alignement stratégique pour tirer pleinement profit de l’IA et atteindre niveau de maturité souhaité en matière d’IA?'
        },
        data: {
            formTitle: 'Données',
            title: 'Données',
            text: 'Comment votre entreprise gère-t-elle ses bonnes données et comment garantit-elle l’accès à celles-ci de manière gouvernée?'
        },
        technology: {
            formTitle: 'Technologie',
            title: 'Technologie',
            text: 'Comment votre entreprise bâtit-elle l’infrastructure technique et choisit-elle les bons outils et composantes technologiques pour entraîner, implanter et gérer ses modèles et solutions d’IA tout au long de leur cycle de vie?'
        },
        rh: {
            formTitle: 'RH',
            title: 'Ressources humaines',
            text: 'Comment votre entreprise relève-t-elle le défi du développement, de l’acquisition et de la transformation des compétences requis pour intégrer l’IA dans ses opérations et gérer le changement que cela suscite?'
        },
        govern: {
            formTitle: 'Gouvernance',
            title: 'Gouvernance',
            text: 'Quels mécanismes (politiques, processus ou composantes technologiques) votre entreprise utilise-t-elle pour encadrer, coordonner et garantir qu’elle implante des solutions sûres, robustes et responsables?'
        }
    },
    levels: {
        title: 'Quels sont les différents niveaux de maturité?',
        1: {
            title: 'Exploration',
            text: 'Exploration de ce qu’est l’IA et ce qu’elle peut apporter à l’organisation. Celle-ci ne dispose pas encore d’un modèle ou d’une solution d’IA en production'
        },
        2: {
            title: 'Expérimentation',
            text: 'Expérimentation à partir de preuve de concept et de pilotes. L’organisation tente de mettre l’IA en production et peut le faire de façon limitée.'
        },
        3: {
            title: 'Formalisation',
            text: 'Passage de projets pilotes à une ou des solutions d’IA en production. La mise en production de solutions d’AI nécessite encore un important travail d’organisation à ce stade.'
        },
        4: {
            title: 'Optimisation',
            text: 'La mise à l’échelle des solutions d’IA se fait efficacement à mesure que le nombre de modèles d’AI déployés augmente. L’organisation s’approche d’une usine de production de modèles.'
        },
        5: {
            title: 'Transformation',
            text: 'Transformation de l’organisation en elle-même par l’utilisation de l’IA. L’organisation utilise l’AI dans sa façon d’opérer dans de nombreux domaines critiques de l’entreprise.'
        }
    },
    method: {
        title: 'Quelle est la méthodologie utilisée?',
        content: `
            <p>Cet outil a été développé par le Forum IA Québec, en partenariat avec l’Institut intelligence et données (IDD) de l’Université Laval. Il consiste en une mise à jour du Cadre de maturité de l’intelligence artificielle développé en 2019-2020 par Element AI, lequel s’appuie notamment sur les résultats d’un sondage mené auprès d’environ 200 hauts dirigeants d’entreprises, pour la plupart nord-américaines. L’outil a été révisé et bonifié en s’inspirant de l’expérience et des apprentissages du Forum IA Québec et de l’IID, tirés lors d’échanges et de l’accompagnement de nombreuses entreprises dans leur intégration de l’intelligence artificielle.</p>
            <p>Pour plus de détails, vous pouvez consulter le livre blanc d’Element AI disponible à l’adresse suivante:</p>
            <p><a href="https://www.elementai.com/products/ai-maturity" target="_blank">https://www.elementai.com/products/ai-maturity</a></p>
            <p style="color: #222226; margin-top: 1.5rem;"><strong >Remerciements</strong></p>
            <p>Nous souhaitons remercier Pierre et Julien pour leur travaux, ServiceNow pour nous avoir permis d’utiliser le Cadre de maturité d’Element AI ainsi que Vincent Thomasset de Productique Québec et IVADO pour leur contribution au processus de révision et bonification de l’outil.</p>
        `
    },
    report: {
        title: 'Vos résultats',
        text: 'Voici nos conseils personnalisés en fonction de votre niveau global de maturité en matière d’IA et nos recommandations pour les cinq dimensions organisationnelles qui vous permettront de progresser.',
        email: 'Envoyer les résultats par courriel',
        level: 'Votre niveau de maturité global en matière d\'IA',
        objectif: 'L’objectif à cette étape est',
        profile: 'Votre profil personnalisé selon les 5 dimensions',
        profile_text: 'Le niveau de maturité globale de votre organisation est déterminé en fonction de votre progression dans chacune des 5 dimensions clés de la maturité a adopter l\'IA.',
        recommendation: 'Des recommandations personnalisées selon votre profil',
        recommendation_text: 'Consultez les onglets pour mieux comprendre votre score pour chacune des 5 dimensions et obtenir des recommandations concrètes pour vous améliorer',
        action: 'La vision organisationnelle et le plan d’action pour maintenir une dynamique qui tend vers la maturité en matière d’IA',
        result: 'Votre résultat pour cette dimension :',
        download: 'Consultez les recommandations de tous les types de profil afin d’établir une stratégie à long terme grâce à notre rapport complet.',
        download_link: 'Télécharger le rapport complet',
        sent: 'Vos résultats vous ont été envoyés par courriel. N’oubliez pas de vérifier vos courriels indésirables.',
        send_again: 'Envoyer à une autre adresse'
    },
    form: {
        selectRegion: 'Sélectionner une région administrative',
        selectSector: 'Sélectionner le secteur d’activité',
        selectAnswer: 'Sélectionner une réponse'
    }
}
