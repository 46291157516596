<template>
  <main v-if="$beet.isReady" id="app" :class="['layout', { '-auth': isAuth }]">
    <Transition name="page" mode="out-in" appear @after-leave="setScrollPosition" @enter="updateScrollPosition">
      <RouterView :key="$route.path" class="page layout__view"/>
    </Transition>
    <SiteFooter/>

    <SiteNavBar v-if="$device.laptop"/>
    <MobileNavBar v-else/>
    <MetaNav v-if="$device.laptop"/>
    <Modal query-key="contact" content-class="-contact">
      <Contact/>
    </Modal>
    <Modal query-key="connexion" content-class="-connexion">
      <Connexion/>
    </Modal>
    <Modal query-key="reset" content-class="-connexion">
      <Reset/>
    </Modal>
    <Modal query-key="video" content-class="-video">
      <Video/>
    </Modal>
    <Modal query-key="tuto" :is-delayed="true" content-class="-tuto">
      <Tutorial/>
    </Modal>
    <Modal query-key="regiondata" content-class="-contact">
      <Region/>
    </Modal>
    <PortalTarget name="modal" slim/>
    <CookieBar/>
  </main>
</template>

<script>
import http from '@/extensions/Http'
import {Modal, Contact, Connexion, Reset, Video, Tutorial, Region} from '@/components/modal'
import {MetaNav, SiteNavBar, SiteFooter, MobileNavBar} from '@/components/nav'

export default {
  name: 'App',
  metaInfo: {
    title: 'Vitrine - IA',
    titleTemplate: '%s | Vitrine - IA'
  },
  components: {Region, MetaNav, SiteNavBar, MobileNavBar, SiteFooter, Modal, Contact, Connexion, Reset, Video, Tutorial},
  data() {
    return {
      scrollPosition: 0,
      isMobileNavOpen: false
    }
  },
  computed: {
    isAuth() {
      return this.$store.state.user.isAuth
    }
  },
  watch: {
    $route({name, hash}, from) {
      if (name !== from.name || !hash) return
      const delay = this.$device.tablet ? 0 : 150
      return setTimeout(() => this.$scroll.toTarget(hash, 80, 350), delay)
    },
    '$lang.current': {
      immediate: true,
      handler(lang) {
        this.$beet.fetchCoreData(lang)
        this.$i18n.locale = lang
      }
    },
    '$beet.businesses': {
      immediate: true,
      handler(businesses) {
        this.$store.dispatch('updateRandomBusinesses', businesses)
      }
    },
    '$beet.studies': {
      immediate: true,
      handler(studies) {
        this.$store.dispatch('updateRandomStudies', studies)
      }
    }
  },
  created() {
    http.$init().then(() => {
      http
          .get('/api/user/profile-information')
          .then(() => {
            http.get('/api/me/role').then(({data}) => {
              if (data.role === 'BusinessOwner') this.$store.dispatch('updateIsAuth', {bool: false, role: data.role})
              else this.$store.dispatch('updateIsAuth', {bool: true, role: data.role})
            })
          })
          .catch(() => {
            this.$store.dispatch('updateIsAuth', {bool: false})
          })
    })
  },
  methods: {
    updateScrollPosition() {
      this.$nextTick(() => {
        const {hash} = this.$route
        if (hash) return setTimeout(() => this.$scroll.toTarget(hash, 80, 350), 150)
        this.$scroll.to(this.scrollPosition)
      })
    },
    setScrollPosition() {
      this.scrollPosition = window.savedScroll || 0
      this.$scroll.to(0)
    }
  }
}
</script>
