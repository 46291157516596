<template>
  <div class="business-cta">
    <div class="business-cta__content">
      <h2>{{ title }}</h2>
      <p>{{ subtitle }}</p>
      <LangRouterLink :to="{ name: 'home' }">{{ linkLabel }}</LangRouterLink>
    </div>
    <div class="business-cta__image"></div>
  </div>
</template>

<script>
export default {
  name: 'BusinessCta',
  props: {
    title: { type: String, require: true },
    subtitle: { type: String, require: true },
    linkLabel: { type: String, require: true }
  }
}
</script>
