export default {
  data() {
    return { itemPerPage: 10 }
  },
  computed: {
    currentPage() {
      return Number(this.$route.query.page) || 1
    },
    count() {
      if (!this.index) return 0
      return Object.keys(this.index).length
    },
    indexOfLastItem() {
      return this.currentPage * this.itemPerPage
    },
    indexOfFirstItem() {
      if (this.$device.mobile) return 0
      return this.indexOfLastItem - this.itemPerPage
    },
    hasPagination() {
      return this.count > this.itemPerPage
    },
    currentIndexPage() {
      if (!this.hasPagination) return this.index
      return this.index.slice(this.indexOfFirstItem, this.indexOfLastItem)
    }
  },
  watch: {
    currentPage(to, from) {
      if (to === from || this.$device.mobile) return
      this.$scroll.toTarget('#index')
    }
  },
  methods: {
    sortByDate(a, b) {
      const aDate = new Date(a.publishedAt).getTime()
      const bDate = new Date(b.publishedAt).getTime()
      return bDate - aDate
    },
    sortByName(a, b) {
      return a.name.localeCompare(b.name)
    },
    sortByTitle(a, b) {
      return a.title.localeCompare(b.title)
    },
    sortByIsCompleted(a, b) {
      if (a.completed === b.completed) {
        return 0
      } else if (a.completed) {
        return -1
      }
      return 1
    }
  }
}
