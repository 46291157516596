<template>
  <section class="section -first">
    <div class="section__header -center">
      <h2 class="section__title -bigger -center">{{ title }}</h2>
      <p class="section__text -center">{{ subtitle }}</p>
    </div>

    <div class="sector-card-list -promo">
      <SectorCard
        v-for="sector in displaySectors"
        :id="sector"
        :key="sector"
        :show-description="false"
        class="sector-card-list__item -promo"
      />
    </div>
  </section>
</template>

<script>
import _shuffle from 'lodash.shuffle'
import { SectorCard } from '@/components/cards'

export default {
  name: 'SectorPromo',
  components: { SectorCard },
  props: {
    title: { type: String, default: null },
    subtitle: { type: String, default: null },
    sectors: { type: Array, default: Array }
  },
  computed: {
    maxDisplay() {
      return this.$device.customtablet && !this.$device.customlaptop ? 2 : 3
    },
    displaySectors() {
      return _shuffle(this.sectors).slice(0, this.maxDisplay)
    }
  }
}
</script>
