<template>
  <div class="contact">
    <h2 class="contact__title">{{ content.title }}</h2>
    <LangRouterLink
        v-if="!$device.tablet"
        class="info-modal__close button -close"
        :aria-label="$t('app.ariaLabel.closeFilter')"
        :to="closePanelLink"
    >
      <Icon id="close"/>
    </LangRouterLink>
    <div class="contact__intro">
      <RawHtml :html="content.text"></RawHtml>

      <Socials/>
    </div>

    <transition name="fade" mode="out-in">
      <div v-if="isFormVisible">
        <form id="contact" class="form" @submit.prevent="validate()">
          <InputSelect
              v-model="validation.object.value"
              :options="selectOptions"
              name="object"
              :label="$t('forms.object.label')"
              :show-validation="validation.object.showValidation"
              :error="validation.object.isInvalid"
              :error-message="validation.object.currentMessage"
              @single-validate="validate"
          />

          <div class="contact__row">
            <InputText
                v-model="validation.name.value"
                class="-half"
                name="name"
                :label="$t('forms.name.label')"
                :show-validation="validation.name.showValidation"
                :error="validation.name.isInvalid"
                :error-message="validation.name.currentMessage"
                @single-validate="validate"
            />

            <InputText
                v-model="validation.organisation.value"
                class="-half"
                name="organisation"
                :show-validation="validation.organisation.showValidation"
                :label="$t('forms.organisation.label')"
                :error="validation.organisation.isInvalid"
                :error-message="validation.organisation.currentMessage"
                @single-validate="validate"
            />
          </div>

          <div class="contact__row">
            <InputText
                v-model="validation.email.value"
                class="-half"
                name="email"
                :show-validation="validation.email.showValidation"
                :label="$t('forms.email.label')"
                :error="validation.email.isInvalid"
                :error-message="validation.email.currentMessage"
                @single-validate="validate"
            />
            <InputText
                v-model="validation.phone.value"
                class="-half"
                name="phone"
                :label="$t('forms.phone.label')"
                :show-validation="validation.phone.showValidation"
                :error="validation.phone.isInvalid"
                :error-message="validation.phone.currentMessage"
                @single-validate="validate"
            />
          </div>

          <InputText
              v-model="validation.message.value"
              textarea
              name="message"
              :show-validation="validation.message.showValidation"
              :label="$t('forms.message.label')"
              :error="validation.message.isInvalid"
              :error-message="validation.message.currentMessage"
              :character-limit="500"
              @single-validate="validate"
          />
          <div class="form__buttons -contact">
            <button name="contact" class="button -dark -fullwidth-mob">{{ $t('forms.buttons.send') }}</button>
          </div>
        </form>
      </div>

      <div v-else-if="isConfirmCardVisible" class="contact__confirmation">
        <img src="/badges/confirm.svg" alt="" class="contact__confirmation-badge"/>
        <p class="contact__confirmation-title">{{ content.confirmation.title }}</p>
      </div>
    </transition>

    <LangRouterLink
        v-if="$device.tablet"
        :to="closePanelLink"
        class="contact__close"
        :aria-label="$t('app.ariaLabel.closeModal')"
    >
      <Icon id="contact_close"/>
    </LangRouterLink>
  </div>
</template>

<script>
import http from '@/extensions/Http'
import {InputSelect, InputText} from '@/components/inputs'
import {ValidationMixin} from '@/mixins'
import {Socials} from '@/components/partials'

export default {
  name: 'Contact',
  components: {InputText, InputSelect, Socials},
  mixins: [ValidationMixin],
  data() {
    return {
      isFormVisible: true,
      isConfirmCardVisible: false
    }
  },
  computed: {
    content() {
      return this.$beet.options.contactform
    },
    socials() {
      return this.$beet.options.socials
    },
    closePanelLink() {
      const {contact, object, ...query} = this.$route.query
      return {name: this.$route.name, query}
    },
    selectOptions() {
      return this.content.object.items.reduce(
          (optionsMap, option, currentIndex) => {
            return {...optionsMap, [currentIndex + 1]: option.item}
          },
          {0: this.$t('forms.object.default')}
      )
    }
  },
  created() {
    this.createValidation({
      object: {
        isRequired: true,
        regex: /^[0-9]{1,2}$/,
        messages: {
          empty: this.$t('forms.object.empty'),
          pattern: this.$t('forms.object.pattern')
        },
        value: this.$route.query.object,
        compute: val => this.selectOptions[val]
      },
      name: {
        isRequired: true,
        regex: /^[a-zA-ZÀ-ÿ\-' ]{1,50} [a-zA-ZÀ-ÿ\-' ]{1,50}$/,
        messages: {
          empty: this.$t('forms.name.empty'),
          pattern: this.$t('forms.name.pattern')
        }
      },
      organisation: {
        isRequired: true,
        regex: /^[0-9a-zA-ZÀ-ÿ\-,' ]{0,100}$/,
        messages: {
          empty: this.$t('forms.organisation.empty'),
          pattern: this.$t('forms.organisation.pattern')
        }
      },
      email: {
        isRequired: true,
        regex:
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        messages: {
          empty: this.$t('forms.email.empty'),
          pattern: this.$t('forms.email.pattern')
        }
      },
      phone: {
        regex: /^[0-9a-zA-ZÀ-ÿ\-,' ]{0,25}$/,
        messages: {
          empty: '',
          pattern: this.$t('forms.phone.pattern')
        }
      },
      message: {
        isRequired: true,
        regex: /^[a-zA-ZÀ-ÿ0-9?$@#()'!,+\-=_:.&€£*%\s]{0,500}$/,
        messages: {
          empty: this.$t('forms.message.empty'),
          pattern: this.$t('forms.message.pattern')
        }
      }
    })
  },
  methods: {
    selectObject(value) {
      this.validation.object.value = value
      this.validateSingleField('object')
    },
    submitForm() {
      const data = this.getValidationFields()

      http.$post('api/contact', data).then(() => {
        this.toggleConfirmationCard()
      })
    },
    toggleConfirmationCard() {
      this.isFormVisible = false
      this.isConfirmCardVisible = true
    }
  }
}
</script>
