<template>
  <div class="mobile-nav-bar">
    <nav class="mobile-nav-bar__main">
      <LangRouterLink :to="{ name: 'home' }">
        <img :src="`/images/VitrineIACI-logo-${$lang.current}.svg`" alt="Vitrine - IA" class="mobile-nav-bar__logo" />
      </LangRouterLink>

      <div class="mobile-nav-bar__controls">
        <LangRouterLink :to="openContactPanelLink" class="mobile-nav-bar__contact">
          <Icon id="contact" class="mail" />
        </LangRouterLink>
        <button
          :class="['mobile-nav-bar__toggle', { '-open': isMobileNavOpen }]"
          aria-label="toggle menu"
          @click="toggleNav()"
        >
          <Icon :id="menuIcon" />
        </button>
      </div>
    </nav>

    <div v-if="isAuth" class="mobile-nav-bar__is-auth">
      <p>{{ $t('app.nav.auth') }}</p>
    </div>
    <transition
      appear
      name="mobile-nav-panel-transition"
      appear-active-class="mobile-nav-panel-transition-appear"
      mode="out-in"
    >
      <MobileNavPanel v-if="isMobileNavOpen" />
    </transition>
  </div>
</template>

<script>
import MobileNavPanel from './MobileNavPanel.vue'

export default {
  name: 'MobileNavBar',
  components: { MobileNavPanel },
  data() {
    return {
      isMobileNavOpen: false
    }
  },
  computed: {
    menuIcon() {
      return this.isMobileNavOpen ? 'close' : 'burger'
    },
    isAuth() {
      return this.$store.state.user.isAuth
    },
    openContactPanelLink() {
      return {
        name: this.$route.name,
        query: { ...this.$route.query, contact: true }
      }
    }
  },
  watch: {
    isMobileNavOpen() {
      this.isMobileNavOpen ? this.$scroll.prevent() : this.$scroll.restore()
    },
    $route({ path }, from) {
      if (path !== from.path && this.isMobileNavOpen) {
        this.isMobileNavOpen = !this.isMobileNavOpen
      }
    }
  },
  methods: {
    toggleNav() {
      this.isMobileNavOpen = !this.isMobileNavOpen
    }
  }
}
</script>
