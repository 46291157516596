import './styles'
import './startup'

import Vue from 'vue'
import VueMeta from 'vue-meta'
import DeviceQueries from 'vue-device-queries'
import { sync } from 'vuex-router-sync'
import PortalVue from 'portal-vue'

import BeetAPI from '@boite-beet/api-client'
import i18n from './i18n'
import store from './store'
import router from './router'
import App from './App.vue'
import GlobalComponents from './components/global'
// import GlobalEvents from './extensions/GlobalEvents'
import ScrollUtils from './extensions/ScrollUtils'
import ScrollHub from './extensions/scrollHub'
import Cookies from './extensions/cookies'
import { toRem } from './helpers'

Vue.config.productionTip = false

Vue.use(i18n)
Vue.use(VueMeta)
Vue.use(GlobalComponents)
Vue.use(ScrollUtils, { scrollEventTarget: window })
Vue.use(ScrollHub, {
  scrollingElement: document.scrollingElement || document.documentElement,
  scrollEventTarget: window,
  reveal: { offset: window.innerHeight * 0.15 }
})
Vue.use(PortalVue)
Vue.use(Cookies)

Vue.use(BeetAPI, {
  store,
  apiUrl: `${process.env.VUE_APP_API_URL}/api`,
  mode: 'nova',
  isAxiosCredentials: true,
  initialData: {}
})

Vue.use(DeviceQueries, {
  phone: `max-width: ${toRem(567)}`,
  tablet: `min-width: ${toRem(568)}`,
  customtablet: `min-width: ${toRem(820)}`,
  mobile: `max-width: ${toRem(1024)}`,
  laptop: `min-width: ${toRem(1025)}`,
  customlaptop: `min-width: ${toRem(1250)}`,
  desktop: `min-width: ${toRem(1360)}`
})

sync(store, router)

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
