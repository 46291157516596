<template>
  <div id="top" :class="{ '-loading': !$page }">
    <template v-if="$page">
      <header class="header" :style="`background-image: url(${$page.header.image})`">
        <div v-scroll:reveal="{ delay: 450 }">
          <Icon id="niv_formalisation" class="header__badge"/>
        </div>
        <h1 v-scroll:reveal="{ delay: 550 }" class="header__title">{{ $page.header.title }}</h1>
        <p v-scroll:reveal="{ delay: 650 }" class="header__subtitle">{{ $page.header.description }}</p>
      </header>

      <nav class="button__list">
        <LangRouterLink v-for="(type, typeKey) in types" :key="typeKey" class="button -link"
                        :to="{name: $route.name, hash: '#top', query: {page: type.id.toString() === $route.query.type.toString() ? $route.query.page : '1', type: type.id}}">
          {{ type.name }}
        </LangRouterLink>
      </nav>

      <ul v-if="pageFilteredDocuments.length > 0" class="document__list">
        <DocumentCard v-for="(document, documentKey) in pageFilteredDocuments" :key="documentKey" :content="document"/>
      </ul>
      <p v-if="pageFilteredDocuments.length === 0" v-scroll:reveal class="section -first -double-pad">
        {{ $t('documents.empty') }}
      </p>

      <nav class="pagination">
        <LangRouterLink v-for="(pageNumber, pageKey) in Math.ceil(typeFilteredDocuments.length / 6)"
                        :key="pageKey"
                        :to="{name: $route.name, hash: '#top', query: {page: pageNumber.toString(), type: $route.query.type}}"
                        class="button -link -page-number">
          {{ pageNumber }}
        </LangRouterLink>
      </nav>
    </template>
  </div>
</template>

<script>
import {DocumentCard} from '@/components/cards'
import {Icon} from '@/components/global'
import {PageMetaMixin} from '@/mixins'

export default {
  name: 'DocumentIndex',
  beetPage: 'documents',
  components: {
    DocumentCard,
    Icon
  },
  mixins: [PageMetaMixin],
  data() {
    return {
      documentsPerPage: 6
    }
  },
  computed: {
    pageFilteredDocuments() {
      const startPosition = (parseInt(this.$route.query.page !== null ? this.$route.query.page : '1') - 1) * this.documentsPerPage
      return this.typeFilteredDocuments
          .slice(startPosition, startPosition + this.documentsPerPage)
    },
    orderSortedDocuments() {
      return Object.values(this.$beet.documents)
          .sort((documentA, documentB) => new Date(documentB.publishedAt) - new Date(documentA.publishedAt))
    },
    typeFilteredDocuments() {
      return this.$route.query.type && this.$route.query.type !== '0'
          ? this.orderSortedDocuments.filter(document => document.type === parseInt(this.$route.query.type))
          : this.orderSortedDocuments
    },
    types() {
      return [
        {
          id: 0,
          name: this.$t('documents.all')
        }
      ].concat(Object.values(this.$beet.documentTypes))
    }
  },
  watch: {
    '$route': {
      immediate: true,
      handler() {
        if (!this.$route.query.page) {
          this.$router.push({name: this.$route.name, hash: '#top', query: {page: '1', type: '0'}})
        }
      }
    }
  }
}
</script>