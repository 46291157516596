<template>
  <div class="search-bar-mobile">
    <form class="search-bar-mobile__form" @submit.prevent="search">
      <button class="search-bar-mobile__button" :aria-label="$t('app.ariaLabel.search')">
        <icon id="search" />
      </button>
      <input v-model="str" type="text" class="search-bar-mobile__input" :placeholder="title" />
    </form>
  </div>
</template>

<script>
export default {
  name: 'SearchBarMobile',
  props: {
    title: { type: String, default: null }
  },
  data() {
    return {
      str: this.$route.query.q || ''
    }
  },
  methods: {
    search() {
      if (!this.str.length || this.str === this.$route.query.q) return
      this.$router.push({ name: 'businessIndex', query: { q: this.str } })
    }
  }
}
</script>
