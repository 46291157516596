<template>
  <div :class="{ '-loading': !$beet.isReady || !$page }">
    <template v-if="$beet.isReady && $page">
      <header class="header" :style="backgroundImage">
        <div v-scroll:reveal="{ delay: 450 }">
          <Icon id="instant" class="header__badge"/>
        </div>
        <h1 v-scroll:reveal="{ delay: 550 }" class="header__title -max-width">{{ $t('maturity.title') }}</h1>
      </header>

      <section class="maturity-form__section -rapport" style="padding-top: 2.5rem">
        <div class="maturity-report">
          <div class="maturity-report__head">
            <h3 class="maturity-form__content-title">{{ $page.email.title }}</h3>
            <p>{{ $page.email.description }}</p>
            <div class="maturity-report__head-print">
              <button class="button -dark" @click="print()">
                <span class="row align-center">
                  <Icon id="print" class="icon maturity__tag-icon"/>
                  {{ $t('app.ariaLabel.print') }}
                </span>
              </button>
            </div>
          </div>

          <div class="maturity-report__content">

            <section class="maturity-report__section -complete">
              <h3 class="maturity-form__content-title">{{ $page.level.title }}</h3>

              <div class="maturity__tag -big"
                   :style="{backgroundColor: levels[general].backgroundColor, color: '#fff'}">
                <Icon :id="levels[general].icon" class="maturity__tag-icon"/>
                {{ `${general}. ${levels[general].title}` }}
              </div>

              <div class="maturity-report__objectif">
              <span class="maturity-report__objectif-blue">
                {{ $page.level.objective }} <Icon id="carret_right"/>
              </span>
                {{ levels[general].objectif.title }}
              </div>

              <p>
                {{ levels[general].objectif.text }}
              </p>
            </section>


            <section class="maturity-report__section -print-mtop">
              <h3 class="maturity-report__section-title">{{ $page.level.profile.title }}</h3>
              <p> {{ $page.level.profile.description }} </p>

              <MaturityGraph :dataset="chartData" v-if="ready"/>
            </section>

            <section class="maturity-report__section ">
              <div class="maturity-report__tablet-print">
                <h3 class="maturity-report__section-title">{{ $page.level.recommendations.title }}</h3>
                <p> {{ $page.level.recommendations.description }} </p>
              </div>

              <div class="maturity-report__tab -complete" v-for="(item, key) in dimensions" :key="key">
                <div class="maturity-report__tabs-tab -complete"
                     :style="{backgroundColor: item.color, color: '#fff'}">
                  <Icon :id="item.icon"/>
                  {{ item.title }}
                </div>
                <div class="maturity-report__tab-head -complete" :style="`backgroundColor: ${item.backgroundColor}`">
                  <span class="maturity-report__tab-icon" :style="`color: ${item.color}`" >
                    <Icon id="carret_right"/>
                  </span>
                  {{ $t('maturity.report.action') }}
                </div>

                <div class="maturity-report__tab-content">
                  <div class="maturity-report__tab-title">
                    {{ $page.level.recommendations.result }}
                  </div>

                  <div class="maturity__tag" :style="{backgroundColor: levels[item.level].backgroundColor, color: '#fff'}">
                    <Icon :id="levels[item.level].icon" class="maturity__tag-icon"/>
                    {{ $beet.maturityDimensions[item.id].levels[Number(item.level) - 1].title }}
                  </div>
                  <br/>
                  <div class="raw-html"
                       :style="`--bullet-color: ${item.color}`"
                       v-html="item.levels[item.level]"/>

                </div>
              </div>
            </section>
          </div>
        </div>
      </section>
    </template>
  </div>
</template>

<script>
import MaturityGraph from "@/components/partials/MaturityGraph.vue";
import {PageMetaMixin} from "@/mixins";
import axiosInstance from "@/extensions/Http";

export default {
  name: "MaturityReport",
  mixins: [PageMetaMixin],
  components: {MaturityGraph},
  beetPage: 'maturity-results',
  data() {
    return {
      ready: false,
      tab: 'strategy',
      sent: false,
      email: '',
      general: 2,
      levels: {
        1: {
          title: this.$t('maturity.levels.1.title'),
          backgroundColor: '#C6C9FD',
          icon: 'niv_exploration',
          objectif: {}
        },
        2: {
          title: this.$t('maturity.levels.2.title'),
          backgroundColor: '#B3B6FD',
          icon: 'niv_experimentation',
          objectif: {}
        },
        3: {
          title: this.$t('maturity.levels.3.title'),
          backgroundColor: '#9A9EFC',
          icon: 'niv_formalisation',
          objectif: {}
        },
        4: {
          title: this.$t('maturity.levels.4.title'),
          backgroundColor: '#7F85FB',
          icon: 'niv_optimisation',
          objectif: {}
        },
        5: {
          title: this.$t('maturity.levels.5.title'),
          backgroundColor: '#646BFB',
          icon: 'niv_transformation',
          objectif: {}
        }
      },
      dimensions: {
        strategy: {
          id: 1,
          title: this.$t('maturity.dimension.strategy.title'),
          color: '#FE9F41',
          backgroundColor: '#FFF5EB',
          icon: 'dim_strategie',
          level: 1,
          levels: this.$beet.maturityDimensions[1].levels.reduce(this.levelsReducer, {}),
        },
        data: {
          id: 2,
          title: this.$t('maturity.dimension.data.title'),
          color: '#5F96FF',
          backgroundColor: '#EEF4FF',
          icon: 'dim_donnees',
          level: 1,
          levels: this.$beet.maturityDimensions[2].levels.reduce(this.levelsReducer, {}),
        },
        technology: {
          id: 3,
          title: this.$t('maturity.dimension.technology.title'),
          color: '#009ECE',
          backgroundColor: '#EBF7FB',
          icon: 'dim_tech',
          level: 1,
          levels: this.$beet.maturityDimensions[3].levels.reduce(this.levelsReducer, {}),
        },
        rh: {
          id: 4,
          title: this.$t('maturity.dimension.rh.title'),
          color: '#BA026F',
          backgroundColor: '#F9F0F5',
          icon: 'dim_rh',
          level: 1,
          levels: this.$beet.maturityDimensions[4].levels.reduce(this.levelsReducer, {}),
        },
        govern: {
          id: 5,
          title: this.$t('maturity.dimension.govern.title'),
          color: '#EE2D64',
          backgroundColor: '#FEEDF2',
          icon: 'dim_gouvernance',
          level: 1,
          levels: this.$beet.maturityDimensions[5].levels.reduce(this.levelsReducer, {}),
        }
      },
    }
  },
  beforeMount() {
    if(this.$route.query.d1 && this.$route.query.g) {
      this.general = this.$route.query.g
      Object.keys(this.dimensions).forEach((key, index) => {
        this.dimensions[key].level = this.$route.query[`d${index + 1}`]
      })
      this.ready = true
    } else {
      axiosInstance.$get('/api/maturity/levels ').then(response => {
        this.general = response.data.global
        Object.keys(this.dimensions).forEach((key, index) => {
          this.dimensions[key].level = response.data.dimensions[index + 1]
        })
        this.ready = true
      }).catch(() => {
        this.$router.replace(this.$i18n.locale === 'fr' ? '/maturite' : '/en/maturity')
      })
    }
  },
  watch: {
    '$page': {
      handler(to) {
        if(to) {
         Object.values(this.levels).forEach((level, key) => {
           level.objectif = {
             title: this.$page.level[`l${key + 1}`].objective,
             text: this.$page.level[`l${key + 1}`].description
           }
         })
        }
      },
      immediate: true
    }
  },
  computed: {
    actorLinks() {
      const ids = [1,2,3,4,5,7]
      const links = []
      ids.forEach(id => {
        links.push({
          icon: `act_${id}`,
          label: this.$beet.actorTypes[id].name,
          route: {name: 'businessIndex', query: {act: id}}
        })
      })
      return links
    },
    backgroundImage() {
      return this.$device.tablet
          ? `background-image: url('/images/banner_maturity.png')`
          : `background-image: url('/images/banner_maturity_m.png')`
    },
    chartData() {
      return {
        labels: [
          this.$t('maturity.dimension.strategy.formTitle'),
          this.$t('maturity.dimension.data.formTitle'),
          this.$t('maturity.dimension.technology.formTitle'),
          this.$t('maturity.dimension.rh.formTitle'),
          this.$t('maturity.dimension.govern.formTitle')
        ],
        datasets: [{
          data: Object.values(this.dimensions).map(({level}) => level),
          backgroundColor: 'rgba(84,92,255, 0.2)',
          borderColor: '#545cff',
          borderWidth: 1
        }]
      }
    }
  },
  methods: {
    print() {
      window.print()
    },
    levelsReducer(acc, level, index) {
      const obj = {}
      obj[index + 1] = level.text
      return {...acc, ...obj}
    },
    sendEmail() {
      this.sent = true
      this.email = ''
    }
  }
}
</script>
