<template>
  <div :class="{ '-loading': !$beet.isReady || !$page }">
    <template v-if="$beet.isReady && $page">
      <header class="header" :style="backgroundImage">
        <div v-scroll:reveal="{ delay: 450 }">
          <Icon id="instant" class="header__badge"/>
        </div>
        <h1 v-scroll:reveal="{ delay: 550 }" class="header__title -max-width">{{ $t('maturity.title') }}</h1>
      </header>

      <section class="section -double-pad-laptop">
        <div class="row -mobile -column -align-center text-center">
          <h2 class="maturity__title">«{{ $page.intro.title }}»</h2>

          <p class="maturity__subtitle">{{ $page.intro.description }}</p>

          <div class="maturity__tag">
            <Icon id="time" class="maturity__tag-icon"/>
            {{ $page.intro.duration }}
          </div>
        </div>

        <div class="maturity-card">
          <div class="maturity-card__img" :style="{backgroundImage: `url(${$page.why.image})`}"/>

          <div class="maturity-card__content">
            <h3 class="maturity__title">{{ $page.why.title }}</h3>
            <div class="raw-html" v-html="$page.why.description"/>

            <button class="button -dark" @click="goToForm"> {{ $page.why.buttonLabel }}</button>

            <div class="maturity-card__content-small">
              <small> {{ $page.why.note }} </small>
            </div>
          </div>
        </div>

        <div>
          <div class="toggle-list__header" tabindex="0" role="button" @click="whoOpen = !whoOpen">
            <h2 class="toggle-list__title">
              {{ $page.who.title }}
            </h2>
            <button class="toggle-list__button">
              <Icon :id="whoOpen ? 'less' : 'more'"/>
            </button>
          </div>

          <DropDown :is-open="whoOpen">
            <div class="toggle-list__dropdown">
              <div class="maturity-list" v-html="$page.who.content"/>
            </div>
          </DropDown>
        </div>

        <div>
          <div class="toggle-list__header" tabindex="0" role="button" @click="whatOpen = !whatOpen">
            <h2 class="toggle-list__title">
              {{ $page.what.title }}
            </h2>
            <button class="toggle-list__button">
              <Icon :id="whatOpen ? 'less' : 'more'"/>
            </button>
          </div>

          <DropDown :is-open="whatOpen">
            <div class="toggle-list__dropdown">
              <div class="maturity-list" v-html="$page.what.content"/>
            </div>
          </DropDown>
        </div>

        <div>
          <div class="toggle-list__header" tabindex="0" role="button" @click="dimensionOpen = !dimensionOpen">
            <h2 class="toggle-list__title">
              {{ $page.dimensions.title }}
            </h2>
            <button class="toggle-list__button">
              <Icon :id="dimensionOpen ? 'less' : 'more'"/>
            </button>
          </div>

          <DropDown :is-open="dimensionOpen">
            <div class="toggle-list__dropdown">
              <p>{{ $page.dimensions.subtitle }}</p>
              <div class="row -wrap">
                <div v-for="(item, key) in dimensions" :key="key" class="row__item -span-6">
                  <div style="padding-right: 1rem; margin-top: 2rem">
                    <div class="maturity__tag -mb" :style="{backgroundColor: item.backgroundColor, color: item.color}">
                      <Icon :id="item.icon" class="maturity__tag-icon"/>
                      {{ item.title }}
                    </div>

                    <div>
                      {{ item.text }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </DropDown>
        </div>

        <div>
          <div class="toggle-list__header" tabindex="0" role="button" @click="levelsOpen = !levelsOpen">
            <h2 class="toggle-list__title">
              {{ $page.levels.title }}
            </h2>
            <button class="toggle-list__button">
              <Icon :id="levelsOpen ? 'less' : 'more'"/>
            </button>
          </div>

          <DropDown :is-open="levelsOpen">
            <div class="toggle-list__dropdown">
              <div v-for="(item, key) in levels" :key="key">
                <div class="maturity__tag -mb" :style="{backgroundColor: item.backgroundColor, color: '#fff'}">
                  <Icon :id="item.icon" class="maturity__tag-icon"/>
                  {{ `${key}. ${item.title}` }}
                </div>

                <div>
                  {{ item.text }}
                </div>
                <br/>
              </div>
            </div>
          </DropDown>
        </div>

        <div>
          <div class="toggle-list__header" tabindex="0" role="button" @click="methodOpen = !methodOpen">
            <h2 class="toggle-list__title">
              {{ $page.method.title }}
            </h2>
            <button class="toggle-list__button">
              <Icon :id="methodOpen ? 'less' : 'more'"/>
            </button>
          </div>

          <DropDown :is-open="methodOpen">
            <div class="toggle-list__dropdown">
              <div class="raw-html" v-html="$page.method.content"/>
            </div>
          </DropDown>
        </div>
      </section>
    </template>
  </div>
</template>

<script>
import { PageMetaMixin } from '../mixins'

export default {
  name: "Maturity",
  beetPage: 'maturity-intro',
  mixins: [PageMetaMixin],
  data() {
    return {
      whoOpen: false,
      whatOpen: false,
      dimensionOpen: false,
      levelsOpen: false,
      methodOpen: false,
    }
  },
  computed: {
    backgroundImage() {
      return this.$device.tablet
          ? `background-image: url('/images/banner_maturity.png')`
          : `background-image: url('/images/banner_maturity_m.png')`
    },
    dimensions() {
      return {
        strategy: {
          title: this.$t('maturity.dimension.strategy.title'),
          text: this.$page.dimensions.dimensions[0].text,
          color: '#FE9F41',
          backgroundColor: '#FFF5EB',
          icon: 'dim_strategie'
        },
        data: {
          title: this.$t('maturity.dimension.data.title'),
          text: this.$page.dimensions.dimensions[1].text,
          color: '#5F96FF',
          backgroundColor: '#EEF4FF',
          icon: 'dim_donnees'
        },
        technology: {
          title: this.$t('maturity.dimension.technology.title'),
          text: this.$page.dimensions.dimensions[2].text,
          color: '#009ECE',
          backgroundColor: '#EBF7FB',
          icon: 'dim_tech'
        },
        rh: {
          title: this.$t('maturity.dimension.rh.title'),
          text: this.$page.dimensions.dimensions[3].text,
          color: '#BA026F',
          backgroundColor: '#F9F0F5',
          icon: 'dim_rh'
        },
        govern: {
          title: this.$t('maturity.dimension.govern.title'),
          text: this.$page.dimensions.dimensions[4].text,
          color: '#EE2D64',
          backgroundColor: '#FEEDF2',
          icon: 'dim_gouvernance'
        }
      }
    },
    levels() {
      return {
        1: {
          title: this.$t('maturity.levels.1.title'),
          text: this.$page.levels.levels[0].text,
          backgroundColor: '#C6C9FD',
          icon: 'niv_exploration',
        },
        2: {
          title: this.$t('maturity.levels.2.title'),
          text: this.$page.levels.levels[1].text,
          backgroundColor: '#B3B6FD',
          icon: 'niv_experimentation',
        },
        3: {
          title: this.$t('maturity.levels.3.title'),
          text: this.$page.levels.levels[2].text,
          backgroundColor: '#9A9EFC',
          icon: 'niv_formalisation',
        },
        4: {
          title: this.$t('maturity.levels.4.title'),
          text: this.$page.levels.levels[3].text,
          backgroundColor: '#7F85FB',
          icon: 'niv_optimisation',
        },
        5: {
          title: this.$t('maturity.levels.5.title'),
          text: this.$page.levels.levels[4].text,
          backgroundColor: '#646BFB',
          icon: 'niv_transformation',
        }
      }

    }
  },
  methods: {
    goToForm() {
      this.$router.push(this.$i18n.locale === 'fr' ? 'maturite/formulaire' : 'maturity/form')
    }
  }
}
</script>


