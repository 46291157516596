<template>
  <div :class="{'-loading': !$beet.isReady}">
    <template v-if="$beet.isReady && $page">
      <header class="header -padding" :style="backgroundImage">
        <div v-scroll:reveal="{ delay: 450 }">
          <Icon id="space" class="header__badge"/>
        </div>
        <h1 v-scroll:reveal="{ delay: 550 }" class="header__title">{{ $page.header.title }}</h1>
        <h2 v-scroll:reveal="{ delay: 650 }" class="header__subtitle">{{ $page.header.subtitle }}</h2>
      </header>

      <section class="maturity-form__section">
        <lang-router-link :to="{ name: 'vitrineSpace' }" class="link maturity-form__back">
          <Icon id="arrow"/>
          {{ $t('app.back') }}
        </lang-router-link>

        <div v-if="currentTab === -1" class="maturity-form">
          <div class="maturity-form__head -center">
            <div class="space-form__finish-icon">
              <icon id="checkmark"/>
            </div>
            <template v-if="currentCompany">
              <p class="maturity-form__head-title">{{ $page.confirmation.access.title }}</p>
              <p class="maturity-form__head-company">{{ $page.confirmation.access.subtitle }}</p>
            </template>
            <template v-else>
              <p class="maturity-form__head-title">{{ $page.confirmation.inscription.title }}</p>
              <p class="maturity-form__head-company">{{ $page.confirmation.inscription.subtitle }}</p>
            </template>
          </div>
        </div>

        <div v-else-if="!currentCompany" class="maturity-form">
          <div class="maturity-form__head">
            <h2 class="space-form__header">{{ $page.forms.title }}</h2>
            <div id="steps" class="maturity-form__head-row -smaller">
              <div v-for="(item, key) in tabs" :id="`step-${key}`" :key="key" class="maturity-form__step">
                <div
                  class="maturity-form__step-tag"
                  :style="{backgroundColor: currentTab >= key ? '#545CFF' : '', color: currentTab >= key ? 'white' : ''}"
                >
                  {{ parseInt(key) + 1 }}
                </div>
                <div
                  class="maturity-form__step-label"
                  :style="{color: currentTab >= key ? '#545CFF' : ''}"
                >
                  {{item.title}}
                </div>
              </div>
            </div>
          </div>
          <FormStep1 v-if="currentTab === 0" :page="$page" @next-step="currentTab = 1"/>
          <FormStep2 v-if="currentTab === 1" :page="$page" :common-errors="errors" :is-last-step="false" :common-data="formData" :other-data="commonOtherData" @previous="previousPage" @next-step="nextPage" @remove-error="removeError"/>
          <FormStep3 v-if="currentTab === 2" :page="$page" :common-errors="errors" :common-data="formData" :other-data="commonOtherData" @previous="previousPage" @next-step="submitNew" @remove-error="removeBusinessError"/>
        </div>

        <div v-else class="maturity-form">
          <div class="maturity-form__head -center">
            <p class="maturity-form__head-title">{{ $page.steps.check.btnAccess }}</p>
            <Icon id="down" class="maturity-form__head-icon"/>
            <p class="maturity-form__head-company">{{ currentCompany.name }}</p>
            <p class="maturity-form__head-place">
              <Icon id="region"/>
              <span>{{ `${this.$beet.regions[currentCompany.region].code} - ${this.$beet.regions[currentCompany.region].name}` }}</span>
            </p>
          </div>
          <FormStep2 :is-last-step="true" :page="$page" :common-errors="errors" :common-data="formData" :other-data="commonOtherData" @next-step="submitExisting" @previous="$router.push({ name: 'spaceForm' })" @remove-error="removeError"/>
        </div>
      </section>
    </template>
  </div>
</template>

<script>
import FormStep1 from "@/components/spaceForm/FormStep1.vue";
import FormStep2 from "@/components/spaceForm/FormStep2.vue";
import FormStep3 from "@/components/spaceForm/FormStep3.vue";
import http from "@/extensions/Http";
import { PageMetaMixin } from "@/mixins";

export default {
  name: "SpaceForm",
  beetPage: "mavitrine",
  components: { FormStep3, FormStep2, FormStep1 },
  mixins: [PageMetaMixin],
  data () {
    return {
      currentTab: 0,
      formData: {},
      commonOtherData: {},
      errors: {}
    }
  },
  computed: {
    currentCompany() {
      return this.$route.params.businessSlug ? Object.values(this.$beet.businesses).find(business => business.slug === this.$route.params.businessSlug) : null;
    },
    backgroundImage() {
      return this.$device.tablet
        ? `background-image: url('/images/banner_vitrineSpace.png')`
        : `background-image: url('/images/banner_vitrineSpace_m.png')`
    },
    tabs() {
      return {
        0: {
          title: this.$page.steps.check.label
        },
        1: {
          title: this.$page.steps.user.label
        },
        2: {
          title: this.$page.steps.access.label
        },
      };
    }
  },
  watch: {
    currentTab() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  },
  methods: {
    removeError(input) {
      if (this.errors[input]) {
        delete this.errors[input];
      }
    },
    removeBusinessError(input) {
      if (this.errors.business && this.errors.business[input]) {
        delete this.errors.business[input];
      }
    },
    previousPage(content) {
      this.formData = {...this.formData, ...content.commonData};
      this.commonOtherData = {...this.commonOtherData, ...content.otherData};
      this.currentTab = this.currentTab - 1;
    },
    nextPage(content) {
      this.formData = {...this.formData, ...content.commonData};
      this.commonOtherData = {...this.commonOtherData, ...content.otherData};
      this.currentTab = this.currentTab + 1;
    },
    submitExisting(content) {
      http
        .$post('/api/access/link', {
          ...content.commonData,
          business_id: this.currentCompany.id
        })
        .then(() => {
          this.currentTab = -1
        })
        .catch((error) => {
          this.currentTab = 1;
          if (error.response && error.response.data && error.response.data.errors) {
            this.errors = error.response.data.errors;
          } else {
            this.errors = { global: true };
          }
        })
    },
    submitNew(content) {
      this.formData = {...this.formData, ...content.commonData};
      http
        .$post('/api/access/create', this.formData)
        .then(() => {
          this.currentTab = -1
        })
        .catch((error) => {
          this.currentTab = 1;
          if (error.response && error.response.data && error.response.data.errors) {
            this.errors = error.response.data.errors;
          } else {
            this.errors.global = true;
          }
        })
    }
  }
};
</script>

<style scoped>

</style>