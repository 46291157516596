<template>
  <li v-scroll:reveal class="document">
    <Transition name="fade-fast">
      <img v-if="$device.customtablet" :src="content.preview" alt="" class="document__image">
    </Transition>
    <div class="document__content">
      <div>
        <h2 class="sector-card__title">{{ content.title }}</h2>
        <LangRouterLink :to="{name: $route.name, hash: '#top', query: {page: '1', type: content.type}}"
                        class="document__type">
          <Icon id="document"/>
          <span>{{ $beet.documentTypes[content.type].name }}</span>
        </LangRouterLink>
        <DropDown :is-open="displayFullDescription" :min-height="48" class="--document">
          <p class="document__description">{{ description }}</p>
        </DropDown>
      </div>
      <div class="document__links">
        <button v-if="hasLargeDescription" class="document__more" @click="toggleFullDescription">
          {{ $t(`documents.${displayFullDescription ? 'minus' : 'plus'}`) }}
        </button>
        <a :href="content.file || ''" target="_blank" download class="document__button">
          <span>{{ $t('documents.view') }}</span>
          <Icon id="arrow"/>
        </a>
      </div>
    </div>
  </li>
</template>

<script>
import {DropDown, Icon} from '@/components/global'

export default {
  name: 'DocumentCard',
  components: {DropDown, Icon},
  props: {
    content: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      canDescriptionToggle: true,
      displayFullDescription: false
    }
  },
  computed: {
    description() {
      if (!this.hasLargeDescription) {
        return this.content.description
      }
      return this.displayFullDescription || !this.canDescriptionToggle
          ? this.content.description
          : this.shortDescription
    },
    hasLargeDescription() {
      return this.content.description.length > 175
    },
    shortDescription() {
      return `${this.content.description.substr(0, 175)}...`
    }
  },
  methods: {
    toggleFullDescription() {
      this.displayFullDescription = !this.displayFullDescription
      if (!this.displayFullDescription) {
        this.canDescriptionToggle = false
        setTimeout(() => {
          this.canDescriptionToggle = true
        }, 200)
      }
    }
  }
}
</script>