<template>
  <nav v-if="$device.tablet" class="pagination">
    <LangRouterLink
      v-if="isPreviousVisible"
      class="button -link -page-number -previous"
      :to="setCurrentPage(currentPage - 1)"
      :aria-label="$t('app.ariaLabel.previousPage')"
      ><Icon id="arrow-drop"
    /></LangRouterLink>

    <LangRouterLink
      v-for="number in visiblePageNumber"
      :key="number"
      :class="['button -link -page-number', { '-active-page': isActivePage(number) }]"
      :to="setCurrentPage(number)"
      >{{ number }}</LangRouterLink
    >

    <ConditionalLink
      :is-active="isNextActive"
      class="button -link -page-number -next"
      :to="setCurrentPage(currentPage + 1)"
      :aria-label="$t('app.ariaLabel.nextPage')"
      ><Icon id="arrow-drop"
    /></ConditionalLink>
  </nav>

  <div v-else class="pagination">
    <LangRouterLink v-if="isNextActive" class="button -link -dark -fullwidth" :to="setCurrentPage(currentPage + 1)">
      {{ $t('app.filters.more') }}
    </LangRouterLink>
  </div>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    itemPerPage: { type: Number, require: true },
    totalItem: { type: Number, require: true },
    currentPage: { type: Number, default: 1 }
  },
  data() {
    return { maxButtonDisplayed: 6 }
  },
  computed: {
    pageNumbers() {
      const pageNumbers = []
      for (let i = 1; i <= Math.ceil(this.totalItem / this.itemPerPage); i++) {
        pageNumbers.push(i)
      }
      return pageNumbers
    },
    visiblePageNumber() {
      if (this.pageNumbers.length <= this.maxButtonDisplayed) return this.pageNumbers
      if (this.currentPage <= 4) return this.pageNumbers.slice(0, this.maxButtonDisplayed)

      const diff = this.pageNumbers.length - this.currentPage
      if (diff < 3) {
        return this.pageNumbers.slice(-this.maxButtonDisplayed)
      }
      const firstIndex = this.currentPage - 4
      const lastIndex = this.currentPage + 2
      return this.pageNumbers.slice(firstIndex, lastIndex)
    },
    isNextActive() {
      return this.currentPage !== this.pageNumbers.length
    },
    isPreviousVisible() {
      return this.currentPage !== 1
    }
  },
  methods: {
    isActivePage(value) {
      return this.currentPage === value
    },
    setCurrentPage(pageNumber) {
      const { page, ...currentQuery } = this.$route.query
      const query = pageNumber === 1 ? currentQuery : { ...currentQuery, page: pageNumber }
      return this.$device.tablet ? { ...this.$route, query, hash: '#top' } : { ...this.$route, query }
    }
  }
}
</script>
