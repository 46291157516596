<template>
  <div class="business-side-info">
    <div v-if="actor" class="business-side-info__actor">
      <template v-if="business.isStartup">
        <Icon id="startup" />
        <p>{{ $t('app.object.startup') }}</p>
      </template>

      <template v-else>
        <Icon :id="getActorIcon(business.actorType)" />
        <p>{{ actor.name }}</p>
      </template>
    </div>
    <div v-if="applicationSectors.length" class="business-side-info__list -sector">
      <LangRouterLink
        v-for="sector in applicationSectors"
        :key="sector.id"
        :to="{ name: 'businessIndex', query: { sect: String(sector.id) } }"
        class="tag -icon"
        :title="sector.name"
        ><Icon :id="getSectorIcon(sector.id)" /><span class="tag__tip">{{ sector.name }}</span></LangRouterLink
      >
    </div>
    <div v-if="serviceTypes.length" class="business-side-info__list">
      <LangRouterLink
        v-for="type in serviceTypes"
        :key="type.id"
        :to="{ name: 'businessIndex', query: { type: String(type.id) } }"
        class="tag"
        >{{ type.name }}</LangRouterLink
      >
    </div>

    <div class="business-side-info__perk">
      <h2 class="business-side-info__subtitle">{{ labels.income }}</h2>
      <p v-if="business.income">{{ $t(`options.income.${business.income}`) }}</p>
    </div>
    <div class="business-side-info__perk">
      <h2 class="business-side-info__subtitle">{{ labels.size }}</h2>
      <p v-if="business.size">{{ $t(`options.size.${business.size}`) }}</p>
    </div>
    <div class="business-side-info__perk">
      <h2 class="business-side-info__subtitle">{{ labels.aiEmployee }}</h2>
      <p v-if="business.aiEmployee">{{ business.aiEmployee }} {{ employeeLabel }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BusinessSideInfo',
  props: {
    labels: { type: Object, require: true },
    business: { type: Object, require: true }
  },
  computed: {
    applicationSectors() {
      const { sectors = [] } = this.$beet || {}
      return this.business.applicationSectors.map(id => sectors[id])
    },
    serviceTypes() {
      const { categories = [] } = this.$beet || {}
      return this.business.categories.map(id => categories[id])
    },
    actor() {
      return this.business.actorType ? this.$beet.actorTypes[this.business.actorType] : null
    },
    employeeLabel() {
      return this.business.aiEmployee > 1 ? this.$t('options.size.employees') : this.$t('options.size.employee')
    }
  },
  methods: {
    getActorIcon(id) {
      return `act_${id}`
    },
    getSectorIcon(id) {
      return `sect_${id}`
    }
  }
}
</script>
