<template>
  <div :class="['study-card', { '-hover': hover }]">
    <div>
      <div @mouseenter="hover = true" @mouseleave="hover = false">
        <LangRouterLink :to="{ name: 'studyShow', params: { studySlug: study.slug } }" class="study-card__title"
          @focus.native="hover = true" @blur.native="hover = false">{{ getTitle(study.title) }}</LangRouterLink>
      </div>
      <p class="study-card__subtitle">{{ $t('app.by') }} {{ study.companyName }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StudyCard',
  props: {
    studyId: { type: Number, required: true }
  },
  data() {
    return {
      hover: false
    }
  },
  computed: {
    study() {
      return this.$beet.studies[this.studyId]
    }
  },
  methods: {
    getTitle(title) {
      return title.length < 80 ? title : `${title.substring(0, 70)} ...`
    }
  }
}
</script>
