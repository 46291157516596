<template>
  <form @submit.prevent="checkForm" class="maturity-form__content -larger" v-if="isReady && !isLoading">
    <div class="space-form__content">
      <h2 class="space-form__label -mt">{{ page.infos.name }}</h2>
      <p class="space-form__text">{{ page.infos.nameHelp }}</p>
      <InputText
        id="organisation"
        name="organisation"
        :value="formData.business.name"
        :show-validation="true"
        class="space-form__input -larger -mt"
        :error="!!errors.name"
        :error-message="errors.name || ''"
        @input="value => {formData.business.name = value; delete errors.name; $emit('remove-error', 'name')}"
      />
      <h2 class="space-form__label">{{ page.infos.parent }}</h2>
      <p class="space-form__text">{{ page.infos.parentHelp }}</p>
      <InputText
        id="parent"
        name="parent"
        :value="formData.business.parent"
        :show-validation="true"
        class="space-form__input -larger -mt"
        :error="!!errors.parent"
        :error-message="errors.parent || ''"
        @input="value => {formData.business.parent = value; delete errors.parent; $emit('remove-error', 'parent')}"
      />
      <h2 class="space-form__label">{{ page.infos.website }}</h2>
      <InputText
        id="website"
        name="website"
        :value="formData.business.website"
        :show-validation="true"
        class="space-form__input -larger -mt"
        :error="!!errors.website"
        :error-message="errors.website || ''"
        @input="value => {formData.business.website = value; delete errors.website; $emit('remove-error', 'website')}"
      />
      <h2 class="space-form__label">{{ page.infos.email }}</h2>
      <p class="space-form__text">{{ page.infos.emailHelp }}</p>
      <InputText
        id="organization_email"
        name="organization_email"
        :value="formData.business.email"
        :show-validation="true"
        class="space-form__input -larger -mt"
        :error="!!errors.email"
        :error-message="errors.email || ''"
        @input="value => {formData.business.email = value; delete errors.email; $emit('remove-error', 'email')}"
      />
      <h2 class="space-form__label">{{ page.infos.neq }}</h2>
      <p class="space-form__text">{{ page.infos.neqHelp }}</p>
      <InputText
        id="neq"
        name="neq"
        :is-number="true"
        :value="formData.business.neq"
        :show-validation="true"
        class="space-form__input -larger -mt"
        :error="!!errors.neq"
        :error-message="errors.neq || ''"
        @input="value => {formData.business.neq = value; delete errors.neq; $emit('remove-error', 'neq')}"
      />
      <h2 class="space-form__label">{{ page.infos.sector }}</h2>
      <p class="space-form__text">{{ page.infos.sectorHelp }}</p>
      <InputSelect
        name="activity"
        :value="formData.business.sector_id"
        :show-validation="true"
        :options="activityOptions"
        class="space-form__input -larger -mt"
        :error="!!errors.sector_id"
        :error-message="errors.sector_id || ''"
        @input="value => {formData.business.sector_id = value; delete errors.sector_id; $emit('remove-error', 'sector_id')}"
      />
      <button type="button" :class="['filter-bar-modal__link -left', { '-active-filter': is_quebec, '-error': errors.is_quebec }]" @click="() => { is_quebec = !is_quebec; delete errors.is_quebec}"><span>{{ page.infos.confirmation }}</span></button>
    </div>
    <p v-if="errors.is_quebec" class="input__error -left">
      <Icon id="warning" class="input__warning" />{{ errors.is_quebec }}
    </p>
    <div class="space-form__flex -buttons">
      <button type="button" class="button -icon" @click="$emit('previous', formData)"><icon id="arrow" class="space-form__rotate"/><span>{{ $t('app.previous') }}</span></button>
      <button class="button -dark -icon" :class="{ '-disabled': isDisabled }" type="submit"><span>{{ $t('app.submit') }}</span></button>
    </div>
    <p class="space-form__conditions">{{ page.conditions.preLink }} <lang-router-link :to="{ name: 'terms' }" target="_blank" class="space-form__link">{{ page.conditions.link }}</lang-router-link> {{ page.conditions.postLink }}</p>
  </form>
</template>

<script>
import InputText from "@/components/inputs/InputText.vue";
import InputSelect from "@/components/inputs/InputSelect.vue";

const URL_REGEX = /[-a-zA-Z0-9@:%._\\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)/;
const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export default {
  name: "FormStep3",
  components: { InputSelect, InputText },
  props: {
    page: Object,
    commonData: Object,
    otherData: Object,
    commonErrors: Object
  },
  data() {
    return {
      formData: {
        business: {
          name: "",
          parent: "",
          website: "",
          email: "",
          neq: "",
          sector_id: null
        }
      },
      is_quebec: false,
      errors: {},
      isLoading: false
    }
  },
  computed: {
    isReady() {
      return this.$beet.isReady && !!this.page;
    },
    isDisabled() {
      return !this.formData.business.name ||
        !this.formData.business.website ||
        !this.formData.business.sector_id
    },
    activityOptions() {
      return Object.values(this.$beet.sectors).reduce((result, current) => {
          result[current.id] = current.name;
          return result;
        },
        { 0: this.$t('app.select') }
      );
    }
  },
  watch: {
    commonErrors: {
      deep: true,
      handler(to) {
        this.isLoading = true;
        if (to.business.name) {
          this.errors.name = to.business.name[0];
        }
        if (to.business.parent) {
          this.errors.parent = to.business.parent[0];
        }
        if (to.business.website) {
          this.errors.website = to.business.website[0];
        }
        if (to.business.email) {
          this.errors.email = to.business.email[0];
        }
        if (to.business.neq) {
          this.errors.neq = to.business.neq[0];
        }
        if (to.business.sector_id) {
          this.errors.sector_id = to.business.sector_id[0];
        }
        this.isLoading = false;
      }
    }
  },
  mounted() {
    if (this.otherData.is_quebec) {
      this.is_quebec = this.otherData.is_quebec;
    }
    if (this.commonData.business) {
      if (this.commonData.business.name) {
        this.formData.business.name = this.commonData.business.name;
      }
      if (this.commonData.business.parent) {
        this.formData.business.parent = this.commonData.business.parent;
      }
      if (this.commonData.business.website) {
        this.formData.business.website = this.commonData.business.website;
      }
      if (this.commonData.business.email) {
        this.formData.business.email = this.commonData.business.email;
      }
      if (this.commonData.business.neq) {
        this.formData.business.neq = this.commonData.business.neq;
      }
      if (this.commonData.business.sector_id) {
        this.formData.business.sector_id = this.commonData.business.sector_id;
      }
    }
    if (this.commonErrors.business) {
      this.isLoading = true;
      if (this.commonErrors.business.name) {
        this.errors.name = this.commonErrors.business.name[0];
      }
      if (this.commonErrors.business.parent) {
        this.errors.parent = this.commonErrors.business.parent[0];
      }
      if (this.commonErrors.business.website) {
        this.errors.website = this.commonErrors.business.website[0];
      }
      if (this.commonErrors.business.email) {
        this.errors.email = this.commonErrors.business.email[0];
      }
      if (this.commonErrors.business.neq) {
        this.errors.neq = this.commonErrors.business.neq[0];
      }
      if (this.commonErrors.business.sector_id) {
        this.errors.sector_id = this.commonErrors.business.sector_id[0];
      }
      this.isLoading = false;
    }
  },
  methods: {
    checkForm() {
      if (!this.isDisabled) {
        this.errors = {};

        if (this.formData.business.email && !EMAIL_REGEX.test(this.formData.business.email)) {
          this.errors.email = this.page.error.emailValid;
        }

        if (!URL_REGEX.test(this.formData.business.website)) {
          this.errors.website = this.page.error.emailValid;
        }

        if (!this.is_quebec) {
          this.errors.is_quebec = this.page.error.required;
        }

        if (Object.values(this.errors).length === 0) {
          this.$emit('next-step', { commonData: this.formData, otherData: { is_quebec: this.is_quebec } });
        }
      }
    }
  }
};
</script>

<style scoped>

</style>