export default {
  buttons: {
    cancel: 'Cancel',
    connection: 'Log in',
    linkReset: 'Send a reset link',
    back: 'Return to log in',
    subscription: 'Subscribe',
    send: 'Send message',
    confirmReset: 'Reset password'
  },
  links: {
    reset: 'Forgot password?'
  },
  subtitle: {
    reset: 'Reset your password'
  },
  name: {
    label: 'Full Name',
    empty: 'Please enter your full name.',
    pattern: 'Please enter your full name.'
  },
  email: {
    label: 'Email address',
    empty: 'Please enter your email address.',
    pattern: 'Please enter a valid email address.'
  },
  password: {
    label: 'Password',
    empty: 'Please enter your password.'
  },
  organisation: {
    label: 'Company / Organization',
    empty: 'Please enter your organization name.',
    pattern: 'Please enter your organization name.'
  },
  orgType: {
    label: 'Organization Type',
    default: 'Select a type',
    empty: 'Please select a type.',
    pattern: 'Please select a valid type.'
  },
  object: {
    label: 'Object',
    default: 'I want...',
    empty: 'Please select an object.',
    pattern: 'Please select a valid subject.'
  },
  phone: {
    label: 'Telephone (optional)',
    empty: 'Please enter your phone number.',
    pattern: 'Please enter a valid phone number.'
  },
  message: {
    label: 'Message',
    empty: 'Please write a message.',
    pattern: 'You have reached the 500-character limit.'
  },
  marketing: {
    label: 'I agree to receive promotional emails from Vitrine AI Québec.'
  },
  linkReset: {
    title: 'Request a reset password link',
    confirmation: 'A confirmation link has been sent'
  },
  reset: {
    title: 'Set a new password',
    confirmation: 'Your password has been reset',
    newPassword: {
      label: 'New Password',
      empty: 'Must contain a minimum of 8 characters including at least 1 uppercase, 1 lowercase and 1 number.',
      pattern: 'Must contain a minimum of 8 characters including at least 1 uppercase, 1 lowercase and 1 number.'
    },
    confirmPassword: {
      label: 'New Password Confirmation',
      emtpy: 'Must be identical to new password',
      pattern: 'Must be identical to new password'
    }
  }
}
